import React from 'react';
import Ingresos from './Home/Ingresos';
import Pasajeros from './Home/Pasajeros';
import Viajes from './Home/Viajes';
import Conductores from './Home/Conductores';
import Historial from './Home/Historial';
import Vehiculos from './Home/Vehiculos';

const Home = () => {
	return (
		<div className='flex flex-col h-full'>
			<div className='grid lg:grid-cols-3 lg:grid-rows-2 gap-4 mx-6 flex-col md:grid-cols-2 md:grid-rows-3 sm:grid-rows-1 my-auto'>
				<div className='bg-white p-4 overflow-x-auto'>
					<Conductores />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Historial />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Ingresos />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Pasajeros />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Vehiculos />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Viajes />
				</div>
			</div>
		</div>
	);
};

export default Home;
