const days = [
	{
		id: 0,
		dia: 'Lunes',
		day: 'monday',
	},
	{
		id: 1,
		dia: 'Martes',
		day: 'tuesday',
	},
	{
		id: 2,
		dia: 'Miercoles',
		day: 'wednesday',
	},
	{
		id: 3,
		dia: 'Jueves',
		day: 'thursday',
	},
	{
		id: 4,
		dia: 'Viernes',
		day: 'friday',
	},
	{
		id: 5,
		dia: 'Sabado',
		day: 'Saturday',
	},
	{
		id: 6,
		dia: 'Domingo',
		day: 'Sunday',
	},
];

export default days;
