import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

const Pasajeros = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosViajados, setUsuariosViajados] = useState(0);

  useEffect(() => {
    const fetchUsuariosData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL_PASAJEROS,
          {
            headers: {
              "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
            },
          }
        );

        const usuariosData = response.data.user;
        setUsuarios(usuariosData);

        const responseViajes = await axios.get(
          process.env.REACT_APP_API_VIAJES,
          {
            headers: {
              "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
            },
          }
        );
        const viajesData = responseViajes.data.trips;
        const usuariosConViajesTerminados = new Set();
        viajesData.forEach((viaje) => {
          if (viaje.status === "Terminado") {
            usuariosConViajesTerminados.add(viaje.user_data.idUser);
          }
        });
        setUsuariosViajados(usuariosConViajesTerminados.size);
      } catch (error) {
        console.error(
          "Error al obtener la información de la API PASAJEROS:",
          error
        );
      }
    };

    fetchUsuariosData();
  }, []);

  const allUsuarios = usuarios.length;

  const chartData = [
    { a: allUsuarios, Usuarios: allUsuarios, x: "Usuarios" },
    { b: usuariosViajados, Viajeros: usuariosViajados, x: "Viajeros" },
  ];

  return (
    <div className="flex items-center justify-center h-full">
      <div>
        <h2 className="text-xl font-bold text-center">Pasajeros</h2>
        <BarChart width={450} height={300} data={chartData}>
          <XAxis dataKey="x" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Usuarios" fill="#3aa5e6" name="Usuarios" />
          <Bar dataKey="Viajeros" fill="#008000" name="Viajeros" />
        </BarChart>
      </div>
    </div>
  );
};

export default React.memo(Pasajeros);
