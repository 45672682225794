import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import ConductorImage from '../../../assets/img/nikucar.png';
const RealTimeDriverMarkers = ({ drivers }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const filteredDrivers = drivers.filter((item) => {
    return item.driverData.data.nombre && item.driverData.data.vehiculo;
  });
  return (
    <>
      {filteredDrivers.map((item, index) => {
        const lat = item.driverData.l[0] || 0;
        const lng = item.driverData.l[1] || 0;
        return (
          <Marker
            key={index}
              position={{
              lat,
              lng,
            }}
            icon={{
              url: ConductorImage,
              scaledSize: new window.google.maps.Size(50,40), // Curren 40,40
            }}
            onClick={() => {
              setSelectedDriver(item);
            }}
          />
        );
      })}
      {selectedDriver && (
        <InfoWindow
        style={{
          width: 400,
          height: 40,
        }}
          position={{
            lat: selectedDriver.driverData.l[0],
            lng: selectedDriver.driverData.l[1],
          }}
          
          onCloseClick={() => {
            setSelectedDriver(null);
          }}
        >
          <div className='text-[1rem]'>
            <h2 className='font-bold text-center pb-2'>Información del Conductor</h2>
            <p className='font-bold'>Nombre: </p><p>{selectedDriver.driverData.data.nombre}</p>
            <p className='font-bold'>Vehiculo:</p><p> {selectedDriver?.driverData?.data?.vehiculo.split('Placas')[0]}</p>
            <p className='font-bold'>Placa:</p><p> {selectedDriver?.driverData?.data?.vehiculo.split(':')[1]}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
export default RealTimeDriverMarkers;