import { gql } from '@apollo/client';

export const LISTEN_TRAVELS_SUB = gql`
	subscription getActiveTrips {
		trips(
			order_by: { time_request: desc }
			where: {
				_or: [
					{ status: { _eq: "Aceptado" } }
					{ status: { _eq: "Curso" } }
					{ status: { _eq: "Iniciado" } }
				]
			}
		) {
			distance
			monitoring
			panic_button
			status
			substatus
			trip_id
			locations {
				description
			}
			user_data {
				name
				last_name
			}
			driver_data {
				last_name
				name
			}
		}
	}
`;

export const OBTENER_VIAJES_QUERY = gql`
	query ObtenerViajes {
		trips(limit: 10) {
			trip_id
		}
	}
`;
