export const setCurrentPage = page => ({
	type: 'SET_CURRENT_PAGE',
	payload: page,
});

export const setPoligonoId = id => ({
	type: 'POLIGONO_ID',
	payload: id,
});

export const setPoligonoZona = zona => ({
	type: 'POLIGONO_ZONA',
	payload: zona,
});

export const setPanicoZona = panicoZona => ({
	type: 'PANICO_ZONA',
	payload: panicoZona,
});
