import React, { useState, useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from 'axios';
import Modal from 'react-modal';
import DatosDelVehiculo from './DatosDelVehiculo';
import TablaDocumentos from './TablaDocumentos';
import formatDate from '../../../formatDate';
import picDriver from '../../../assets/img/nik_conductor.png';
import Tooltip from '@mui/material/Tooltip';
import { getDatabase, ref as DatabaseRef, set } from 'firebase/database';
import { app } from '../../../firebase/firebase';
import '../../../styles/generales.css';
Modal.setAppElement('#root');

export default function BotonDetalles({ rowData, vehicleId }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [driverDetails, setDriverDetails] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isCircle, setIsCircle] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_DRIVERs_ID}/${rowData.driver_id}`,
					{
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
						},
					}
				);
				setDriverDetails(response.data.drivers[0]);
			} catch (error) {
				console.error('Error fetching driver details:', error);
			}
			setIsLoading(false);
		};

		fetchData();
	}, [rowData.driver_id]);

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const formattedPhoneNumber =
		driverDetails && driverDetails.tel
			? driverDetails.tel.replace(/\D/g, '')
			: '';

	const solicitudConductor = async (estado, driverId) => {
		try {
			setIsLoading(true);

			const time = new Date();
			const apiUrl = 'https://superb-mackerel-18.hasura.app/v1/graphql';

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					query: `
                  mutation updateDriver(
					$driver_id: String!,
					$status: String,
					$date_approved: bigint
					) {
					update_drivers_by_pk(
						pk_columns: {
						driver_id: $driver_id
						},
						_set: {
						status: $status,
						date_approved: $date_approved
						}
					) {
						driver_id
					}
					}

                `,
					variables: {
						driver_id: driverId,
						status: estado,
						date_approved:
							estado === 'Activo' ? time.getTime() : null,
					},
				}),
			});

			const data = await response.json();

			if (data.errors) {
				throw new Error(data.errors[0].message);
			}

			setTimeout(async () => {
				const db = getDatabase(app);

				try {
					await set(
						DatabaseRef(
							db,
							`/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/TAXI_status_conductor/${driverId}`
						),
						{
							status: estado,
							substatus: estado === 'Activo' ? 'complete' : '',
						}
					);

					setModalIsOpen(false);
				} catch (error) {
					console.log('error firebase', error);
				}
			}, 2000);
		} catch (error) {
			console.error('Error en la solicitud GraphQL:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const actualizarDatos = () => {
		const nuevaVentana = window.open(
			`https://update-info.netlify.app/#/info-detalles/${rowData.driver_id}`,
			'_blank',
			'width=800,height=600'
		);

		nuevaVentana.focus();
	};

	const reportarBug = () => {
		const nuevaVentana = window.open(
			`https://forms.monday.com/forms/b49bef9b1216f139de0a3452e3a1d6bb?r=use1`,
			'_blank',
			'width=800,height=600'
		);

		nuevaVentana.focus();
	};

	return (
		<div className='text-center'>
			<button
				className='bg-BlueD text-white p-1 rounded-l-full hover:bg-orange-500 hover:scale-110'
				onClick={openModal}
			>
				<InfoOutlinedIcon
					sx={{ fontSize: '1.1rem', margin: '.25rem' }}
				/>
			</button>
			<Modal
				className={`bg-gray-100 flex flex-col rounded-xl border-4 w-modal h-modalAjuste border-orange-500`}
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={{
					overlay: {
						backgroundColor: '#00000099',
						overflow: 'auto',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						paddingLeft: '17vw',
					},
					content: {
						overflowY: 'auto',
						overflowX: 'hidden',
					},
				}}
			>
				<div className='flex justify-between'>
					<button className='p-1 ml-auto' onClick={closeModal}>
						<HighlightOffIcon
							className='text-orange-500 hover:bg-orange-600 hover:text-white rounded-full'
							style={{ fontSize: '2rem' }}
						/>
					</button>
				</div>
				<h2 className='text-xl text-center font-bold'>
					{isLoading
						? 'Cargando detalles del conductor...'
						: driverDetails
						? (driverDetails.name
								? driverDetails.name
								: ''
						  ).toUpperCase() +
						  ' ' +
						  (driverDetails.last_name
								? driverDetails.last_name
								: ''
						  ).toUpperCase()
						: 'Detalles no disponibles'}
				</h2>
				<div className='flex justify-center items-center w-full p-4 ml-4'>
					<div className='grid semi:grid-cols-8 gap-4'>
						<div className='semi:col-start-2 col-end-4 h-full'>
							<Tooltip title='Cambiar forma' arrow>
								<img
									loading='lazy'
									className={`h-box cursor-pointer ${
										isCircle ? 'rounded-full' : ''
									}`}
									src={
										isLoading
											? 'URL_de_imagen_de_carga'
											: driverDetails && driverDetails.img
											? driverDetails.img
											: picDriver
									}
									alt='Conductor imagen'
									style={{
										borderRadius: isCircle ? '50%' : '0',
										width: '300px',
										height: '300px',
										objectFit: 'cover',
									}}
									onClick={() => setIsCircle(!isCircle)}
								/>
							</Tooltip>
						</div>
						<div className='semi:col-start-5 col-end-8 flex flex-col gap-4'>
							<h2 className='text-md'>
								<strong>ID:</strong>{' '}
								<span>
									{isLoading
										? ''
										: driverDetails
										? driverDetails.id
										: 'Detalles no disponibles'}
								</span>
							</h2>
							<h2 className='text-md'>
								<strong>Correo:</strong>{' '}
								{isLoading ? (
									''
								) : driverDetails && driverDetails.email ? (
									<a
										className='text-blue-600 underline'
										href={`mailto:${driverDetails.email}`}
									>
										{driverDetails.email}
									</a>
								) : (
									'Detalles no disponibles'
								)}
							</h2>
							<h2 className='text-md'>
								<strong>Tel:</strong>{' '}
								{isLoading ? (
									''
								) : driverDetails && formattedPhoneNumber ? (
									<a
										className='text-blue-600 underline'
										href={`https://wa.me/${formattedPhoneNumber}?text=¡Buenas%20tardes!`}
										target='_blank'
										rel='noreferrer'
									>
										{driverDetails.tel}
									</a>
								) : (
									'Detalles no disponibles'
								)}
							</h2>
							<h2 className='text-md'>
								<strong>Fecha de aprobacion:</strong>{' '}
								<span>
									{isLoading
										? ''
										: driverDetails
										? formatDate(
												driverDetails.fechaRegistro
										  )
										: 'Detalles no disponibles'}
								</span>
							</h2>
							<h2 className='text-md'>
								<strong>Fecha de registro:</strong>{' '}
								<span>
									{isLoading
										? ''
										: driverDetails
										? formatDate(
												driverDetails.fechaRegistro
										  )
										: 'Detalles no disponibles'}
								</span>
							</h2>
							<h2 className='text-md'>
								<strong>Lugar:</strong>{' '}
								<span>
									{isLoading
										? ''
										: driverDetails
										? driverDetails.state
										: 'Detalles no disponibles'}
								</span>
							</h2>
							<h2 className='text-md'>
								<strong>Estatus:</strong>{' '}
								<span>
									{isLoading
										? ''
										: driverDetails
										? driverDetails.status
										: 'Detalles no disponibles'}
								</span>
							</h2>
							<h2 className='text-md'>
								<strong>Datos Fiscales:</strong>{' '}
								<span>
									{isLoading
										? 'Cargando...'
										: driverDetails?.datosDeEmisiOnFacturaciOn
										? '✅'
										: '❌'}
								</span>
								<strong className='pl-10'>Conectado:</strong>{' '}
								<span>
									{isLoading
										? 'Cargando...'
										: rowData?.inline
										? '✅'
										: '❌'}
								</span>
							</h2>
						</div>
					</div>
				</div>
				<div className='grid semi:grid-cols-2 mt-8 semi:ml-12 sm:mx-4'>
					<div className='col-span-1 text-center'>
						<TablaDocumentos
							vehicleDetails={
								driverDetails ? driverDetails : null
							}
							vehicleId={vehicleId}
						/>
					</div>
					<div className='col-span-1 self-center'>
						<DatosDelVehiculo
							vehicleDetails={
								driverDetails ? driverDetails : null
							}
						/>
					</div>
				</div>
				<div className='div-Btn-detalles-conductor'>
					<button
						id='Aceptar'
						onClick={() => {
							const confirmarEliminacion = window.confirm(
								'¿Seguro que deseas Aprobar este conductor?'
							);
							if (confirmarEliminacion) {
								solicitudConductor('Activo', driverDetails?.id);
							}
						}}
					>
						Aceptar
					</button>
					<button
						id='Requiere'
						onClick={() => {
							actualizarDatos();
						}}
					>
						Actualizar Datos
					</button>
					<button
						id='Rechazar'
						onClick={() => {
							const confirmarEliminacion = window.confirm(
								'¿Seguro que deseas Rechazar este conductor?'
							);
							if (confirmarEliminacion) {
								solicitudConductor(
									'Denegado',
									driverDetails?.id
								);
							}
						}}
					>
						Rechazar
					</button>
					<button
						type='button'
						id='Rechazar'
						style={{ background: 'orangered' }}
						onClick={() => {
							const confirmarEliminacion = window.confirm(
								'¿Quieres reportar una falla de este Usuario?'
							);
							if (confirmarEliminacion) {
								reportarBug();
							}
						}}
					>
						Reportar Bug
					</button>
				</div>
			</Modal>
		</div>
	);
}
