import React from 'react';
import { useQuery } from '@apollo/client';
import { TRIPS_BY_ID_USER } from '../../../schemas/querys';

const ExpandableComponent = ({ userId }) => {
    const { loading, error, data } = useQuery(TRIPS_BY_ID_USER, {
        variables: { _eq: userId }, 
    });

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const canceledTrips = data.trips.filter(trip => trip.status === 'Cancelado');
    const completedTrips = data.trips.filter(trip => trip.status === 'Terminado');

    return (
        <div className='ml-2 flex'>
            <p className='mr-4'>Solicitados: {data.trips.length}</p>
            <p className='mr-4'>Cancelados: {canceledTrips.length}</p>
            <p>Terminados: {completedTrips.length}</p>
        </div>
    );
};

export default ExpandableComponent;
