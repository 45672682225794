import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function SnackBar({ open, handleClose }) {
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        handleClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackbar}
                severity="success"
            >
                Creado con exito
            </MuiAlert>
        </Snackbar>
    );
}
