import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DetallesFinalizados from '../ViajesFinalizados/DetallesFinalizados'
import useShowIcons from '../../../hooks/useShowIcons'
import { Videocam, VideocamOff } from '@mui/icons-material';
function CurrentTripsTable({ trips }) {
  const { showIcons } = useShowIcons()

  const getStatusClass = (status) => {
    if (status === 'Terminado') {
      return 'bg-greenE text-white'
    } else if (status === 'Cancelado' || status === 'Cancelada') {
      return 'bg-redD text-white'
    } else if (status === 'Aceptado') {
      return 'bg-yellowE text-black'
    } else if (status === 'Curso') {
      return 'bg-orangeD text-white'
    } else {
      return ''
    }
  }

  const getStatusContent = (row) => {
    if (showIcons) {
      return row.status === 'Terminado' ? (
        <CheckOutlinedIcon sx={{fontSize:'2rem'}} />
      ) : (
        <HighlightOffOutlinedIcon  sx={{fontSize:'2rem'}}/>
      )
    } else return row.tripStatus
  }

  useEffect(() => {
    document.title = `S. Monitoreo - Viajes en curso`;
},[])
  const columns = [
    {
      name: 'Monitoreo',
      width: '120px',
      center: true,
      cell: (row) => (
        <>
          {row.monitoring ? <Videocam className='text-green-500' /> : <VideocamOff className='text-red-500' />}
        </>
      ),
    },
    {
      name: 'ID',
      selector: 'trip_id',
      width: '110px',
      cell: (row) => (
        <strong className='truncate ...' style={{ display: 'block', fontSize: '1rem' }}>
          {row.tripID}
        </strong>
      ),
    },
    {
      name: 'Conductor',
      width: '200px',
      selector: (row) => row.driver,
    },
    {
      name: 'Origen',
      width: '210px',
      selector: (row) => row.locations.origin,
    },
    {
      name: 'Destino',
      selector: (row) => row.locations.destination,
      width: '190px',
    },
    {
      name: 'Usuario',
      selector: (row) => row.user,
      width: '180px',
    },
    {
      name: 'Estatus',
      selector: 'status',
      width: '150px',
      cell: (row) => (
        <span
          className={`px-3 py-1 text-lg text-center rounded-full ${getStatusClass(
            row.tripStatus
          )}`}
        >
          {getStatusContent(row)}
        </span>
      ),
    },
    {
      name: 'SubEstatus',
      selector: 'status',
      width: '150px',
      cell: (row) => (
        <span
          className={`px-3 py-1 text-lg text-center rounded-full ${getStatusClass(
            row.tripStatus
          )}`}
        >
          {getStatusContent(row)}
        </span>
      ),
    },
    {
      name: 'Detalles',
      selector: 'options',
      width: '120px',
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center" style={{ height: '100%' }}>
          <DetallesFinalizados rowData={row} />
        </div>
      ),
      ignoreRowClick: true,
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row) => row.panic,
      style: {
        backgroundColor: '#EA0000',
        color: 'white',
      },
    },
  ]

  return (
    <div className="ml-30 mr-30 mt-10 mega:mr-T mega:ml-T items-center mb-4">
      <DataTable
        columns={columns}
        data={trips}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        highlightOnHover
        customStyles={{
          rows: {
            style: {
              borderTop: '1px solid #CBD5E0',
            },
          },
          headCells: {
            style: {
              fontSize: '1.25rem',
              textAlign: 'center',
            },
          },
          cells: {
            style: {
              marginTop: '1rem',
              marginBottom: '1rem',
              fontSize: '1rem',
            },
          },
          pagination: {
            style: {
              fontSize: '1rem',
              color: 'black',
            },
          },
        }}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  )
}

export default CurrentTripsTable
