import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from './assets/img/Loading.json';
import Lottie from 'lottie-react';
import Dashboard from './components/Dashboard';
import { Login } from './components/Login';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import NotFound from './components/notfound';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchDashboardData = async () => {
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		};
		fetchDashboardData();
	}, []);
	return (
		<AuthProvider>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Routes>
					<Route path='/Login' element={<Login />} />
					<Route
						path='/'
						element={
							<ProtectedRoute>
								{loading ? (
									<Lottie animationData={Loading} />
								) : (
									<Dashboard />
								)}
							</ProtectedRoute>
						}
					/>
					<Route element={<NotFound />} path='/*' />
				</Routes>
			</LocalizationProvider>
		</AuthProvider>
	);
}
export default App;
