import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { FaPlusCircle } from 'react-icons/fa';
import HorarioForm from './HorarioForm';
import days from './days';
import Loader from './Loader';
import reloj from '../../assets/img/reloj.png';
import '../../styles/tarifaDinamica.css';

const TarifaDinamica = ({ setTarifaDinamica, nombreZona, poligonoID }) => {
	const [dayE, setDayE] = useState(null);
	const [horario, setHorario] = useState(false);
	const [updateInfo, setUpdateInfo] = useState(false);
	const [data, setData] = useState([]);
	const [dayData, setDayData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [editar, setEditar] = useState({});
	const [isEditar, setIsEditar] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);
	const zona = nombreZona;

	useEffect(() => {
		fetchDynamicTimeSlots();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateInfo]);

	const fetchDynamicTimeSlots = () => {
		setLoading(true); // Indicar que se está cargando
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
          query GetDynamicTimeSlot {
            dynamic_time_slot(where: {zona_id: {_eq: "${poligonoID.poligonoId}"}}) {
              status
              day
              id
              zona_id
              multiplier
              day_e
              end_time
              start_time
            }
          }
        `,
			}),
		})
			.then(res => res.json())
			.then(res => {
				setData(res.data.dynamic_time_slot);

				// Asegúrate de usar dayE en lugar de data para filtrar el nuevo día
				const newDay = res.data.dynamic_time_slot.filter(
					dia => dia.day_e === dayE
				);

				setTimeout(() => {
					setDayData(newDay);
					setLoading(false);
				}, 1000);
			})
			.catch(error => {
				console.error('Error:', error);
				setLoading(false); // Indicar que la carga ha terminado con error
			});
	};

	const filterDay = day => {
		const newDay = data.filter(dia => dia.day_e === day);
		setDayData(newDay);
		setSelectedDay(day);
	};

	const eliminarTarifa = (id, day_e) => {
		setLoading(true); // Indicar que se está cargando después de la eliminación
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
          mutation MyMutation($id: String!) {
            delete_dynamic_time_slot_by_pk(id: $id) {
              id
              zona_id
              day
              status
            }
          }`,
				variables: {
					id,
				},
			}),
		})
			.then(res => res.json())
			.then(() => {
				setUpdateInfo(!updateInfo);
				setDayE(day_e);
				setLoading(false); // Indicar que la carga ha terminado después de la eliminación
			})
			.catch(error => {
				console.error('Error:', error);
				setLoading(false); // Indicar que la carga ha terminado con error después de la eliminación
			});
	};

	const convertirHora = start_time => {
		// Convertir a cadena de texto y asegurarse de que tenga al menos 4 caracteres
		const start_time_str = start_time.toString().padStart(4, '0');

		// Obtener las horas y minutos
		const horas = parseInt(start_time_str.substring(0, 2));
		const minutos = start_time_str.substring(2, 4);

		// Convertir a formato de 12 horas
		let horas12 = horas % 12;
		horas12 = horas12 === 0 ? 12 : horas12; // Si horas12 es 0, cambiarlo a 12
		const ampm = horas >= 12 ? 'pm' : 'am';

		// Retornar la hora en formato de 12 horas (AM/PM)
		return `${horas12}:${minutos} ${ampm}`;
	};

	return (
		<>
			{horario ? (
				<HorarioForm
					setHorario={setHorario}
					zona={zona}
					days={days}
					setUpdateInfo={setUpdateInfo}
					updateInfo={updateInfo}
					editar={editar}
					isEditar={isEditar}
					dayData={dayData}
					data={data}
					setDayE={setDayE}
				/>
			) : (
				<>
					{loading && <Loader />}
					<div className='div-regresar-tarifa-dinamica'>
						<button
							onClick={() => {
								setTarifaDinamica(false);
							}}
						>
							<IoArrowBackSharp />
						</button>
						<span id='span-regresar-tarifa-dinamica'>Regresar</span>
					</div>
					<section className='section-tarifa-dinamica-agregar-zona'>
						<div id='div-tarifa-dinamica-dias'>
							<h2>Dias de la semana </h2>
							<hr />
							{days.map(day => (
								<div
									className={`div-dias-de-la-semana ${
										selectedDay === day.id ? 'selected' : ''
									}`}
									key={day.id}
									onClick={() => filterDay(day.id)}
								>
									{day.dia}
								</div>
							))}
						</div>
						<div id='div-tarifa-dinamica-horario'>
							<h2> Horarios</h2>
							{dayData.length > 0 ? (
								dayData.map(horario => (
									<div
										className='div-card-horarios'
										key={horario.id}
										onClick={() => {
											setHorario(true);
											setEditar(horario);
											setIsEditar(true);
										}}
									>
										<div className='div-card-data-horarios'>
											<div>
												<p className='div-card-p-tarifa'>
													Tarifa x{' '}
													{horario?.multiplier}{' '}
												</p>
											</div>
											<div className='div-card-horas'>
												<div>
													<p>
														Inicia:{' '}
														{convertirHora(
															horario.start_time
														)}
													</p>
												</div>
												<div>
													<p>
														Termina:{' '}
														{convertirHora(
															horario?.end_time
														)}
													</p>
												</div>
											</div>
										</div>
										<div className='div-card-bottom-eliminar-tarifa'>
											<button
												onClick={e => {
													e.stopPropagation();
													const confirmarEliminacion =
														window.confirm(
															'¿Seguro que deseas eliminar esta tarifa?'
														);

													// Si el usuario hace clic en "Aceptar", eliminamos la tarifa
													if (confirmarEliminacion) {
														eliminarTarifa(
															horario.id,
															horario.day_e
														);
													}
												}}
											>
												{' '}
												Eliminar
											</button>
										</div>
									</div>
								))
							) : (
								<div className='card-container-no-hay-horarios'>
									<div>
										<img src={reloj} alt='reloj' />
									</div>
									<p>No hay horarios!</p>
								</div>
							)}
						</div>
					</section>
					<div id='div-tarifa-dinamica-buttom'>
						<button
							onClick={() => {
								setHorario(true);
								setIsEditar(false);
							}}
						>
							{' '}
							<FaPlusCircle />
						</button>
					</div>
				</>
			)}
		</>
	);
};

export default TarifaDinamica;
