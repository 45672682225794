import React, { useEffect, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Switch from 'react-switch';
import Loader from './Loader';
import '../../styles/tarifaForm.css';

const TarifaForm = ({
	setModal,
	titulo,
	data,
	update,
	setUpdate,
	editar,
	nombreZona,
	idZona,
}) => {
	const poligoName = nombreZona;
	const poligonoId = idZona;

	const [car, setCar] = useState(null);
	const [name, setName] = useState(null);
	const [description, setDescription] = useState(null);
	const [zonas, setZonas] = useState([]);
	const [tarifaBase, setTarifaBase] = useState(null);
	const [tarifaMinima, setTarifaMinima] = useState(null);
	const [tarifaKilometro, setTarifaKilometro] = useState(null);
	const [tarifaMinuto, setTarifaMinuto] = useState(null);
	const [distancia, setDistancia] = useState(null);
	const [plataformaIva, setPlataformaIva] = useState(null);
	const [impuestoISR, setImpuestoISR] = useState(null);
	const [cuotaSolicitud, setCuotaSolicitud] = useState(null);
	const [tarifaplataforma, setTarifaPlataforma] = useState(null);
	const [impuestoIva, setImpuestoIva] = useState(data.impuestos_iva);
	const [status, setStatus] = useState(data.status || false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `query getVehicles {
  						vehicles(order_by: { name: asc }) {
    						vehiculo_id
    						name
  						}
				}`,
			}),
		})
			.then(res => res.json())
			.then(res => {
				setZonas(res.data.vehicles);
			});

		if (titulo === 'Editar') {
			setCar(data.vehiculo_id);
			setName(data.name);
			setDescription(data.description);
			setTarifaBase(data.base_rate);
			setTarifaMinima(data.minimum_rate);
			setTarifaKilometro(data.rate_per_kilometer);
			setTarifaMinuto(data.rate_per_minute);
			setDistancia(data.max_distance);
			setPlataformaIva(data.plataforma_iva * 100);
			setImpuestoISR(data.impuestos_isr * 100);
			setCuotaSolicitud(data.cuota_solicitud * 100);
			setTarifaPlataforma(data.uso_plataforma * 100);
			setImpuestoIva(data.impuestos_iva);
		}
	}, [titulo, data]);

	const handleChange = event => {
		setCar(event.target.value);
	};

	const validaStatus = () => {
		setStatus(prevStatus => !prevStatus);
	};

	const guardarTarifa = e => {
		e.preventDefault();
		setLoading(true);

		const id = crypto.randomUUID();
		const rate_id = editar ? data.rate_id : id;

		switch (null) {
			case car:
				alert('Selecciona el tipo de Car');
				setLoading(false);
				break;

			case name:
				alert('ingresa un name');
				setLoading(false);
				break;

			case description:
				alert('ingresa una descripcion');
				setLoading(false);
				break;

			case tarifaBase:
				alert('ingresa una tarifa base ');
				setLoading(false);
				break;

			case tarifaMinima:
				alert('Ingresa una tarifa minima');
				setLoading(false);
				break;

			case tarifaKilometro:
				alert('ingresa una tarifa kilometro');
				setLoading(false);
				break;

			case tarifaMinuto:
				alert('ingresa una tarifa minuto');
				setLoading(false);
				break;

			case distancia:
				alert('ingresa la distancia');
				setLoading(false);
				break;

			case plataformaIva:
				alert('ingresa el valor plataforma iva');
				setLoading(false);
				break;

			case impuestoISR:
				alert('ingresa el valor del impuesto ISR');
				setLoading(false);
				break;

			case cuotaSolicitud:
				alert('ingresa el valor cuota solicitud');
				setLoading(false);
				break;

			case tarifaplataforma:
				alert('ingresa el valor de la tarifa plataforma ');
				setLoading(false);
				break;

			default:
				const variables = {
					rate_id,
					poligonoId,
					poligoName,
					name,
					description,
					car,
					tarifaBase: parseFloat(tarifaBase),
					tarifaMinima: parseFloat(tarifaMinima),
					tarifaKilometro: parseFloat(tarifaKilometro),
					tarifaMinuto: parseFloat(tarifaMinuto),
					distancia: parseFloat(distancia),
					plataformaIva: parseFloat(plataformaIva / 100),
					impuestoISR: parseFloat(impuestoISR / 100),
					cuotaSolicitud: parseFloat(cuotaSolicitud / 100),
					tarifaplataforma: parseFloat(tarifaplataforma / 100),
					impuestoIva: parseFloat(impuestoIva),
					status,
				};

				if (titulo === 'Editar') {
					fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
						method: 'POST',
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							query: `mutation Update_rate(
								$rate_id: String!,
              					$name: String,
              					$description: String,
              					$poligonoId: String,
              					$poligoName: String,
              					$car: String,
              					$tarifaBase: float8,
              					$tarifaMinima: float8,
              					$tarifaKilometro: float8,
              					$tarifaMinuto: float8,
              					$distancia: float8,
              					$plataformaIva: float8,,
              					$impuestoISR: float8,,
              					$cuotaSolicitud: float8,,
           	  					$tarifaplataforma: float8,
              					$impuestoIva: float8,
								$status: Boolean,) {update_rates_by_pk(
			pk_columns:{rate_id: $rate_id},
			 _set:{
                  poligono_id: $poligonoId,
                  poligono_name: $poligoName,
                  name: $name,
                  description: $description,
                  vehiculo_id: $car,
                  base_rate: $tarifaBase,
                  minimum_rate: $tarifaMinima,
                  rate_per_kilometer: $tarifaKilometro,
                  rate_per_minute: $tarifaMinuto,
                  max_distance: $distancia,
                  plataforma_iva: $plataformaIva ,
            	  impuestos_isr: $impuestoISR,
            	  cuota_solicitud: $cuotaSolicitud,
            	  uso_plataforma: $tarifaplataforma,
                  impuestos_iva: $impuestoIva,
                  status: $status
                  environment: "2",
					}) {
    				rate_id
 					 }
				}`,
							variables,
						}),
					})
						.then(res => res.json())
						.then(() => {
							setModal(false);
							setUpdate(!update);
						})
						.catch(error => {
							console.error('Error:', error);
							setLoading(false);
						});
				} else {
					fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
						method: 'POST',
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							query: `
						mutation CreateRate(
							$rate_id: String!,
							$name: String,
							$description: String,
							$poligonoId: String,
							$poligoName: String,
							$car: String,
							$tarifaBase: float8,
							$tarifaMinima: float8,
							$tarifaKilometro: float8,
							$tarifaMinuto: float8,
							$distancia: float8,
							$plataformaIva: float8,,
							$impuestoISR: float8,,
							$cuotaSolicitud: float8,,
							$tarifaplataforma: float8,
							$impuestoIva: float8,
							$status: Boolean,
            ) {
				insert_rates(
					objects: {
						rate_id: $rate_id,
						poligono_id: $poligonoId,
						poligono_name: $poligoName,
						name: $name,
						description: $description,
						vehiculo_id: $car,
						base_rate: $tarifaBase,
						minimum_rate: $tarifaMinima,
						rate_per_kilometer: $tarifaKilometro,
						rate_per_minute: $tarifaMinuto,
						max_distance: $distancia,
						plataforma_iva: $plataformaIva ,
						impuestos_isr: $impuestoISR,
						cuota_solicitud: $cuotaSolicitud,
						uso_plataforma: $tarifaplataforma,
						impuestos_iva: $impuestoIva,
						status: $status,
						environment: "2",
						
					}
					) {
						returning {
							rate_id
						}
					}
				}
				`,
							variables,
						}),
					})
						.then(res => res.json())
						.then(() => {
							setModal(false);
							setLoading(false);
							setUpdate(!update);
						})
						.catch(error => {
							console.error('Error:', error);
						});
				}

				break;
		}
	};

	const zonaName = zonas.filter(zona => car === zona.vehiculo_id);

	return (
		<>
			{loading && <Loader />}
			<section className='section-crear-tarifa-form'>
				<div className='div-form-titulo'>
					<h1>{titulo} Tarifa</h1>
				</div>

				<form onSubmit={guardarTarifa}>
					<div className='div-from-seleccionar-zona'>
						<div>
							<p>Zona</p>
							<p>{nombreZona}</p>
						</div>
						<div>
							<p>Tipo de Vehiculo</p>

							<select
								name='Seleccionar'
								id=''
								onChange={handleChange}
							>
								<option value='null'>
									{car ? zonaName[0]?.name : 'selecciona'}
								</option>
								{zonas.map(zona => (
									<option
										value={zona.vehiculo_id}
										key={zona.vehiculo_id}
									>
										{zona.name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className='div-form-nombre-de-la-tarifa'>
						<div>
							<p htmlFor='nombre-tarifa'>Nombre de la Tarifa</p>
							<input
								type='text'
								id='nombre-tarifa'
								placeholder='Tarifa'
								defaultValue={name}
								onChange={e => {
									setName(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-descripcion'>
						<div>
							<p>Descripcion</p>
						</div>
						<div>
							<input
								type='text'
								placeholder='descripcion'
								defaultValue={description}
								onChange={e => {
									setDescription(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-precio-base'>
						<div>
							<label htmlFor='precio-base'>Precio Base</label>
							<input
								type='text'
								id='precio-base'
								placeholder='0'
								defaultValue={tarifaBase}
								onChange={e => {
									setTarifaBase(e.target.value);
								}}
							/>
						</div>
						<div>
							<label htmlFor='precio-minimo'>Precio Minimo</label>
							<input
								type='text'
								id='precio-minimo'
								placeholder='0'
								defaultValue={tarifaMinima}
								onChange={e => {
									setTarifaMinima(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-precio-km'>
						<div>
							<label htmlFor='precio-km'>Precio Km</label>
							<input
								type='text'
								id='precio-km'
								placeholder='0'
								defaultValue={tarifaKilometro}
								onChange={e => {
									setTarifaKilometro(e.target.value);
								}}
							/>
						</div>
						<div>
							<label htmlFor='precio-minuto'>Precio Minuto</label>
							<input
								type='text'
								id='precio-minuto'
								placeholder='0'
								defaultValue={tarifaMinuto}
								onChange={e => {
									setTarifaMinuto(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-distancia-maxima'>
						<div>
							<p>Distancia Maxima</p>
						</div>
						<div>
							<input
								type='text'
								placeholder='9999'
								defaultValue={distancia}
								onChange={e => {
									setDistancia(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-iva'>
						<div>
							<label htmlFor='IVA'> % IVA</label>
							<input
								type='text'
								id='IVA'
								placeholder='0.01'
								defaultValue={plataformaIva}
								onChange={e => {
									setPlataformaIva(e.target.value);
								}}
							/>
						</div>
						<div>
							<label htmlFor='ISR'> % ISR </label>
							<input
								type='text'
								id='ISR'
								placeholder='0'
								defaultValue={impuestoISR}
								onChange={e => {
									setImpuestoISR(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-uso-plataforma'>
						<div>
							<label htmlFor='plataforma'>% Uso Plataforma</label>
							<input
								type='text'
								id='plataforma'
								placeholder='0'
								defaultValue={tarifaplataforma}
								onChange={e => {
									setTarifaPlataforma(e.target.value);
								}}
							/>
						</div>
						<div>
							<label htmlFor='comision'>
								{' '}
								% Cuota de solicitud
							</label>
							<input
								type='text'
								id='comision'
								placeholder='0'
								defaultValue={cuotaSolicitud}
								onChange={e => {
									setCuotaSolicitud(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className='div-form-iva-general'>
						<p>
							IVA General: <span>{impuestoIva} %</span>
						</p>
					</div>
					{editar && (
						<>
							<div className='status-switch-tarifa-from'>
								<p>
									Status de la tarifa:{' '}
									<span
										style={{
											color: status ? 'green' : 'red',
										}}
									>
										{status ? 'Activa' : 'Inactiva'}
									</span>
								</p>
								<Switch
									onChange={validaStatus}
									checked={status}
								/>
							</div>
						</>
					)}

					<div className='div-form-buttom-guardar'>
						<button type='submit'> Guardar </button>
					</div>
				</form>
				<button
					className='div-form-regresar'
					onClick={() => {
						setModal(false);
					}}
				>
					<IoMdArrowRoundBack />
				</button>
			</section>
		</>
	);
};

export default TarifaForm;
