import XLSX from "xlsx-js-style";

export const exportToExcel = (filteredTrips, columnConfig) => {
  const idToNameMap = {
    "91111b73-2439-4d26-b3c1-c462c5b0f4e0": "Comfort",
    "09436de0-4987-11ed-8a29-75bea275e626": "Nikucar",
    "82f8f8b0-f5cb-4234-89e1-167c1f0822a6": "Security",
    "08f97404-ba81-4a3f-b4aa-f5ac05fa2c85": "Lady",
    "094363hl-s11ed-8a29-75bea275esad2w": "Taxi",
  };
  function getNameFromId(id) {
    return idToNameMap[id] || "Nombre no encontrado";
  }

  const wb = XLSX.utils.book_new();
  const dataToExport = filteredTrips.map((travel) => {
    const rowData = {};
    columnConfig.forEach((column) => {
      if (column.key === "user_data") {
        const user_name =
          travel.user_data && travel.user_data.name
            ? travel.user_data.name
            : "";
        const user_lastName =
          travel.user_data && travel.user_data.last_name
            ? travel.user_data.last_name
            : "";
        rowData[column.label] = `${user_name} ${user_lastName}`;
      } else if (column.key === "locations[0].description") {
        const originDescription =
          travel.locations && travel.locations.length > 0
            ? travel.locations[0].description
            : "N/A";
        rowData[column.label] = originDescription;
      } else if (column.key === "zona_data.nombre") {
        const cityName =
          travel.zona_data && travel.zona_data.nombre
            ? travel.zona_data.nombre
            : "Desconocida";
        rowData[column.label] = cityName;
      } else if (column.key === "locations[1].description") {
        const destinoDescription =
          travel.locations && travel.locations.length > 1
            ? travel.locations[1].description
            : "N/A";
        rowData[column.label] = destinoDescription;
      } else if (
        column.key === "time_request" &&
        typeof travel[column.key] === "number"
      ) {
        const date = new Date(travel[column.key]);
        const formattedDate = date.toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
        rowData[column.label] = formattedDate;
      } else if (column.key === "driver_name") {
        const driverName = travel.driver_data
          ? `${travel.driver_data.name} ${travel.driver_data.last_name}`
          : "N/A";
        rowData[column.label] = driverName;
      } else if (column.key === "status") {
        rowData[column.label] = travel[column.key] || "N/A";
      } else if (column.key === "vehicle_id") {
        rowData[column.label] = getNameFromId(travel[column.key]) || "N/A";
      } else if (column.key === "tarifa_total"){
        rowData[column.label] = travel[column.key] || "N/A";
      } else {
        rowData[column.label] = travel[column.key] || "N/A";
      }

    });
    return rowData;
  });

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  ws["!charset"] = "UTF-8";
  XLSX.utils.book_append_sheet(wb, ws, "Viajes Finalizados");

  const columnWidths = [];
  columnConfig.forEach((column, columnIndex) => {
    const minWidth = 20;
    let maxWidth = minWidth;

    dataToExport.forEach((rowData) => {
      const cellValue = rowData[column.label] || "";
      const cellWidth = cellValue.toString().length + 2;
      if (cellWidth > maxWidth) {
        maxWidth = cellWidth;
      }
    });

    if (maxWidth < minWidth) {
      maxWidth = minWidth;
    }

    columnWidths[columnIndex] = { width: maxWidth };
  });

  ws["!cols"] = columnWidths;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const fileName = `Viajes_Finalizados_${formattedDate}.xlsx`;

  XLSX.writeFile(wb, fileName);
};
