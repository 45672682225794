import React, { useState } from 'react';
import axios from 'axios';
import ModalPasajeros from './ModalPasajeros';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function DetallesPasajeros({ rowData }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pasajeroDetails, setPasajeroDetails] = useState(null);

    const openModal = async () => {
        setModalIsOpen(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL_PASAJEROS}/${rowData.user_id}`,
                {
                    headers: {
                        "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                    },
                }
            );
            setPasajeroDetails(response.data);
        } catch (error) {
            console.error("Error fetching passenger details:", error);  
        }
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
        setPasajeroDetails(null);
    };

    return (
        <div className='text-center items-center justify-center'>
            <button
                className='bg-blueT text-white px-2 rounded-2xl font-bold hover:bg-blue-950'
                onClick={openModal}>
                <MoreHorizIcon/>
            </button>
            <ModalPasajeros
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                pasajeroDetails={pasajeroDetails}
            />
        </div>
    );
}