import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

export default function ConductoresEquipamiento() {
    const [equipmentData, setEquipmentData] = useState([]);
    useEffect(() => {
        document.title = `S. Monitoreo - Equipamientos otorgados`;
      }, []);
    useEffect(() => {
        const fetchEquipmentData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URI_LIST_EQUIPMENT, {
                    headers: {
                        "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                    },
                });

                setEquipmentData(response.data.equipments);
            } catch (error) {
                console.error("Error fetching equipment data:", error);
            }
        };

        fetchEquipmentData();
    }, []);

    const columns = [
        {
            name: 'Instalación (aaaa/mm/dd)',
            selector: 'date',
            sortable: true,
            style: { textAlign: 'center' },
        },
        {
            name: 'Conductor',
            cell: row => (
                <strong className='font-custom truncate' style={{ display: 'block', fontSize: '1rem' }}>
                    {row.driver.last_name !== null ? `${row.driver.name} ${row.driver.last_name}` : row.driver.name}
                </strong>
            ),
            sortable: true,
            selector: row => row.driver.name,
        },
        {
            name: 'Equipamiento',
            selector: 'equipment',
            sortable: true,
            center: true,
        },
        {
            name: 'Pagado',
            selector: 'paid',
            sortable: true,
            center: true
        },
    ];

    return (
        <div className='flex items-center justify-center h-screen'>
            <div>
                <DataTable
                    columns={columns}
                    data={equipmentData}
                    pagination
                    customStyles={{
                        rows: {
                            style: {
                                borderTop: '1px solid #CBD5E0',
                            },
                        },
                        headCells: {
                            style: {
                                fontSize: '1.25rem',
                                textAlign: 'center',
                            },
                        },
                        cells: {
                            style: {
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                fontSize: '1rem'
                            },
                        },
                        pagination: {
                            style: {
                                fontSize: '1rem',
                                color: 'black',
                            }
                        },
                        table: {
                            style: {
                                height: '65vh',
                                width: '70vw',
                                header: 'sticky',
                                overflowX: "hidden"
                            },
                        }
                    }}
                />
            </div>
        </div>
    );
}