import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import GetAllDrivers from '../GetAllDrivers';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../../assets/img/Loading.json';
import { useDriversNotifiedData } from '../../../utils/Get';
Modal.setAppElement('#root');

export default function NotificacionConductores({ rowData }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [driverIds, setDriverIds] = useState([]);
    const [tripIds, setTripIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const todos = GetAllDrivers();
    const { loading: driversLoading, error: driversError, data: driversData } = useDriversNotifiedData();

    const conductoresInfo = todos.map(conductor => ({
        nombreCompleto: `${conductor.name} ${conductor.last_name}`,
        idConductor: conductor.driver_id
    }));

    useEffect(() => {
        if (driversData) {
            setLoading(true);
            const allNotifications = driversData.trips_drivers_notified;

            const matchingNotifications = allNotifications.filter(
                (notification) => notification.trip_id.startsWith(rowData.trip_id)
            );

            const notificationCount = matchingNotifications.length;
            setNotificationCount(notificationCount);

            const notificationDriverIds = matchingNotifications.map((notification) => notification.driver_id);
            const notificationTripIds = matchingNotifications.map((notification) => notification.trip_id);

            setDriverIds(notificationDriverIds);
            setTripIds(notificationTripIds);
            setLoading(false);
        }
    }, [driversData, rowData.trip_id]);

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const columns = [
        {
            name: 'Conductor ID',
            selector: 'driverId',
            sortable: true,
        },
        {
            name: 'Conductor',
            selector: 'driverId',
            sortable: true,
            cell: (row) => {
                const conductor = conductoresInfo.find((c) => c.idConductor === row.driverId);
                const conductorNombre = conductor ? conductor.nombreCompleto : 'Conductor no encontrado';
                return (
                    <div className="text-left overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {conductorNombre}
                    </div>
                );
            },
        },
        {
            name: 'Viaje ID',
            selector: 'tripId',
            sortable: true,
        },
    ];

    const data = driverIds.map((driverId, index) => ({
        driverId,
        tripId: tripIds[index],
    }));

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
    };

    return (
        <div>
            <Tooltip title={`Conductores notificados: ${notificationCount}`} placement="top" arrow>
                <button
                    className='bg-blueT text-white px-2 py-1 rounded-r-2xl font-bold hover:bg-blue-950 text-current ml-1 relative'
                    onClick={handleClick}>
                    {driversLoading ? (
                        <div className="w-5 h-5 border-4 border-dashed rounded-full animate-spin absolute bottom-4 left-7 bg-orange-500"></div>
                    ) : (
                        <span className="absolute bottom-4 left-7 bg-orange-500 text-white rounded-full text-s w-8 h-6 flex items-center justify-center whitespace-nowrap">
                            {notificationCount}
                        </span>
                    )}
                    <WarningIcon />
                </button>
            </Tooltip>
            <Modal
                className={`bg-gray-100 flex flex-col rounded-xl border-4 w-modal h-modal border-orange-500 `}
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: '#00000099',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: '17vw'
                    },
                    content: {
                        overflowY: "auto",
                        overflowX: "hidden",
                    },
                }}
            >
                <div className='flex justify-end'>
                    <button
                        className='mt-1 mr-1'
                        onClick={handleCloseModal}
                    >
                        <HighlightOffOutlinedIcon
                            className="text-orange-500 hover:bg-orange-600 hover:text-white rounded-full"
                            sx={{ fontSize: '2rem' }}
                        />
                    </button>
                </div>
                <div className='m-4'>
                    {loading ? (
                        <Lottie animationData={LoadingAnimation} height={100} width={100} />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={data}
                            paginationComponentOptions={paginationComponentOptions}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 500]}
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    style: {
                                        borderTop: '1px solid #CBD5E0',
                                    },
                                },
                                headCells: {
                                    style: {
                                        fontSize: '1.25rem',
                                        textAlign: 'center',
                                    },
                                },
                                cells: {
                                    style: {
                                        marginTop: '1rem',
                                        marginBottom: '1rem',
                                        fontSize: '1rem'
                                    },
                                },
                                pagination: {
                                    style: {
                                        fontSize: '1rem',
                                        color: 'black',
                                        width: '55vw',
                                        margin: 'auto',
                                    }
                                },
                                table: {
                                    style: {
                                        height: '55vh',
                                        header: 'sticky',
                                        overflowX: "hidden",
                                        width: '65vw',
                                        margin: 'auto',
                                    },
                                }
                            }}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
}
