import '../../styles/loader.css';
import React from 'react';

const Loader = () => {
	return (
		<div className='container-loadding'>
			<span className='loader'></span>
		</div>
	);
};

export default Loader;
