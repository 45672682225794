import React, { useEffect, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import Loader from './Loader';
import store from '../../redux/store';
import Switch from 'react-switch';
// eslint-disable-next-line no-unused-vars
import days from './days';

import '../../styles/horarioForm.css';

const HorarioForm = ({
	setHorario,
	zona,
	days,
	setUpdateInfo,
	updateInfo,
	editar,
	isEditar,
	dayData,
	data,
	setDayE,
}) => {
	const [seleccionarInicio, setSeleccionarInicio] = useState('');
	const [seleccionarFin, setSeleccionarFin] = useState('');
	const [loading, setLoading] = useState(false);
	const [inicio, setInicio] = useState(null);
	const [final, setFinal] = useState(null);
	const [horaInicio, setHoraInicio] = useState(null);
	const [horaFinal, setHoraFinal] = useState(null);
	const [day, setDay] = useState(null);
	const [day_e, setDay_e] = useState(null);
	const [multiplicador, setMultiplicador] = useState(null);
	const [status, setStatus] = useState(editar?.status || false);

	const poligonoID = store.getState();

	useEffect(() => {
		if (isEditar === true && editar) {
			const convertirHoraMilitar = horaMilitar => {
				const horaNumerica = parseInt(horaMilitar, 10);

				if (
					isNaN(horaNumerica) ||
					horaNumerica < 0 ||
					horaNumerica > 2359
				) {
					return 'Hora no válida';
				}

				const horas = Math.floor(horaNumerica / 100);
				const minutos = horaNumerica % 100;

				const horaFormateada = `${agregarCero(horas)}:${agregarCero(
					minutos
				)}`;
				return horaFormateada;
			};

			const agregarCero = numero => {
				return numero < 10 ? `0${numero}` : numero.toString();
			};

			const start = convertirHoraMilitar(editar?.start_time);
			const final = convertirHoraMilitar(editar?.end_time);

			const nuevoValorSinDosPuntos = nuevoValor => {
				const valor = nuevoValor.replace(/:/g, '');

				const hora = valor.substring(0, 2);
				const minuto = valor.substring(2, 4);

				const fecha = new Date();
				fecha.setHours(parseInt(hora, 10));
				fecha.setMinutes(parseInt(minuto, 10));

				const horaFormateada = fecha.toLocaleTimeString('en-US', {
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				});

				return horaFormateada;
			};

			setSeleccionarInicio(start);
			setSeleccionarFin(final);
			setInicio(nuevoValorSinDosPuntos(start));
			setFinal(nuevoValorSinDosPuntos(final));
			setHoraInicio(editar?.start_time);
			setHoraFinal(editar?.end_time);
			setDay(editar?.day);
			setDay_e(editar?.day_e);
			setMultiplicador(editar?.multiplier);
		}
	}, [isEditar, editar]);

	const horaInicial = e => {
		const nuevoValor = e.target.value;

		setSeleccionarInicio(nuevoValor);

		const nuevoValorSinDosPuntos = nuevoValor.replace(/:/g, '');

		setHoraInicio(parseInt(nuevoValorSinDosPuntos, 10));

		const hora = nuevoValorSinDosPuntos.substring(0, 2);
		const minuto = nuevoValorSinDosPuntos.substring(2, 4);

		const fecha = new Date();
		fecha.setHours(parseInt(hora, 10));
		fecha.setMinutes(parseInt(minuto, 10));

		const horaFormateada = fecha.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});

		setInicio(horaFormateada);
	};

	const horaFinal1 = e => {
		const nuevoValor = e.target.value;

		setSeleccionarFin(nuevoValor);

		const nuevoValorSinDosPuntos = nuevoValor.replace(/:/g, '');

		setHoraFinal(parseInt(nuevoValorSinDosPuntos, 10));

		const hora = nuevoValorSinDosPuntos.substring(0, 2);
		const minuto = nuevoValorSinDosPuntos.substring(2, 4);

		const fecha = new Date();
		fecha.setHours(parseInt(hora, 10));
		fecha.setMinutes(parseInt(minuto, 10));

		const horaFormateada = fecha.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});

		setFinal(horaFormateada);
	};

	const seleccionarDia = e => {
		const selectedDayId = e.target.value;
		// eslint-disable-next-line
		const selectedDay = days.find(day => day.id == selectedDayId);

		if (selectedDay) {
			setDay(selectedDay.day);
			setDay_e(selectedDay.id);
		} else {
			setDay(null);
			setDay_e(null);
		}
	};

	// Función para validar si una nueva franja se cruza con las existentes
	function validarNuevaFranja(inicioNuevo, finNuevo) {
		const newDay = isEditar
			? dayData.filter(dia => dia.id !== editar.id)
			: data.filter(dia => dia.day_e === day_e);

		console.log(newDay);

		for (let i = 0; i < newDay.length; i++) {
			const inicioExistente = newDay[i].start_time;
			const finExistente = newDay[i].end_time;

			// Verificar si hay intersección
			if (
				(inicioNuevo >= inicioExistente &&
					inicioNuevo < finExistente) ||
				(finNuevo > inicioExistente && finNuevo <= finExistente)
			) {
				return false; // Hay intersección
			}
		}

		return true; // No hay intersección
	}

	const validaStatus = () => {
		setStatus(prevStatus => !prevStatus);
	};

	const guardarTarifa = () => {
		const id = crypto.randomUUID();
		const rate_id = isEditar ? editar.id : id;

		if (!day) {
			alert('Ingresa el día');
			return;
		}

		if (!horaInicio) {
			alert('Ingresa una hora inicial');
			return;
		}

		if (!horaFinal) {
			alert('Ingresa una hora final');
			return;
		}

		if (!multiplicador) {
			alert('Ingresa una tarifa a multiplicar');
			return;
		}

		setLoading(true);

		const hayinterseccion = validarNuevaFranja(horaInicio, horaFinal);

		if (hayinterseccion === true) {
			const variables = {
				id: rate_id,
				zona_id: poligonoID.poligonoId,
				day: day,
				day_e: day_e,
				multiplier: multiplicador,
				start_time: horaInicio,
				end_time: horaFinal,
				status: status,
			};

			const query = isEditar
				? `
      mutation UpdateDynamicTimeSlot(
        $id: String!,
        $zona_id: String,
        $day: String,
        $day_e: Int,
        $multiplier: float8,
        $start_time: Int,
        $end_time: Int,
        $status: Boolean
      ) {
        update_dynamic_time_slot_by_pk(pk_columns:{id: $id}, _set:{
          zona_id: $zona_id,
          day: $day,
          day_e: $day_e,
          multiplier: $multiplier,
          start_time: $start_time,
          end_time: $end_time,
          status: $status,
        }) {
          id
        }
      }
    `
				: `
      mutation InsertDynamicTimeSlot(
        $id: String!,
        $zona_id: String,
        $day: String,
        $day_e: Int,
        $multiplier: float8,
        $start_time: Int,
        $end_time: Int,
        $status: Boolean
      ) {
        insert_dynamic_time_slot(objects: {
          id: $id,
          zona_id: $zona_id,
          day: $day,
          day_e: $day_e,
          multiplier: $multiplier,
          start_time: $start_time,
          end_time: $end_time,
          status: $status,
        }) {
          affected_rows
          returning {
            id
            zona_id
            day
            day_e
            multiplier
            start_time
            end_time
            status
          }
        }
      }
    `;

			fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
				method: 'POST',
				headers: {
					'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					query: query,
					variables: variables,
				}),
			})
				.then(res => res.json())
				.then(() => {
					setHorario(false);
					setLoading(false);
					setUpdateInfo(!updateInfo);
					setDayE(editar.day_e);
				})
				.catch(error => {
					console.error('Error:', error);
				});
		} else {
			setLoading(false);
			return alert('No se puede crear tarifa, Horario intersectado');
		}
	};

	const diaSeleccionado = days.find(day => day.id === editar.day_e);

	return (
		<>
			{loading && <Loader />}
			<div className='div-container-form-horarios'>
				<div className='subDiv-container-form-horarios'>
					<div className='section-from-horarios-bottom-regresar'>
						<button
							onClick={() => {
								setHorario(false);
							}}
						>
							<IoIosCloseCircle />
						</button>
					</div>
					<section className='section-from-horarios-titulo'>
						<h1>Tarifa Dinamica</h1>
					</section>
					<section className='section-from-horarios-zonas'>
						<div className='div-editar-day-horarioForm-zona'>
							<p>
								Zona: <span> {zona} </span>
							</p>
						</div>
						{isEditar ? (
							<>
								<div className='div-editar-day-horarioForm'>
									<p>
										{' '}
										Dia <span>
											{diaSeleccionado.dia}
										</span>{' '}
									</p>
								</div>
							</>
						) : (
							<>
								<div className='div-editar-day-horarioForm'>
									<select
										value={day_e}
										onChange={e => seleccionarDia(e)}
									>
										<option value=''>
											Selecciona un dia
										</option>
										{days.map(day => (
											<option key={day.id} value={day.id}>
												{day.dia}
											</option>
										))}
									</select>
								</div>
							</>
						)}
					</section>
					<section className='section-from-horarios-elegir-horarios'>
						<div>
							<h2>Hora de Inicio</h2>
							<div>
								<label htmlFor='Hora-A'>
									{isEditar ? '' : 'Selecciona Hora'}
									<input
										id='Hora-A'
										type='time'
										value={seleccionarInicio}
										onChange={horaInicial}
									/>
								</label>
								<p>Comienza desde: {inicio} </p>
							</div>
						</div>
						<div>
							<h2>Hora de Final</h2>
							<div>
								<label htmlFor='Hora-B'>
									{isEditar ? '' : 'Selecciona Hora'}
									<input
										id='Hora-B'
										type='time'
										value={seleccionarFin}
										onChange={horaFinal1}
									/>
								</label>
								<p>Termina hasta: {final} </p>
							</div>
						</div>
					</section>
					<section className='section-from-horarios-multiplicador'>
						<label htmlFor='multiplicador'>
							Agregar Multiplicador
						</label>
						<input
							type='text'
							id='multiplicador'
							placeholder='1.2'
							defaultValue={multiplicador}
							onChange={e => {
								setMultiplicador(e.target.value);
							}}
						/>
						<p>
							Agrega solo el numero que quieres que se aplique a
							la tarifa.
						</p>
					</section>
					{isEditar && (
						<>
							<div className='status-switch-horario-from'>
								<p>
									Status de la tarifa:{' '}
									<span
										style={{
											color: status ? 'green' : 'red',
										}}
									>
										{status ? 'Activa' : 'Inactiva'}
									</span>
								</p>
								<Switch
									onChange={validaStatus}
									checked={status}
								/>
							</div>
						</>
					)}

					<section className='section-from-horarios-bottom-guardar'>
						<button onClick={guardarTarifa}>Guardar</button>
					</section>
				</div>
			</div>
		</>
	);
};

export default HorarioForm;
