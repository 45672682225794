import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Vehiculos = () => {
    const [vehicles, setVehicles] = useState([]);
    const [nikucarCount, setNikucarCount] = useState(0);
    const [securityCount, setSecurityCount] = useState(0);
    const [securityComfortCount, setSecurityComfortCount] = useState(0);

    useEffect(() => {
        const fetchConductoresData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_DRIVERS,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );
                const drivers = response.data.drivers;

                let nikucarCount = 0;
                let securityCount = 0;
                let securityComfortCount = 0;

                drivers.forEach(driver => {
                    const vehicleType = driver.vehicle_id_type && driver.vehicle_id_type.name;

                    if (vehicleType === "Nikucar") {
                        nikucarCount++;
                    } else if (vehicleType === "Security") {
                        securityCount++;
                    } else if (vehicleType === "Security and Comfort") {
                        securityComfortCount++;
                    }
                });

                setVehicles(drivers);
                setNikucarCount(nikucarCount);
                setSecurityCount(securityCount);
                setSecurityComfortCount(securityComfortCount);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchConductoresData();
    }, []);

    const data = [
        { b: nikucarCount, Nikucar: nikucarCount, x: "Nikucar" },
        { b: securityCount, Security: securityCount, x: "Security" },
        { b: securityComfortCount, Comfort: securityComfortCount, x: "Comfort" },
    ];

    return (
        <div className="flex items-center justify-center h-full">
            <div>
                <h2 className='text-xl font-bold text-center'>Vehículos</h2>
                <div>
                    <BarChart width={450} height={300} data={data}>
                        <XAxis dataKey="x" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Nikucar" fill="#3aa5e6" name="Nikucar" />
                        <Bar dataKey="Security" fill="#51171D" name="Security" />
                        <Bar dataKey="Comfort" fill="#088032" name="Comfort" />
                    </BarChart>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Vehiculos);