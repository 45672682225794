import React from 'react';
import TablaViajes from './ViajesFinalizados/TablaViajes'

const ViajesFinalizados = () => {
    return (
        <div>
            <TablaViajes />
        </div>
    );
}

export default ViajesFinalizados;
