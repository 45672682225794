import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Ingresos = () => {
  const [data, setData] = useState([]);
  const [totalIngresosTerminadoDia, setTotalIngresosTerminadoDia] = useState(0);
  const [totalIngresosTerminadoMes, setTotalIngresosTerminadoMes] = useState(0);
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL, {
          headers: {
            'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
          },
        });
        setData(response.data.trips);
      } catch (error) {
        console.error('Error al obtener la información de la API:', error.message);
      }
    };

    // fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setCurrentDate(getCurrentDate());
    }
  }, [data]);

  useEffect(() => {
    const terminadosDelDia = data.filter(
      (trip) => trip.status === 'Terminado' && getFormattedDate(trip.time_accepted) === currentDate
    );
    const totalTerminadoDelDia = terminadosDelDia.reduce((acc, trip) => acc + trip.tarifa_total, 0);
    setTotalIngresosTerminadoDia(totalTerminadoDelDia);
  }, [data, currentDate]);

  useEffect(() => {
    const terminadosDelMes = data.filter(
      (trip) => trip.status === 'Terminado' && isSameMonth(trip.time_accepted)
    );
    const totalTerminadoDelMes = terminadosDelMes.reduce((acc, trip) => acc + trip.tarifa_total, 0);
    setTotalIngresosTerminadoMes(totalTerminadoDelMes);
  }, [data]);

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const getFormattedDate = (timeAccepted) => {
    const date = new Date(timeAccepted);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const isSameMonth = (timeAccepted) => {
    const currentDateObj = new Date();
    const acceptedDate = new Date(timeAccepted);
    return (
      currentDateObj.getMonth() === acceptedDate.getMonth() &&
      currentDateObj.getFullYear() === acceptedDate.getFullYear()
    );
  };

  const chartData = [
    { y: totalIngresosTerminadoDia, z: 0, valor: totalIngresosTerminadoDia, x: "Dia" },
    { y: 0, z: totalIngresosTerminadoMes, value: totalIngresosTerminadoMes, x: "Mes" },
  ];

  return (
    <div className="flex items-center justify-center h-full">
      <div>
        <h2 className='text-xl font-bold text-center'>Ingresos</h2>
        <p className='font-bold text-end text-lg'>FECHA: {currentDate}</p>
        <BarChart width={450} height={300} data={chartData}>
          <XAxis dataKey="x" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="valor" fill="#3aa5e6" name="Ingreso del día" />
          <Bar dataKey="value" fill="#008000" name="Ingreso del mes" />
        </BarChart>
      </div>
    </div>
  );
};

export default React.memo(Ingresos);