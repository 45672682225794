import React, { useState } from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import mapa from '../../assets/img/mapa.png';
import '../../styles/poligonoForm.css';

const PoligonoForm = ({ setModal, handleReset, handleSave }) => {
	const [name, setName] = useState('');

	const guardarPoligono = e => {
		e.preventDefault();
		if (name) {
			handleSave(name);
		} else {
			return alert('debe ingresar un nombre');
		}
	};

	const cerrarForm = () => {
		const confirmarEliminacion = window.confirm(
			'¿Seguro que deseas cerrar y cancelar esta operacion?'
		);

		if (confirmarEliminacion) {
			setName('');
			handleReset();
			setModal(false);
		}
	};

	return (
		<>
			<section className='section-poligono-form'>
				<form onSubmit={guardarPoligono} className='form-poligono-form'>
					<div className='div-buttom-form-poligono-cerrar'>
						<button
							type='button'
							onClick={() => {
								cerrarForm();
							}}
							className='buttom-form-poligono-cerrar'
						>
							<CancelIcon className='icon-poligono-close' />
						</button>
					</div>
					<h2>Nombre del Poligono</h2>
					<div className='div-input-poligono-form'>
						<AddLocationAltIcon className='icon-poligono' />
						<input
							type='text'
							placeholder='Ingresa el Nombre '
							onChange={e => {
								setName(e.target.value);
							}}
						/>
					</div>
					<img
						src={mapa}
						alt='mapa'
						className='iframe-lottie-poligono'
					/>
					<button
						className='buttom-form-poligono-guardar'
						type='submit'
					>
						Guardar
					</button>
				</form>
			</section>
		</>
	);
};

export default PoligonoForm;
