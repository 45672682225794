import React, { useState } from 'react'

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = (event) => {
    const value = event.target.value
    setSearchTerm(value)
    onSearch(value)
  }

  const inputStyle = {
    backgroundColor: '#eaecec',
    padding: '8px',
    borderRadius: '4px',
    border: 'none',
  }

  const handleCleanSearch = () => {
    setSearchTerm('')
    onSearch('')
  }

  return (
    <div className="search-bar text-lg mb-2">
      <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearch}
        style={inputStyle}
        className='text-2xl'
      />
      <small
        className="relative text-xl text-orange-500 font-bold right-6 hover:text-orange-700 cursor-pointer"
        onClick={handleCleanSearch}
      >
        X
      </small>
    </div>
  )
}

export default SearchBar
