import React from 'react';
import CurrentTripsTable from './CurrentTrips/CurrentTripsTable';

const ViajesEnCurso = ({ trips }) => {
    return (
        <>
            <CurrentTripsTable trips={trips} />
        </>
    );
}

export default ViajesEnCurso;
