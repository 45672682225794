import React from 'react';
import Tabla from './Conductores/TablaConductores';

const Conductores = () => {
    return (
        <div className='ml-30 mr-30 mt-10'>
            <Tabla />
        </div>
    );
}

export default Conductores;