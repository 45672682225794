import React from 'react';
import Modal from 'react-modal';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import fotoAlterna from '../../../assets/img/pasajero_niku.png'

Modal.setAppElement('#root');

export default function ModalPasajeros({ isOpen, onRequestClose, pasajeroDetails }) {
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            return phoneNumber.replace(/\D/g, '');
        }
        return '';
        
    };
    return (
        <Modal
            className={`bg-gray-100 flex flex-col gap-4 rounded-2xl border-4 border-orange-500`}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    backgroundColor: '#00000099',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '17vw',
                },
                content: {
                    overflowY: "auto",
                    overflowX: "hidden",
                },
            }}
        >
            <div className="flex flex-col-reverse m-2">

                <button className="self-end" onClick={onRequestClose}>
                    <HighlightOffOutlinedIcon className="text-orange-500 hover:bg-orange-600 hover:text-white rounded-full" sx={{ fontSize: '2rem' }} />
                </button>
            </div>
            {pasajeroDetails && (
                <div className='flex mb-2 mx-2'>
                    <div className='grid grid-row-2 mr-4'>
                        <div className='row-span-1 flex justify-center items-center'>
                            {pasajeroDetails.user_by_pk.photo ? (
                                <img
                                    src={pasajeroDetails.user_by_pk.photo}
                                    alt="Foto de perfil"
                                    style={{
                                        borderRadius: '50%',
                                        width: '250px',
                                        height: '250px',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <img
                                    src={fotoAlterna}
                                    alt="Imagen alternativa"
                                    style={{
                                        borderRadius: '50%',
                                        width: '250px',
                                        height: '250px',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                        </div>
                        <div className='row-span-1 space-y-4 pb-2 px-2'>
                            <p className='mt-2 text-center font-bold'>
                                {pasajeroDetails.user_by_pk.name ? pasajeroDetails.user_by_pk.name.toUpperCase() : 'Sin nombre'}{' '}
                                {pasajeroDetails.user_by_pk.last_name ? pasajeroDetails.user_by_pk.last_name.toUpperCase() : ''}
                            </p>
                            <p className='semi:col-span-3 ml-2'><span className='font-bold'>ID:</span> {pasajeroDetails.user_by_pk.user_id}</p>
                            <p className='semi:col-span-3 ml-2'>
                                <span className='font-bold'>Telefono:</span>{' '}
                                {pasajeroDetails.user_by_pk.phone ? (
                                    <a
                                        className='text-blue-600 underline'
                                        href={`https://wa.me/${formatPhoneNumber(pasajeroDetails.user_by_pk.phone)}?text=¡Buenas%20tardes!`}
                                        target="_blank"
                                    >
                                        {pasajeroDetails.user_by_pk.phone}
                                    </a>
                                ) : 'Sin teléfono'}
                            </p>
                            <p className='semi:col-span-3 ml-2'>
                                <span className='font-bold'>Email:</span>{' '}
                                {pasajeroDetails.user_by_pk.email ? (
                                    <a
                                        className='text-blue-600 underline'
                                        href={`mailto:${pasajeroDetails.user_by_pk.email}`}
                                    >
                                        {pasajeroDetails.user_by_pk.email}
                                    </a>
                                ) : 'Sin email'}
                            </p>
                            <p className='semi:col-span-3 ml-2'><span className='font-bold'>Estatus:</span> {pasajeroDetails.user_by_pk.status || 'Desconocido'}</p>
                        </div>
                    </div>
                </div>
            )}

        </Modal>
    );
}