import XLSX from 'xlsx-js-style';

export const exportToExcel = (filteredPasajeros, columnConfig) => {
    const wb = XLSX.utils.book_new();
    const dataToExport = filteredPasajeros.map(passenger => {
        const rowData = {};
        columnConfig.forEach(column => {
            const keys = column.key.split('.');
            let value = passenger;
            for (const key of keys) {
                if (value && key in value) {
                    value = value[key];
                } else {
                    value = 'N/A';
                    break;
                }
            }
            if (column.key === 'name') {
                const lastName = passenger.last_name || 'N/A';
                value = `${value} ${lastName}`;
            }
            if (column.key === 'last_log_date' || column.key === 'register_date') {
                let timestamp = parseFloat(value);
                if (!isNaN(timestamp)) {
                    const date = new Date(timestamp);
                    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
                    rowData[column.label] = formattedDate;
                } else {
                    rowData[column.label] = 'N/A';
                }
            } else if (column.key === 'rating') {
                if (value !== null) {
                    rowData[column.label] = value;
                } else {
                    rowData[column.label] = 'N/A';
                }
            } else {
                rowData[column.label] = value;
            }
        });
        return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    ws['!charset'] = 'UTF-8';
    XLSX.utils.book_append_sheet(wb, ws, 'Pasajeros');

    const columnWidths = [];
    columnConfig.forEach((column, columnIndex) => {
        const minWidth = 20;
        let maxWidth = minWidth;

        dataToExport.forEach(rowData => {
            const cellValue = rowData[column.label] || '';
            const cellWidth = cellValue.toString().length + 7;
            if (cellWidth > maxWidth) {
                maxWidth = cellWidth;
            }
        });

        if (maxWidth < minWidth) {
            maxWidth = minWidth;
        }

        columnWidths[columnIndex] = { width: maxWidth };
    });

    ws['!cols'] = columnWidths;
    const currentDate = new Date()
    const formattedDate = currentDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    const fileName = `Pasajeros_${formattedDate}.xlsx`

    XLSX.writeFile(wb, fileName)
};