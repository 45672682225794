import { useState, useEffect } from 'react'

const useShowIcons = () => {
  const [showIcons, setShowIcons] = useState(window.innerWidth <= 1450)

  useEffect(() => {
    const handleResize = () => {
      setShowIcons(window.innerWidth <= 1450)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { showIcons }
}
export default useShowIcons
