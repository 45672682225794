import React, { useState, useEffect } from 'react';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MapIcon from '@mui/icons-material/Map';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MovingIcon from '@mui/icons-material/Moving';
import CheckIcon from '@mui/icons-material/Check';
import Modal from 'react-modal';
import NikuCar from "../../assets/img/niku_car_blanco.png";
import { ref, get } from 'firebase/database';
import { useAuth } from '../../context/AuthContext';
import { db } from "../../firebase/firebase";

Modal.setAppElement('#root');

const NavBar = ({ setCurrentPage }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { user, logout } = useAuth();
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        if (user) {
            const userId = user.uid;
            const roleRef = ref(db, `projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/DASHBOARD_User/${userId}/role`);

            get(roleRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const role = snapshot.val();
                        setUserRole(role);
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener el rol del usuario:', error);
                });
        }
    }, [user]);

    const handleLogout = async () => {
        //const user = await auth.currentUser;
        //if (user) {
            try {
                await logout();
              // await signOut(auth);
              // window.location.href = '/';
            } catch (error) {
                console.error('Error al cerrar sesión:', error);
            }
        //}
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleButtonClick = (page) => {
        setCurrentPage(page);
        setMenuOpen(false);
    };

    return (
        <div className='xl:hidden bg-blueT grid grid-cols-3'>
            <button className='text-white mt-3 text-xl relative z-50 ml-2 mb-2 w-10 h-10 hover:text-black' onClick={toggleMenu}>
                <MenuOutlinedIcon />
            </button>
            <Modal
                isOpen={menuOpen}
                onRequestClose={toggleMenu}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        padding: 0,
                        width: '17rem',
                        height: userRole === 'Admin' ? '26.5rem' : userRole === 'Master' ? '23rem' : '20rem',
                        overflow: 'visible',
                        background: '#004990',
                    },
                }}
            >
                <div className="flex flex-col ml-2 mr-2 mb-2">
                    {(userRole === 'Admin' || userRole === 'Master' || userRole === 'control') && (
                        <button className='text-white rounded-2xl mt-2 hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('Home')}>
                            <HomeIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Home
                        </button>
                    )}
                    {(userRole === 'Admin' || userRole === 'Master' || userRole === 'control') && (
                        <button className='text-white rounded-2xl hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('Conductores')}>
                            <DirectionsCarIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Conductores
                        </button>
                    )}
                    {(userRole === 'Admin' || userRole === 'Master' || userRole === 'control') && (
                        <button className='text-white rounded-2xl hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('ViajesEnCurso')}>
                            <MovingIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Viajes En Curso
                        </button>
                    )}
                    {(userRole === 'Admin' || userRole === 'Master' || userRole === 'control') && (
                        <button className='text-white rounded-2xl hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('ViajesFinalizados')}>
                            <CheckIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Finalizados
                        </button>
                    )}
                    {(userRole === 'Admin') && (
                        <button className='text-white rounded-2xl hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('Pasajeros')}>
                            <EmojiPeopleIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Pasajeros
                        </button>
                    )}
                    {(userRole === 'Admin' || userRole === 'Master') && (
                        <button className='text-white rounded-2xl hover:bg-white hover:text-black w-full text-left p-2' onClick={() => handleButtonClick('Mapa')}>
                            <MapIcon className="inline-block ml-1 mb-1 w-5 h-5 mr-2" />
                            Mapa
                        </button>
                    )}
                </div>
                <div className="flex flex-col items-center big:mt-5 text-white">
                    <AccountCircleIcon sx={{ fontSize: '2rem' }} />
                    <button
                        className="bg-white mt-2 rounded-full text-lg p-2 aspect-w-4 aspect-h-1 text-BlueD hover:text-black hover:scale-105 transition-all hover:font-bold"
                        onClick={handleLogout}
                    >
                        Cerrar sesión
                    </button>
                    <button className='mt-2 hover:text-orange-500'>
                        NIKU TECNOLOGIA, S.A. DE C.V. v.2.0
                    </button>
                </div>
            </Modal>
            <h1 className='text-white xs:text-xl 2xs:mt-2 xs:mt-4 2xs:text-md 2xs:hidden cel:block justify-self-center col-span-1'>Monitero NikuCar</h1>
            <img src={NikuCar} alt="NikuCar" className="xs:h-14 xs:w-20 2xs:h-8 2xs:w-10 col-span-1 col-start-3 self-center justify-self-end mb-4 mr-4" />
        </div>
    );
}

export default NavBar;