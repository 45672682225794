import React from 'react';

export default function DatosDelVehiculo({ vehicleDetails }) {
    if (!vehicleDetails || !vehicleDetails.cards) {
        return <p className='mb-7'>No cuenta con datos del vehiculo</p>;
    }
    const renderValueOrNA = (value) => {
        return value !== null ? value : "N/A";
    };
    return (
        <div>
            <div className='grid grid-cols-2 gap-2 text-md mb-7'>
                <div className='col-span-2 row-auto bg-blue-200 text-center font-bold text-blue-600 rounded-t-xl'>
                    <h1 className='mt-1 mb-1'>Datos del vehiculo</h1>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Marca</div>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Modelo</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{vehicleDetails.cards.marca}</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{vehicleDetails.cards.model}</div>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Matrícula</div>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Tipo</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{vehicleDetails.cards.placa}</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{renderValueOrNA(vehicleDetails.vehicle_id_type?.vehicletype)}</div>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Año</div>
                </div>
                <div className='col-span-1 row-auto bg-BlueD text-center text-white'>
                    <div className='font-bold mt-1 mb-1'>Color</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{vehicleDetails.cards.year}</div>
                </div>
                <div className='col-span-1 row-auto bg-blue-200 text-center text-blue-600'>
                    <div className='mt-1 mb-1'>{vehicleDetails.cards.color}</div>
                </div>
            </div>
        </div>
    );
}