import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Viajes = () => {
    const [viajes, setViajes] = useState([]);

    useEffect(() => {
        const fetchViajesData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_VIAJES,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );
                const viajesData = response.data.trips;
                setViajes(viajesData);
            } catch (error) {
                console.error("Error fetching trip data:", error);
            }
        };

        fetchViajesData();
    }, []);

    const viajesTerminados = viajes.filter(viaje => viaje.status === 'Terminado').length;
    const viajesCancelados = viajes.filter(viaje => viaje.status === 'Cancelado').length;

    const data = [
        { a: viajesTerminados, Terminados: viajesTerminados, x: "Terminados" },
        { b: viajesCancelados, Cancelados: viajesCancelados, x: "Cancelados" },
    ];

    return (
        <div className="flex items-center justify-center h-full">
            <div>
                <h2 className='text-xl font-bold text-center'>Viajes</h2>
                <BarChart width={450} height={300} data={data}>
                    <XAxis dataKey="x" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Terminados" fill="#3aa5e6" name="Terminados" />
                    <Bar dataKey="Cancelados" fill="#008000" name="Cancelados" />
                </BarChart>
                <div className='ml-8 font-bold'>
                    <p>Viajes terminados: {viajesTerminados}</p>
                    <p>Viajes cancelados: {viajesCancelados}</p>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Viajes);
