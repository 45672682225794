import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import DataTable from 'react-data-table-component';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DRIVERS_ON_LINE } from '../../../schemas/querys';
import ModalDriversByLocality from './ModalDriversByLocality'; // Asegúrate de ajustar la ruta

const customStyles = {
    cells: {
        style: {
            backgroundColor: "rgba(0, 73, 144)",
            color: "white",
        },
    },
    headCells: {
        style: {
            backgroundColor: "rgba(0, 73, 144)",
            color: "white",
        },
    },
};

export default function DriversByState() {
    const { loading, error, data } = useQuery(DRIVERS_ON_LINE);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLocality, setSelectedLocality] = useState('');
    const [driversData, setDriversData] = useState([]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const groupedDrivers = data.drivers.reduce((acc, driver) => {
        const locality = driver.address_locality || 'Desconocido';
        if (!acc[locality]) {
            acc[locality] = [];
        }
        acc[locality].push(driver);
        return acc;
    }, {});

    const tableData = Object.keys(groupedDrivers).map(locality => ({
        locality,
        count: groupedDrivers[locality].length
    }));

    const columns = [
        {
            name: 'Localidad',
            selector: row => row.locality,
            sortable: true,
        },
        {
            name: 'Conductores',
            selector: row => row.count,
            sortable: true,
            center: true
        },
        {
            name: 'Detalles',
            center: true,
            cell: row => (
                <button
                    onClick={() => {
                        setSelectedLocality(row.locality);
                        setDriversData(groupedDrivers[row.locality]);
                        setModalOpen(true);
                    }}
                >
                    <InfoOutlinedIcon />
                </button>
            ),
            sortable: false,
        },
    ];

    return (
        <div style={{ maxHeight: "400px", overflowY: "auto" }} className="fixed right-0 top-0 bg-blueT 2xs:mt-20 w-96 semi:block 2xs:hidden p-4 mr-16 shadow-md text-white bg-opacity-60">
            <h1 className='text-center'>Conductores por Localidad</h1>
            <DataTable
                columns={columns}
                data={tableData}
                customStyles={customStyles}
            />
            {modalOpen && (
                <ModalDriversByLocality
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    locality={selectedLocality}
                    drivers={driversData}
                />
            )}
        </div>
    );
}
