import React from 'react';
import '../../styles/alertaPanico.css';

const AlertaPanico = ({ setMostrarPanicAlert, setCurrentPage }) => {
	const mostrarPanico = () => {
		setMostrarPanicAlert(false);
		setCurrentPage('Panico');
	};

	return (
		<>
			<section className='section-alerta-panico'>
				<div className='div-container-alerta-panico'>
					<h1>¡Hay una Emergencia! </h1>
					<iframe
						src='https://lottie.host/embed/fe649215-66e2-4371-9eaa-a446b92138b1/17cplZKHb3.json'
						title='Panico-alerta'
					></iframe>
					<p>
						Hemos detectado que se ha activado el botón de pánico en
						uno de los servicios presentes en tu zona.
					</p>
					<div>
						<button
							onClick={() => {
								mostrarPanico();
							}}
						>
							Conocer Detalles
						</button>
					</div>
				</div>
			</section>
		</>
	);
};

export default AlertaPanico;
