import React, { useState, useEffect, useRef } from 'react';
import { mapStyle } from '../Sidebar/Mapa/Estilo';
import toast, { Toaster } from 'react-hot-toast';
import { FaPlusCircle } from 'react-icons/fa';
import PoligonoForm from './PoligonoForm';
import Loader from './Loader';
import {
	GoogleMap,
	Marker,
	Polyline,
	useJsApiLoader,
	Autocomplete,
} from '@react-google-maps/api';
import '../../styles/poligonos.css';

const Poligonos = () => {
	const [markers, setMarkers] = useState([]);
	const [closed, setClosed] = useState(false);
	const [save, setSave] = useState(false);
	const [mapCenter, setMapCenter] = useState(null);
	const [map, setMap] = useState(null);
	const [modal, setModal] = useState(false);
	const [loader, setLoader] = useState(false);
	const autocompleteRef = useRef(null);

	const notify = () => toast.success('Zona creada exitosamente');

	const arrayL = ['geometry', 'drawing', 'places'];

	const defaultZoom = 12;
	const defaultCenter = {
		lat: 19.0589756,
		lng: -98.1811881,
	};

	useEffect(() => {
		if (map && mapCenter) {
			map.panTo(mapCenter);
		}
	}, [map, mapCenter]);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		libraries: arrayL,
	});

	const mapStyles = {
		height: '100vh',
		width: '100%',
	};

	const handleMapClick = event => {
		const { latLng } = event;
		const latitude = latLng.lat();
		const longitude = latLng.lng();

		const newMarker = { lat: latitude, lng: longitude };
		setMarkers([...markers, newMarker]);

		setClosed(true);

		if (markers.length >= 2 && !save) {
			setSave(true);
		}

		if (markers.length === 0) {
			setMapCenter(newMarker);
		}
	};

	const handleSave = nombre => {
		setLoader(true);
		const poligono_id = crypto.randomUUID();

		const coordinates = markers.map(marker => [marker.lng, marker.lat]);
		coordinates.push(coordinates[0]);

		const poligono = {
			type: 'Polygon',
			crs: {
				type: 'name',
				properties: {
					name: 'urn:ogc:def:crs:EPSG::4326',
				},
			},
			coordinates: [coordinates],
		};

		const variables = {
			poligono_id: poligono_id,
			environment: 2,
			nombre: nombre,
			status: false,
			poligono: poligono,
		};

		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
                mutation Create_zone_ADMIN(
                    $poligono_id: String!,
                    $environment: smallint,
                    $nombre: String,
                    $status: Boolean,
                    $poligono: geometry
                    ) {
                      insert_poligonos(objects: {
                        environment: $environment,
                        nombre: $nombre,
                        status: $status,
                        poligono_id: $poligono_id, 
                        poligono: $poligono
                      }) {
                        returning {
                          poligono_id
                        }
                      }
                    }`,

				variables,
			}),
		})
			.then(res => res.json())
			.then(() => {
				setTimeout(() => {
					setModal(false);
					handleReset();
					setLoader(false);
					setTimeout(() => {
						notify();
					}, 2000);
				});
			}, 2000)
			.catch(error => {
				console.error('Error:', error);
			});
	};

	const handleReset = () => {
		setClosed(false);
		setSave(false);
		setMarkers([]);
		setMapCenter(null);
		setMapCenter(defaultCenter);
	};

	const handleClean = () => {
		setClosed(false);
		setSave(false);
		setMarkers([]);
	};

	const handlePlaceSelected = () => {
		const place = autocompleteRef.current.getPlace();
		if (place.geometry) {
			setMapCenter({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			});
		}
	};

	const handleMarkerDragEnd = (event, index) => {
		const { latLng } = event;
		const latitude = latLng.lat();
		const longitude = latLng.lng();

		const updatedMarkers = markers.map((marker, i) =>
			i === index ? { lat: latitude, lng: longitude } : marker
		);

		setMarkers(updatedMarkers);
	};

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<>
					{modal ? (
						<PoligonoForm
							setModal={setModal}
							handleReset={handleReset}
							handleSave={handleSave}
						/>
					) : (
						isLoaded && (
							<>
								<GoogleMap
									mapContainerStyle={mapStyles}
									zoom={defaultZoom}
									center={mapCenter || defaultCenter}
									options={{
										styles: mapStyle.default,
									}}
									onClick={handleMapClick}
									onLoad={map => setMap(map)}
								>
									{markers.map((marker, index) => (
										<Marker
											key={index}
											position={marker}
											draggable={true}
											onDragEnd={event =>
												handleMarkerDragEnd(
													event,
													index
												)
											}
										/>
									))}

									{closed && (
										<Polyline
											path={
												closed
													? [...markers, markers[0]]
													: []
											}
											options={{
												strokeColor: 'orange',
												strokeOpacity: 0.8,
												strokeWeight: 2,
											}}
										/>
									)}

									<div className='autocomplete-container'>
										<Autocomplete
											onLoad={autocomplete =>
												(autocompleteRef.current =
													autocomplete)
											}
											onPlaceChanged={handlePlaceSelected}
										>
											<input
												type='text'
												placeholder='Buscar lugar'
												className='autocomplete-input'
											/>
										</Autocomplete>
									</div>
									<div className='button-poligono-reset'>
										{save && (
											<button
												id='button-poligono-save'
												onClick={() => setModal(true)}
											>
												<FaPlusCircle id='icon-guardar-poligono' />
											</button>
										)}

										<button
											onClick={() => {
												handleReset();
											}}
										>
											Resetear Marcadores
										</button>
									</div>
									<button
										id='limpiar-marcadores'
										onClick={() => {
											handleClean();
										}}
									>
										limpiar Marcadores
									</button>
									<Toaster
										position='top-center'
										reverseOrder={false}
										toastOptions={{
											duration: 5000,
											style: {
												background: '#363636',
												color: '#fff',
											},
										}}
									/>
								</GoogleMap>
							</>
						)
					)}
				</>
			)}
		</>
	);
};

export default Poligonos;
