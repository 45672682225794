/* const getVehicles = async () => {
	return fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
		method: 'POST',
		headers: {
			'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			query: `
        query GetVehicles {
          vehicles {
            vehiculo_id
            image
          }
        }
      `,
		}),
	})
		.then(res => res.json())
		.then(res => res.data.vehicles);
}; */

const getVehicles = [
	{
		vehiculo_id: '094363hl-s11ed-8a29-75bea275esad2w',
		image: 'https://firebasestorage.googleapis.com/v0/b/nikucar-apk.appspot.com/o/media%2Fproj_iDtR68Uec2W1ohmGzgtMRo%2Fmedia_niku%2Fcontact-97574_640.png?alt=media&token=a2923d14-f4fc-44c2-8381-6bdd5074e1cf',
	},
	{
		vehiculo_id: '91111b73-2439-4d26-b3c1-c462c5b0f4e0',
		image: 'https://firebasestorage.googleapis.com/v0/b/nikucar-apk.appspot.com/o/media%2Fproj_iDtR68Uec2W1ohmGzgtMRo%2Fmedia_niku%2FNikuComfort%201.png?alt=media&token=7e2bf627-ef8a-4313-8322-48f2ac103f9a',
	},
	{
		vehiculo_id: '09436de0-4987-11ed-8a29-75bea275e626',
		image: 'https://firebasestorage.googleapis.com/v0/b/nikucar-apk.appspot.com/o/media%2Fproj_iDtR68Uec2W1ohmGzgtMRo%2Fmedia_niku%2FNikuCar%201.png?alt=media&token=a897c70b-4f58-4d87-ba37-313a4e5afd95',
	},
	{
		vehiculo_id: '82f8f8b0-f5cb-4234-89e1-167c1f0822a6',
		image: 'https://firebasestorage.googleapis.com/v0/b/nikucar-apk.appspot.com/o/media%2Fproj_iDtR68Uec2W1ohmGzgtMRo%2Fmedia_niku%2FNikuSafe02%201.png?alt=media&token=36553b86-1c5e-4442-a2ed-b70ae3b765a1',
	},
	{
		vehiculo_id: '08f97404-ba81-4a3f-b4aa-f5ac05fa2c85',
		image: 'https://firebasestorage.googleapis.com/v0/b/nikucar-apk.appspot.com/o/media%2Fproj_iDtR68Uec2W1ohmGzgtMRo%2Fmedia_niku%2FNikuLady03%201.png?alt=media&token=fd71ae8b-5398-4b72-8021-11f5c83f6245',
	},
];

export default getVehicles;
