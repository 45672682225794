import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import DetallesPasajeros from './DetallesPasajeros';
import SearchBar from '../SearchBar';
import { exportToExcel } from './ExcelExporter';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DatePicker } from '@mui/x-date-pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ExpandableComponent from './ExpandableComponent';
import { useQuery } from '@apollo/client';
import { ALL_USERS } from '../../../schemas/querys';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TablaPasajeros = () => {
    const [pasajeros, setPasajeros] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredPassengersDate, setFilteredPassengersDate] = useState(pasajeros);
    const [expandedRows, setExpandedRows] = useState([]);
    // const { loading, error, data } = useQuery(ALL_USERS);
    const applyDateRangeFilter = () => {

        if (!startDate || !endDate) {
            setFilteredPassengersDate(pasajeros);
            return;
        }

        const startRange = new Date(startDate).getTime();
        let endRange = new Date(endDate);
        endRange.setHours(23, 59, 59, 999);
        endRange = endRange.getTime();


        const validPassengers = pasajeros.filter(pasajero => pasajero.register_date !== null);

        validPassengers.forEach(pasajero => {
            pasajero.register_date = parseInt(pasajero.register_date);
        });

        const filteredPassengersDate = validPassengers.filter(pasajero => {
            const passengerDate = pasajero.register_date;
            return passengerDate >= startRange && passengerDate <= endRange;
        });


        setFilteredPassengersDate(filteredPassengersDate);
    };

    const handleRowToggle = (row) => {
        const rowIndex = expandedRows.indexOf(row);
        const newExpandedRows = [...expandedRows];
        if (rowIndex > -1) {
            newExpandedRows.splice(rowIndex, 1);
        } else {
            newExpandedRows.push(row);
        }
        setExpandedRows(newExpandedRows);
    };


    useEffect(() => {
        setFilteredPassengersDate(pasajeros);
    }, [pasajeros]);

    const handleSearch = (searchTerm, startDate, endDate) => {
        if (!searchTerm.trim() && !startDate && !endDate) {
            setFilteredPassengersDate(pasajeros);
            return;
        }

        const filtered = pasajeros.filter((pasajero) => {
            const nombre = pasajero.name ? pasajero.name.trim() : '';
            const apellido = pasajero.last_name ? pasajero.last_name.trim() : '';
            const nombreCompleto = formatName(nombre, apellido).toLowerCase();
            const email = formatValue(pasajero.email).toLowerCase();
            const registro = formatDate(pasajero.register_date).toLowerCase();
            const ultimaConexion = formatDate(pasajero.last_log_date).toLowerCase();
            const id = pasajero.user_id.toLowerCase();

            const matchesSearchTerm = (
                id.includes(searchTerm.toLowerCase()) ||
                nombreCompleto.includes(searchTerm.toLowerCase()) ||
                email.includes(searchTerm.toLowerCase()) ||
                registro.includes(searchTerm.toLowerCase()) ||
                ultimaConexion.includes(searchTerm.toLowerCase())
            );

            const isInDateRange = (
                (!startDate || new Date(pasajero.register_date).getTime() >= new Date(startDate).getTime()) &&
                (!endDate || new Date(pasajero.register_date).getTime() <= new Date(endDate).getTime())
            );

            return matchesSearchTerm && isInDateRange;
        });

        setFilteredPassengersDate(filtered);
    };


    useEffect(() => {
        const sortedPasajeros = [...pasajeros].sort((a, b) => b.register_date - a.register_date);
        setFilteredPassengersDate(sortedPasajeros);
    }, [pasajeros]);

    useEffect(() => {
        document.title = `S. Monitoreo - Pasajeros`;
        const fetchPasajerosData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_URL_PASAJEROS,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );
                setPasajeros(response.data.user);
            } catch (error) {
                console.error("Error fetching passengers data:", error);
            }
        };
        fetchPasajerosData();
    }, []);

    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Sin datos";
        }
        const date = new Date(parseInt(timestamp));
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    };

    const formatValue = (value) => {
        return value !== null ? value : "Sin email";
    };
    const formatRating = (rating) => {
        return rating !== null ? rating : "0";
    };
    const formatName = (firstName, lastName) => {
        if (firstName && lastName !== undefined && firstName && lastName !== null) {
            const fullName = `${firstName} ${lastName}`;
            return fullName.toUpperCase();
        } else if (firstName) {
            return firstName.toUpperCase();
        } else if (lastName) {
            return lastName.toUpperCase();
        } else {
            return "Sin nombre";
        }
    };

    const columns = [
        {
            name: 'Id',
            selector: 'user_id',
            sortable: true,
            wrap: true,
            width: '140px',
            cell: row => (
                <strong className='font-custom overflow-hidden truncate ...' style={{ display: 'block', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                    {row.user_id}
                </strong>
            ),
        },
        {
            name: 'Nombre',
            selector: row => formatName(row.name, row.last_name),
            wrap: true,
            width: '310px',
            sortable: true,
            cell: row => (
                <div className='font-custom overflow-hidden' style={{ display: 'block', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                    {row.name ? row.name.toUpperCase() : 'Sin nombre'}  {row.last_name && row.last_name !== 'undefined' ? row.last_name.toUpperCase() : ''}
                </div>
            ),
        },
        {
            name: 'Email',
            selector: row => formatValue(row.email),
            sortable: true,
            width: '220px',
        },
        {
            name: 'Registro',
            selector: row => formatDate(row.register_date),
            width: '160px',
        },
        {
            name: 'Última Conexión',
            selector: row => formatDate(row.last_log_date),
            center: true,
            width: '230px',
        },
        {
            name: 'Calificación',
            selector: row => formatRating(row.rating),
            sortable: true,
            width: '175px',
            center: true,
            cell: row =>
                <div className='font-custom' style={{ display: 'block', fontSize: '1rem' }}>{row.rating || '0'}</div>,
        },
        {
            name: 'Opciones',
            selector: 'options',
            width: '130px',
            center: true,
            cell: row => (
                <div className="flex items-center">
                    <DetallesPasajeros rowData={row} />
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    const columnConfig = [
        { label: 'ID', key: 'user_id' },
        { label: 'Nombre', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Fecha de registro', key: 'register_date' },
        { label: 'Ultima conexion', key: 'last_log_date' },
        { label: 'Calificacion', key: 'rating' },
    ];

    const exportToExcelLocal = () => {
        exportToExcel(filteredPassengersDate, columnConfig);
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
    };

    const resetFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredPassengersDate(pasajeros);
    };

    return (
        <div className='ml-30 mr-30 mt-10 mb-4'>
            <div className="flex content-between mb-4 justify-between">
                <SearchBar onSearch={handleSearch} />
                <div className="z-0 flex">
                    <DatePicker
                        label="Fecha de inicio"
                        sx={{
                            marginRight: "1rem",
                            width: "12rem",
                            borderColor: "#07f0ad",
                            color: "#07f0ad"
                        }}
                        format="DD-MM-YYYY"
                        value={startDate || ''}
                        onChange={(date) => setStartDate(date)}
                    />
                    <DatePicker
                        label="Fecha de fin"
                        format="DD-MM-YYYY"
                        value={endDate || ''}
                        onChange={(date) => setEndDate(date)}
                        sx={{
                            width: "12rem",
                        }}
                    />
                    <div className="flex items-center ml-2">
                        <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl" onClick={() => startDate && endDate ? applyDateRangeFilter() : (filteredPassengersDate)}>
                            <FilterAltIcon sx={{ color: 'white' }} />
                        </button>
                        <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl ml-1" onClick={resetFilter}>
                            <FilterAltOffIcon sx={{ color: 'white' }} />
                        </button>
                    </div>
                </div>
                <button
                    className='bg-green-600 p-2 text-white font-bold mb-2 flex items-center space-x-2 hover:bg-green-900'
                    onClick={exportToExcelLocal}
                >
                    <ListAltIcon className='text-2xl mr-1' />
                    Descargar Excel
                </button>
            </div>
            <DataTable
                columns={columns}
                data={filteredPassengersDate}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 500]}
                paginationComponentOptions={paginationComponentOptions}
                highlightOnHover
                customStyles={{
                    rows: {
                        style: {
                            borderTop: '1px solid #CBD5E0',
                        },
                    },
                    headCells: {
                        style: {
                            fontSize: '1.15rem',
                            textAlign: 'center',
                        },
                    },
                    cells: {
                        style: {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            fontSize: '1rem',
                        },
                    },
                    pagination: {
                        style: {
                            fontSize: '1rem',
                            color: 'black',
                        },
                    },
                    table: {
                        style: {
                            height: '70vh',
                            header: 'sticky',
                            overflowX: "hidden"
                        },
                    }
                }}
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={({ data }) => <ExpandableComponent userId={data.user_id} />}
            />
        </div>
    );
}

export default TablaPasajeros;