import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import BotonDetalles from './BotonDetalles';
import BotonHistorial from './BotonHistorial';
import SearchBar from '../SearchBar';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { exportToExcel } from './ExcelExporter';
import { DatePicker } from '@mui/x-date-pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import BlockIcon from '@mui/icons-material/Block';
import '../../../styles/generales.css';

const DriversTable = () => {
	const [drivers, setDrivers] = useState([]);
	const [showIcons, setShowIcons] = useState(window.innerWidth <= 1579);
	const [filteredDrivers, setFilteredDrivers] = useState(drivers);
	const [filteredDriversDate, setFilteredDriversDate] = useState(drivers);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [zonasunicas, setZonasUnicas] = useState([]);
	const [selectedZona, setSelectedZona] = useState('');
	const [localidadUnica, setLocalidadUnica] = useState([]);
	const [selectedLocalidad, setSelectedLocalidad] = useState('');
	const [zonaFiltered, setZonaFiltered] = useState([]);

	const applyDateRangeFilter = () => {
		const startRange = startDate ? new Date(startDate).getTime() : null;
		let endRange = endDate ? new Date(endDate) : null;

		if (endRange) {
			endRange.setHours(23);
			endRange.setMinutes(59);
			endRange.setSeconds(59);
		}

		endRange = endRange ? endRange.getTime() : null;

		const filteredDriversDate = drivers
			.filter(driver => {
				const driverDate = new Date(driver.date_registration).getTime();
				return (
					(!startRange || driverDate >= startRange) &&
					(!endRange || driverDate <= endRange)
				);
			})
			.sort((a, b) => {
				return (
					new Date(b.date_registration).getTime() -
					new Date(a.date_registration).getTime()
				);
			});

		setFilteredDriversDate(filteredDriversDate);
	};

	const handleSearch = searchTerm => {
		const cleanedSearchTerm = searchTerm.trim().toLowerCase();

		if (!cleanedSearchTerm && !startDate && !endDate) {
			setFilteredDriversDate(filteredDrivers);
			return;
		}

		const removeAccents = (str) => {
			return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		};

		const filtered = drivers.filter(driver => {
			const fullName = removeAccents(`${driver.name} ${driver.last_name}`).toLowerCase();
			const id = driver.driver_id.toLowerCase();
			const matricula =
				driver.driver_vehicle?.vehicle_plates.toLowerCase() || '';
			const fecha =
				new Date(driver.date_registration)
					.toLocaleDateString()
					.toLowerCase() || '';
			const estado = removeAccents(driver.address_state?.toLowerCase() || '');
			const localidad = removeAccents(driver.address_locality?.toLowerCase() || '');
			const vehicleTypeName = driver.vehicle_id_type?.name.toLowerCase() || '';
			const matchesSearchTerm =
				fullName.includes(cleanedSearchTerm) ||
				id.includes(cleanedSearchTerm) ||
				matricula.includes(cleanedSearchTerm) ||
				fecha.includes(cleanedSearchTerm) ||
				estado.includes(cleanedSearchTerm) ||
				localidad.includes(cleanedSearchTerm) ||
				vehicleTypeName.includes(cleanedSearchTerm);
			const isInDateRange =
				(!startDate ||
					new Date(driver.date_approved).getTime() >=
					new Date(startDate).getTime()) &&
				(!endDate ||
					new Date(driver.date_approved).getTime() <=
					new Date(endDate).getTime());

			return matchesSearchTerm && isInDateRange;
		});

		setFilteredDriversDate(filtered);
	};

	useEffect(() => {
		setFilteredDrivers(drivers);
		const zonasSet = new Set(
			drivers.map(conductor => conductor.address_state)
		);
		const zonasArray = Array.from(zonasSet);
		const zonasFiltradas = zonasArray.filter(zona => zona !== null);
		setZonasUnicas(zonasFiltradas);
	}, [drivers]);

	const sortDriversByStartDate = drivers => {
		return drivers.sort((a, b) => {
			const dateA = a.date_registration
				? new Date(a.date_registration)
				: null;
			const dateB = b.date_registration
				? new Date(b.date_registration)
				: null;

			if (dateA && dateB) {
				return dateB - dateA;
			} else if (dateA) {
				return -1;
			} else if (dateB) {
				return 1;
			} else {
				return 0;
			}
		});
	};

	useEffect(() => {
		const fetchConductoresData = async () => {
			try {
				const response = await axios.get(
					process.env.REACT_APP_API_DRIVERS,
					{
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
						},
					}
				);

				const sortedDrivers = sortDriversByStartDate(
					response.data.drivers
				);
				setDrivers(sortedDrivers);
				setFilteredDriversDate(sortedDrivers);
			} catch (error) {
				console.error('Error fetching driver data:', error);
			}
		};

		const handleResize = () => {
			setShowIcons(window.innerWidth <= 1579);
		};
		window.addEventListener('resize', handleResize);
		fetchConductoresData();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getDocumentsIcon = documents => {
		if (showIcons) {
			return documents ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />;
		} else {
			return documents ? 'Si' : 'No';
		}
	};

	const getStatusClass = status => {
		if (status === 'Activo') {
			return 'bg-greenE text-white';
		} else {
			return 'bg-yellowE text-black';
		}
	};

	function formatDate(date) {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	}

	const columns = [
		{
			name: 'ID',
			selector: row => row.driver_id,
			sortable: true,
			width: '160px',
			cell: row => (
				<strong className='font-custom overflow-hidden w-28 truncate ... '>
					{row.driver_id}
				</strong>
			),
		},
		{
			name: 'Nombre',
			selector: row => `${row.name} ${row.last_name}`,
			sortable: true,
			width: '350px',
			cell: row => (
				<div className='font-custom text-left overflow-hidden truncate ...'>
					{row?.name?.includes(row?.last_name)
						? row.name
						: (
							row.name +
							' ' +
							(row?.last_name != null ? row.last_name : '')
						).toUpperCase()}
				</div>
			),
		},
		{
			name: 'Vehiculo',
			selector: row => {
				if (row.vehicle_id_type) {
					return row.vehicle_id_type.name;
				} else {
					return 'No asignado';
				}
			},
			key: 'vehicle_id_type',
			label: 'Vehiculo',
			sortable: true,
			width: '150px',
			cell: row => (
				<div className='font-custom text-left overflow-hidden truncate ...'>
					{row.vehicle_id_type
						? row.vehicle_id_type.name
						: 'No asignado'}
				</div>
			),
		},
		{
			name: 'Estado',
			selector: row => row.address_state || 'Sin estado',
			sortable: true,
			center: true,
			cell: row => (
				<div className='font-custom truncate ... '>
					{row.address_state?.toUpperCase() || 'Sin estado'}
				</div>
			),
			width: '145px',
		},
		{
			name: 'Localidad',
			selector: row => row.address_locality || 'Sin localidad',
			sortable: true,
			center: true,
			cell: row => (
				<div className='font-custom truncate ... '>
					{row.address_locality?.toUpperCase() || 'Sin localidad'}
				</div>
			),
			width: '155px',
		},
		{
			name: 'Matrícula',
			selector: row =>
				row.driver_vehicle?.vehicle_plates || 'Sin matrícula',
			sortable: true,
			center: true,
			cell: row => (
				<div className='font-custom'>
					{row.driver_vehicle?.vehicle_plates.toUpperCase() ||
						'Sin matrícula'}
				</div>
			),
			width: '155px',
		},
		// date_registration
		{
			name: 'Registro',
			center: true,
			selector: row => {
				const date = row.date_registration
					? new Date(row.date_registration)
					: null;
				return date ? formatDate(date) : 'Sin registro';
			},
			sortable: true,
			width: '145px',
		},
		{
			name: 'Inicio de actividad',
			center: true,
			selector: row => {
				const date = row.date_approved
					? new Date(row.date_approved)
					: null;
				return date ? formatDate(date) : 'Sin registro';
			},
			sortable: true,
			width: '245px',
		},
		{
			name: 'Estatus',
			selector: row => row.status || 'Pendiente',
			sortable: true,
			cell: row => (
				<span
					className={`status-badge w-box py-1 font-custom text-md text-center rounded-full ${getStatusClass(
						row.status
					)}`}
				>
					{showIcons ? (
						<div className='flex items-center justify-center'>
							{row.status === 'Activo' ? (
								<CheckOutlinedIcon className='text-2xl' />
							) : row.status === 'Pendiente' ? (
								<div className='text-2xl font-bold'>-</div>
							) : (
								<CloseOutlinedIcon className='text-2xl' />
							)}
						</div>
					) : (
						row.status || 'Pendiente'
					)}
				</span>
			),
			width: '150px',
		},

		{
			name: 'Documentos',
			selector: row => row.docVerification,
			sortable: true,
			center: true,
			cell: row => (
				<span
					className={`documents-badge py-1 w-box text-center rounded-full text-md font-custom ${row.docVerification === true
						? 'bg-greenE text-white'
						: 'bg-redD text-white'
						}`}
				>
					<div className='flex items-center justify-center'>
						{getDocumentsIcon(row.docVerification)}
					</div>
				</span>
			),
			width: '190px',
		},
		{
			name: 'Opciones',
			selector: row => row.options,
			right: 'true',
			cell: row => (
				<div className='flex items-center'>
					<BotonDetalles rowData={row} vehicleId={row.vehicle_id} />
					<BotonHistorial rowData={row} />
				</div>
			),
			width: '130px',
		},
	];

	const paginationComponentOptions = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
	};

	const columnConfig = [
		{ label: 'ID', key: 'driver_id' },
		{ label: 'Estado', key: 'address_state' },
		{ label: 'Localidad', key: 'address_locality' },
		{ label: 'Nombre', key: 'name' },
		{ label: 'Vehiculo', key: 'vehicle_id_type' },
		{ label: 'Celular', key: 'phone' },
		{
			label: 'Matricula',
			key: 'driver_vehicle.vehicle_plates',
			transform: value =>
				value && value.vehicle_plates ? value.vehicle_plates : '',
		},

		{ label: 'Fecha de registro', key: 'date_registration' },
		{ label: 'Estatus', key: 'status' },
		{ label: 'Documentos', key: 'docVerification' },
	];

	const exportToExcelLocal = () => {
		exportToExcel(filteredDriversDate, columnConfig);
	};

	const resetFilter = () => {
		setStartDate(null);
		setEndDate(null);
		setFilteredDriversDate(drivers);
	};

	useEffect(() => {
		document.title = `S. Monitoreo - Conductores`;
	}, []);

	const filtarZona = e => {
		const zonaFiltered = drivers.filter(zona => e === zona.address_state);
		setFilteredDriversDate(zonaFiltered);
		setZonaFiltered(zonaFiltered);
		const localidadesSet = new Set(
			zonaFiltered.map(localidad => localidad.address_locality)
		);
		const localidadArray = Array.from(localidadesSet);
		setLocalidadUnica(localidadArray);
		setSelectedLocalidad('');
	};

	const filtrarLocalidad = e => {
		const localidadFiltered = zonaFiltered.filter(
			zona => e === zona.address_locality
		);
		setFilteredDriversDate(localidadFiltered);
	};

	const cancelarFiltros = () => {
		setFilteredDriversDate(drivers);
		setSelectedZona('');
		setSelectedLocalidad('');
	};

	return (
		<div className='mb-4'>
			<div className='flex content-between justify-between mb-2'>
				<SearchBar onSearch={handleSearch} />
				<div className='z-0 flex'>
					<DatePicker
						label='Fecha de inicio'
						sx={{
							marginRight: '1rem',
							width: '12rem',
							borderColor: '#07f0ad',
							color: '#07f0ad',
						}}
						format='DD-MM-YYYY'
						value={startDate || ''}
						onChange={date => setStartDate(date)}
					/>
					<DatePicker
						label='Fecha de fin'
						format='DD-MM-YYYY'
						value={endDate || ''}
						onChange={date => setEndDate(date)}
						sx={{
							width: '12rem',
						}}
					/>
					<div className='flex items-center ml-2'>
						<button
							className='bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl'
							onClick={() =>
								startDate && endDate
									? applyDateRangeFilter()
									: setFilteredDrivers(filteredDriversDate)
							}
						>
							<FilterAltIcon sx={{ color: 'white' }} />
						</button>
						<button
							className='bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl ml-1'
							onClick={resetFilter}
						>
							<FilterAltOffIcon sx={{ color: 'white' }} />
						</button>
					</div>
				</div>
				<button
					className='bg-green-600 p-2 text-white font-bold mb-2 flex items-center space-x-2 hover:bg-green-900'
					onClick={exportToExcelLocal}
				>
					<ListAltIcon className='text-2xl mr-1' />
					Descargar Excel
				</button>
			</div>
			<div className='div-container-select-tablaConductores'>
				<select
					value={selectedZona}
					onChange={e => {
						setSelectedZona(e.target.value);
						filtarZona(e.target.value);
					}}
				>
					<option value=''>Selecciona la Zona</option>
					{zonasunicas.map((zona, index) => (
						<option value={zona} key={index}>
							{zona}
						</option>
					))}
				</select>
				{selectedZona && (
					<>
						<select
							value={selectedLocalidad}
							onChange={e => {
								setSelectedLocalidad(e.target.value);
								filtrarLocalidad(e.target.value);
							}}
						>
							<option value=''>Selecione Localidad</option>
							{localidadUnica.map((localidad, index) => (
								<option value={localidad} key={index}>
									{localidad}
								</option>
							))}
						</select>
						<div
							className='div-borrar-filtros'
							onClick={cancelarFiltros}
						>
							<BlockIcon className='borrar-filtros-icon' />
							<span>Cancelar</span>
						</div>
					</>
				)}
			</div>
			<div className='overflow-x-auto'>
				<DataTable
					columns={columns}
					data={filteredDriversDate}
					pagination
					paginationPerPage={10}
					paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 500]}
					paginationComponentOptions={paginationComponentOptions}
					highlightOnHover
					responsive={true}
					customStyles={{
						rows: {
							style: {
								borderTop: '1px solid #CBD5E0',
							},
						},
						selected: {
							style: {
								color: '#008000',
							},
						},
						headCells: {
							style: {
								fontSize: '1.25rem',
								textAlign: 'center',
							},
						},
						pagination: {
							style: {
								fontSize: '1rem',
								color: 'black',
							},
						},
						cells: {
							style: {
								marginTop: '1rem',
								width: '10vw',
								marginBottom: '1rem',
								textAlign: 'center',
								fontSize: '1rem',
							},
						},
						table: {
							style: {
								height: '77vh',
								header: 'sticky',
								overflowX: 'hidden',
							},
						},
					}}
				/>
			</div>
		</div>
	);
};

export default React.memo(DriversTable);
