import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Loader from './Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../styles/documentForm.css';

const DocumentForm = ({ setModal, vehiculeData }) => {
	const vehiculeID = vehiculeData.vehiculo_id;
	const [documentos, setDocumentos] = useState([]);
	const [documentVehicle, setDocumentVehicule] = useState([]);
	const [update, setUpdate] = useState(false);
	const [loading, setLoading] = useState(false);

	const notifyRegister = () => toast.success('Registro creado exitosamente');
	const notifyDelete = () => toast.success('Registro eliminado exitosamente');

	const documents = () => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
       query GetDucuments {
  		ducuments {
    		obligatory
			document_id
			icon
			name
			type
			Registration
		  }
		}
      `,
			}),
		})
			.then(res => res.json())
			.then(res => setDocumentos(res.data.ducuments));
	};

	const documentsByVehicule = () => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
      		query  GetDocumentByVehicle ($vehiculeId: String)
	  		{
  				document_by_vehicle(where: {vehicle_id: {_eq: $vehiculeId }})
				{
    				document_id
    				id
    				type
    				vehicle_id
  				}
			}
      `,
				variables: {
					vehiculeId: vehiculeID,
				},
			}),
		})
			.then(res => res.json())
			.then(res => setDocumentVehicule(res.data.document_by_vehicle));
	};

	const registerDocument = documento => {
		setLoading(true);
		const documentID = documento.document_id + vehiculeID;

		const documentBase = btoa(documentID);

		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
      		mutation registrarDocmumento
			 ($document_id: String, $id: String, $type: String, $vehicule:String) {
  				insert_document_by_vehicle(objects:
					{document_id: $document_id, id:  $id, type:$type, vehicle_id:$vehicule})
					 { returning {
      					document_id
      					id
      					type
      					vehicle_id
   					 }
  					}
				}
      `,
				variables: {
					id: documentBase,
					document_id: documento.document_id,
					type: documento.type,
					vehicule: vehiculeID,
				},
			}),
		})
			.then(res => {
				res.json();
				setUpdate(!update);
				setTimeout(() => {
					setLoading(false);
					notifyRegister();
				}, 2000);
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
			});
	};

	const deleteRegister = documento => {
		setLoading(true);
		const foundDocument = documentVehicle.find(
			doc => doc.document_id === documento.document_id
		);

		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
      				mutation delete ($id : String) {
  						delete_document_by_vehicle(where: {id: {_eq: $id}}) {
    					returning {
      						id
      						document_id
    					}
  					}
				}
      `,
				variables: {
					id: foundDocument.id,
				},
			}),
		})
			.then(res => {
				res.json();
				setUpdate(!update);
				setTimeout(() => {
					setLoading(false);
					notifyDelete();
				}, 2000);
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		const fetchData = async () => {
			documents();
			documentsByVehicule();
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [update]);

	const isDocumentChecked = documento => {
		const foundDocument = documentVehicle.find(
			doc => doc.document_id === documento.document_id
		);

		return foundDocument !== undefined;
	};

	const handleCheckboxChange = documento => {
		const checked = !isDocumentChecked(documento);

		if (checked === false) {
			deleteRegister(documento);
			return checked;
		} else {
			registerDocument(documento);
			return checked;
		}
	};

	const documentosOrdenados = documentos?.sort((a, b) => {
		const nombreA = a.name.toUpperCase();
		const nombreB = b.name.toUpperCase();

		if (nombreA < nombreB) {
			return -1;
		}
		if (nombreA > nombreB) {
			return 1;
		}
		return 0;
	});

	return (
		<>
			{loading && <Loader />}
			<div className='div-container-documentForm'>
				<section className='section-header-documentFrom'>
					<div>
						<button
							onClick={() => {
								setModal(false);
							}}
						>
							<ArrowBackIcon /> <span> Regresar </span>
						</button>
					</div>
					<div>
						<p className='documentFrom-div-p-title'>Vehiculo Id:</p>
						<p className='documentFrom-div-p-text'>
							{vehiculeData.vehiculo_id}
						</p>
					</div>
					<div>
						<p className='documentFrom-div-p-title'>Nombre:</p>
						<p className='documentFrom-div-p-text'>
							{vehiculeData.name}
						</p>
					</div>
					<div>
						<p className='documentFrom-div-p-title'>Tipo:</p>
						<p className='documentFrom-div-p-text'>
							{vehiculeData.type}
						</p>
					</div>
					<div>
						<img src={vehiculeData.image} alt='' />
					</div>
				</section>
				<div className='div-title-documentForm'>
					<h1>Lista de Documentos</h1>
				</div>
				<section className='section-body-documentFrom'>
					<table className='Tabla-body-documentFrom'>
						<thead>
							<tr>
								<th>Icono</th>
								<th>Nombre</th>
								<th>Tipo</th>
								<th>Obligatorio</th>
								<th>Registrar</th>
							</tr>
						</thead>
						<tbody>
							{documentosOrdenados.map((documento, index) => (
								<tr key={index}>
									<td>
										<img src={documento?.icon} alt='icon' />
									</td>
									<td>{documento?.name}</td>
									<td>{documento?.type}</td>
									<td>
										{documento?.obligatory
											? 'true'
											: 'false'}
									</td>
									<td>
										<input
											type='checkbox'
											checked={isDocumentChecked(
												documento
											)}
											onChange={() =>
												handleCheckboxChange(documento)
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
				<Toaster
					position='bottom-center'
					reverseOrder={false}
					toastOptions={{
						duration: 5000,
						style: {
							background: '#363636',
							color: '#fff',
						},
					}}
				/>
			</div>
		</>
	);
};

export default DocumentForm;
