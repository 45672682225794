import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import { mapStyle } from '../Sidebar/Mapa/Estilo';
import RealTimeDriverMarkers from '../Sidebar/Mapa/RealTimeDriverMarkers';
import DriverTable from '../Sidebar/Mapa/DriverTable';
import DriversByState from '../Sidebar/Mapa/DriversByState';
import { ref, onValue } from 'firebase/database';
import { db } from '../../firebase/firebase';

const defaultCenter = {
	lat: 19.0589756,
	lng: -98.1811881,
};
const arrayL = ['geometry', 'drawing', 'places'];

const Mapa = () => {
	const [ubicacion, setUbicacion] = useState([]);
	const [showTerminados, setShowTerminados] = useState(false);
	const [showCancelados, setShowCancelados] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [driverData, setDriverData] = useState([]);
	const [showDriverTable, setShowDriverTable] = useState(true);
	const defaultZoom = 10;

	const [zoomLevel, setZoomLevel] = useState(defaultZoom);

	const mapStyles = {
		height: '100vh',
		width: '100%',
	};

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS,
		libraries: arrayL,
	});

	const defaultZoom2 = 18;

	const handleSelectedLocationChange = location => {
		setSelectedLocation(location);
		setZoomLevel(defaultZoom2);
		setTimeout(() => {
			setZoomLevel(prevZoomLevel => prevZoomLevel + 1);
		}, 1);
	};

	const handleCenter = location => {
		setSelectedLocation(location);
		setZoomLevel(defaultZoom);
		setTimeout(() => {
			setZoomLevel(prevZoomLevel => prevZoomLevel + 1);
		}, 1);
	};

	useEffect(() => {
		const fetchPasajerosData = async () => {
			try {
				const response = await axios.get(
					process.env.REACT_APP_API_RIDES,
					{
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
						},
					}
				);
				setUbicacion(response.data.trips);
				const updatedUbicacion = response.data.trips.map(trip => {
					if (trip.status === 'Terminado' && !trip.driver_data) {
						return {
							...trip,
							status: 'Cancelado',
						};
					}
					return trip;
				});
				setUbicacion(updatedUbicacion);
			} catch (error) {
				console.error('Error fetching passengers data:', error);
			}
		};

		fetchPasajerosData();
	}, []);

	useEffect(() => {
		const query = ref(
			db,
			'/projects/proj_iDtR68Uec2W1ohmGzgtMRo/geoFireGroups/Conductores'
		);
		onValue(query, snapshot => {
			let arr = [];
			const data = snapshot.val();
			// eslint-disable-next-line array-callback-return
			Object.keys(data).map(key => {
				arr.push({ id: key, driverData: data[key] });
			});
			setDriverData(arr);
		});
	}, []);

	const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS;

	const getMarkerIcon = status => {
		if (!window.google || !window.google.maps) {
			return null;
		}
		const color = status === 'Terminado' ? 'green' : 'red';
		return {
			path: window.google.maps.SymbolPath.CIRCLE,
			fillColor: color,
			fillOpacity: 1,
			strokeColor: 'white',
			strokeWeight: 1,
			scale: 8,
		};
	};

	return (
		<div>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={mapStyles}
					zoom={zoomLevel}
					center={selectedLocation || defaultCenter}
					options={{ styles: mapStyle.default }}
				>
					<div className='absolute ml-2 2xs:mt-20 md:mt-2 md:left-[20rem] mt-2'>
						<button
							className='bg-white border-black p-2 border-2 hover:bg-gray-300'
							onClick={() => setShowTerminados(!showTerminados)}
						>
							Terminados
						</button>
						<button
							className='bg-white border-black p-2 border-2 hover:bg-gray-300 ml-2'
							onClick={() => setShowCancelados(!showCancelados)}
						>
							Cancelados
						</button>
						<button
							className='bg-white border-black p-2 border-2 hover:bg-gray-300 ml-2'
							onClick={() => handleCenter(defaultCenter)}
						>
							Centrar Mapa
						</button>
						<button
							className='bg-white border-black p-2 border-2 hover:bg-gray-300 ml-2'
							onClick={() => setShowDriverTable(!showDriverTable)}
						>
							Cambiar Tabla
						</button>
					</div>
					{ubicacion.map(trip =>
						trip.locations.map((location, index) => {
							const status = trip.status;
							if (
								(status === 'Terminado' && showTerminados) ||
								(status === 'Cancelado' && showCancelados)
							) {
								return (
									<Marker
										key={index}
										position={{
											lat: location.latitude,
											lng: location.longitude,
										}}
										icon={getMarkerIcon(status)}
									/>
								);
							}
							return null;
						})
					)}

					{driverData.length > 0 && (
						<>
							{showDriverTable ? (
								<DriverTable
									onLocationSelect={handleSelectedLocationChange}
									drivers={driverData}
								/>
							) : (
								<DriversByState />
							)}
							<RealTimeDriverMarkers
								apiKey={googleMapsApiKey}
								drivers={driverData}
							/>
						</>
					)}
				</GoogleMap>
			)}
		</div>
	);
};

export default Mapa;
