import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import DetallesFinalizados from './DetallesFinalizados';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchBar from '../SearchBar';
import { exportToExcel } from './ExcelExporter';
import { DatePicker } from '@mui/x-date-pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import NotificacionConductores from './NotificacionConductores';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../../assets/img/Loading.json';
const ViajesFinalizados = () => {
    const [trips, setTrips] = useState([]);
    const [showIcons, setShowIcons] = useState(window.innerWidth <= 1450);
    const [filteredTripsDate, setFilteredTripsDate] = useState(trips);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const applyDateRangeFilter = () => {
        const startRange = startDate ? new Date(startDate).getTime() : null;
        let endRange = endDate ? new Date(endDate) : null;

        if (endRange) {
            endRange.setHours(23, 59, 59, 999);
        }

        endRange = endRange ? endRange.getTime() : null;

        const filteredTripsDate = trips.filter(trip => {
            const tripDate = new Date(trip.time_request).getTime();
            return (!startRange || tripDate >= startRange) && (!endRange || tripDate <= endRange);
        });

        setFilteredTripsDate(filteredTripsDate);
    };


    const handleSearch = (searchTerm) => {
        if (!searchTerm.trim()) {
            setFilteredTripsDate(trips);
        } else {
            const cleanedSearchTerm = searchTerm.trim().toLowerCase();

            const filtered = trips.filter((trip) => {
                const conductor = trip.driver_data ? `${trip.driver_data.name?.trim()} ${trip.driver_data.last_name?.trim()}` : 'Sin nombre';
                const ciudad = trip.zona_data ? `${trip.zona_data.nombre?.trim()}` : 'Sin nombre';
                const origen = trip.locations && trip.locations[0] ? trip.locations[0].description?.trim() || 'N/A' : 'N/A';
                const destino = trip.locations && trip.locations[1] ? trip.locations[1].description?.trim() || 'N/A' : 'N/A';
                const usuario = (trip.user_data && trip.user_data.name !== 'null' && trip.user_data.last_name !== 'null') ?
                    `${trip.user_data.name?.trim()} ${trip.user_data.last_name?.trim()}` : 'Sin nombre';
                const fecha = trip.time_request ? formatDate(trip.time_request) : 'N/A';
                const tripId = trip.trip_id.toLowerCase();
                const tipoViaje = getNameFromId(trip.vehicle_id);
                const matchesSearchTerm = (
                    conductor.toLowerCase().includes(cleanedSearchTerm) ||
                    origen.toLowerCase().includes(cleanedSearchTerm) ||
                    destino.toLowerCase().includes(cleanedSearchTerm) ||
                    usuario.toLowerCase().includes(cleanedSearchTerm) ||
                    ciudad.toLowerCase().includes(cleanedSearchTerm) ||
                    fecha.includes(cleanedSearchTerm) ||
                    tripId.includes(cleanedSearchTerm) ||
                    tipoViaje.toLowerCase().includes(cleanedSearchTerm)
                );
                const isInDateRange = (
                    (!startDate || new Date(trip.time_request).getTime() >= new Date(startDate).getTime()) &&
                    (!endDate || new Date(trip.time_request).getTime() <= new Date(endDate).getTime())
                );

                return matchesSearchTerm && isInDateRange;
            });

            setFilteredTripsDate(filtered);
        }
    };

    useEffect(() => {
        setFilteredTripsDate(trips);
    }, [trips]);

    useEffect(() => {
        const fetchViajesData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    process.env.REACT_APP_API_VIAJES,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );

                // Filtrar solo los viajes con environment 2
                const filteredTrips = response.data.trips.filter(trip => trip.environment === "2");

                // Filtrar los viajes que no tengan los idDriver especificados
                const excludedDriverIds = [
                    "oLPjQdQLIPba046rYrbnbD1Dhvc2",
                    "WKQ4T5IaRoX37a3RNtQXjzJJRwT2",
                    "KoIFKGeGykWIhAlJ8qJQz05eAR53",
                    "A0U99TjgnIdE1KRKyHeOGUPMgaW2",
                    "WQfQGwr1W5SHA4MsJ0wvUEqDdM23",
                    "nnwuMZZfL0NxqcqHNYrP6mQyqaU2",
                    "yWI9ZsZIpdROvwoeCfCELwa6eWC3",
                    "b9Z1DZ1XxDWYyNSpGWlYqnV8MrC3",
                    "a8BuCU0MkldJjdREKFRuP7zD6Ne2",
                    "fELeNzjUaNNxzVzkAkHm52ReK493"];
                const filteredTripsWithoutExcludedDrivers = filteredTrips.filter(trip => !excludedDriverIds.includes(trip.driver_data?.idDriver));

                const excludedUserIds = ["YLa87HejoDQuX0O8GNQp5KEiuYz1", "OP1zUe4LN3Ypm0zAFl8G9QdQmHl2", "ICJlc2IgYrfeSnXT3t50afCvuSP2", "cXnMSOKknOXhJDRZ4cGtZpxIyR62", "slZMFQH3jLQKo4Txu5b6kLcLOMk2", "kPFomJKL3YOJCVwCojdm9hlgphm1", "whhoNRJAGmfOlCj3HsFnRHV8fgS2"];
                const filteredTripsWithoutExcludedUsers = filteredTripsWithoutExcludedDrivers.filter(trip => !excludedUserIds.includes(trip.user_data?.idUser));

                // Actualizar el estado solo con los viajes filtrados
                const updatedTrips = filteredTripsWithoutExcludedUsers.map((trip) => {
                    if (trip.status === "Terminado" && !trip.driver_data) {
                        return {
                            ...trip,
                            status: "Cancelado",
                        };
                    }
                    return trip;
                });

                // Función para ordenar los viajes por fecha
                const sortTripsByDate = (arr) => arr.sort((a, b) => new Date(b.time_request) - new Date(a.time_request));

                // Ordenar y establecer los viajes en el estado
                setTrips(sortTripsByDate(updatedTrips));
            } catch (error) {
                console.error("Error fetching trips data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchViajesData();
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setShowIcons(window.innerWidth <= 1450);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hour = ('0' + date.getHours()).slice(-2);
        const minute = ('0' + date.getMinutes()).slice(-2);
        const second = ('0' + date.getSeconds()).slice(-2);

        return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'Terminado':
                return 'bg-greenE text-white text-sm ';
            case 'Cancelado':
                return 'bg-redD text-white text-sm '
            case 'Aceptado':
                return 'bg-yellowE text-black text-sm ';
            default:
                return 'bg-slate-500 text-white text-sm ';
        }
    };

    const getStatusContent = (row) => {
        if (row.status === null) {
            return 'Desconocido';
        }
        if (showIcons) {
            return row.status === "Terminado" ? (
                <CheckOutlinedIcon className="text-2xl" />
            ) : (
                <CloseOutlinedIcon className="text-2xl" />
            );
        } else {
            return row.status;
        }
    };

    const idToNameMap = {
        "91111b73-2439-4d26-b3c1-c462c5b0f4e0": "Comfort",
        "09436de0-4987-11ed-8a29-75bea275e626": "Nikucar",
        "82f8f8b0-f5cb-4234-89e1-167c1f0822a6": "Security",
        "08f97404-ba81-4a3f-b4aa-f5ac05fa2c85": "Lady",
        "094363hl-s11ed-8a29-75bea275esad2w": "Taxi"
    };

    function getNameFromId(id) {
        return idToNameMap[id] || "Nombre no encontrado";
    }

    const columns = [
        {
            name: 'Viaje ID',
            selector: 'trip_id',
            sortable: true,
            width: '135px',
            cell: row =>
                <strong className='font-custom truncate ...' style={{ display: 'block', fontSize: '1rem' }}>
                    {row.trip_id}</strong>,
        },
        {
            name: 'Conductor',
            width: '190px',
            selector: row => {
                const conductorName = row.driver_data ?
                    `${row.driver_data.name?.toUpperCase()} ${row.driver_data.last_name?.toUpperCase() !== 'UNDEFINED' ? row.driver_data.last_name?.toUpperCase()
                        : ''}`
                    : 'Sin Asignar';
                return conductorName;
            },
            sortable: true,
        },
        {
            name: 'Ciudad',
            selector: row => {
                const userName = row.zona_data ? `${row.zona_data.nombre?.toUpperCase()} ` : 'Desconocido';
                return userName;
            },
            width: '180px',
            sortable: true,
        },
        {
            name: 'Origen',
            width: '200px',
            selector: row => {
                const origen = (row.locations && row.locations[0]) ? row.locations[0].description || 'N/A' : 'Sin origen';
                return origen;
            },
            sortable: true,
        },
        {
            name: 'Destino',
            selector: row => {
                const destino = (row.locations && row.locations[1]) ?
                    (row.locations[1].description && row.locations[1].description !== 'undefined') ? row.locations[1].description : 'Sin destino'
                    : 'Sin destino';
                return destino;
            },
            width: '200px',
            sortable: true,
        },

        {
            name: 'Usuario',
            selector: row => {
                const userName = row.user_data ? `${row.user_data.name?.toUpperCase()} ${row.user_data.last_name?.toUpperCase()}` : 'Desconocido';
                return userName;
            },
            width: '180px',
            sortable: true,
        },
        {
            name: 'Solicitó',
            selector: 'vehicle_id',
            sortable: true,
            width: '135px',
            center: true,
            cell: row => (
                <strong className='font-custom truncate ...' style={{ display: 'block', fontSize: '1rem' }}>
                    {getNameFromId(row.vehicle_id)}
                </strong>
            ),
        },
        {
            name: 'Fecha',
            selector: row => formatDate(row.time_request) || 'Sin registro',
            sortable: true,
            center: true,
            width: '210px',
        },
        {
            name: 'Estatus',
            selector: 'status',
            center: true,
            sortable: true,
            width: '150px',
            cell: row => (
                <span className={`px-3 py-1 text-lg text-center rounded-full truncate ... ${getStatusClass(row.status)}`}>
                    {getStatusContent(row)}
                </span>
            ),
        },
        {
            name: 'Detalles',
            middle: true,
            selector: 'options',
            width: '140px',
            cell: row => (
                <div className="flex items-center">
                    <DetallesFinalizados rowData={row} />
                    <NotificacionConductores rowData={row} />
                </div>
            ),
            ignoreRowClick: true,
        },

    ];

    const columnConfig = [
        { label: 'Viaje ID', key: 'trip_id' },
        {
            label: 'Conductor', key: 'driver_name',
            transform: (value, rowData) => {
                const firstName = value && value.name ? value.name.trim() : '';
                const lastName = rowData.user_data && rowData.user_data.last_name ? rowData.user_data.last_name.trim() : '';
                return `${firstName} ${lastName}`;
            }
        },
        {
            label: 'Ciudad',
            key: 'zona_data.nombre',
            transform: (value) => {
                const cityName = value ? value.trim() : 'Desconocida';
                return cityName;
            }
        },
        {
            label: 'Origen',
            key: 'locations[0].description',
            transform: (value) => {
                const originDescription = value || 'N/A';
                return originDescription;
            }
        },

        {
            label: 'Destino',
            key: 'locations[1].description',
            transform: (value) => {
                const destinoDescription = value || 'N/A';
                return destinoDescription;
            }
        },
        {
            label: 'Usuario',
            key: 'user_data',
            transform: (value) => {
                if (value && value.name !== 'null') {
                    const name = value.name;
                    const lastName = value.last_name !== 'null' ? value.last_name : '';
                    return `${name} ${lastName}`;
                }
                return 'Desconocido';
            }
        },
        { label: 'Fecha', key: 'time_request', },
        { label: 'Estatus', key: 'status' },
        {
            label: 'Solicitó',
            key: 'vehicle_id',
            transform: (value) => getNameFromId(value)
        },
        { label: 'Costo', key: 'tarifa_total' },
        { label: 'Metodo', key: 'payment_method' },

    ];

    const exportToExcelLocal = () => {
        exportToExcel(filteredTripsDate, columnConfig);
    };

    const resetFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredTripsDate(trips);
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
    };
    useEffect(() => {
        document.title = `S. Monitoreo - Viajes finalizados`;
    }, [])

    return (
        <div className=' ml-30 mr-30 mt-10 mega:mr-T mega:ml-T items-center mb-4'>
            <div className="flex content-between justify-between mb-2">
                <SearchBar onSearch={handleSearch} />
                <div className="z-0 flex">
                    <DatePicker
                        label="Fecha de inicio"
                        sx={{
                            marginRight: "1rem",
                            width: "12rem",
                            borderColor: "#07f0ad",
                            color: "#07f0ad"
                        }}
                        format="DD-MM-YYYY"
                        value={startDate || ''}
                        onChange={(date) => setStartDate(date)}
                    />
                    <DatePicker
                        label="Fecha de fin"
                        format="DD-MM-YYYY"
                        value={endDate || ''}
                        onChange={(date) => setEndDate(date)}
                        sx={{
                            width: "12rem",
                        }}
                    />
                    <div className="flex items-center ml-2">
                        <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl" onClick={() => startDate && endDate ? applyDateRangeFilter() : (filteredTripsDate)}>
                            <FilterAltIcon sx={{ color: 'white' }} />
                        </button>
                        <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl ml-1" onClick={resetFilter}>
                            <FilterAltOffIcon sx={{ color: 'white' }} />
                        </button>
                    </div>

                </div>
                <button
                    className="bg-green-600 p-2 text-white font-bold mb-2 flex items-center space-x-2 hover:bg-green-900"
                    onClick={exportToExcelLocal}
                >
                    <ListAltIcon className="text-2xl mr-1" />
                    Descargar Excel
                </button>
            </div>
            {loading ? (
                <Lottie animationData={LoadingAnimation} height={100} width={100} />
            ) : (
                <DataTable
                    columns={columns}
                    data={filteredTripsDate}
                    paginationComponentOptions={paginationComponentOptions}
                    pagination
                    paginationPerPage={10} z
                    paginationRowsPerPageOptions={[10, 20, 30, 50]}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            style: {
                                borderTop: '1px solid #CBD5E0',
                            },
                        },
                        headCells: {
                            style: {
                                fontSize: '1.25rem',
                                textAlign: 'center',
                            },
                        },
                        cells: {
                            style: {
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                fontSize: '1rem'
                            },
                        },
                        pagination: {
                            style: {
                                fontSize: '1rem',
                                color: 'black',
                            }
                        },
                        table: {
                            style: {
                                height: '75vh',
                                header: 'sticky',
                                overflowX: "hidden"
                            },
                        }
                    }}
                />
            )}
        </div>
    );
}

export default ViajesFinalizados;