import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import GetAllDrivers from '../../GetAllDrivers';
import { DatePicker } from "@mui/x-date-pickers";
import '@mui/x-date-pickers/AdapterDayjs';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../../../assets/img/Loading.json'
import SnackBar from './SnackBar';

const EQUIPAMIENTO_OPTIONS = [
    { value: 'COMFORT', label: 'COMFORT' },
    { value: 'SECURITY', label: 'SECURITY' },
    { value: 'LADY', label: 'LADY' },
];

const EQUIPAMIENTO_MONTOS = {
    COMFORT: 8000.0,
    SECURITY: 2000.0,
    LADY: 8000.0,
};

export default function Form() {
    const [showLoading, setShowLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const todos = GetAllDrivers()
    const conductoresOrdenados = todos.sort((a, b) => a.name.localeCompare(b.name));

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    useEffect(() => {
        document.title = `S. Monitoreo - Registro de equipamiento`;
      }, []);
    return (
        <div className='mx-auto flex items-center justify-center p-4 font-bold w-form h-screen'>
            <div style={{ position: 'relative' }}>
                {showLoading && (
                    <div className="text-center" >
                        <Lottie animationData={LoadingAnimation} height={200} width={200} />
                    </div>
                )}
                {!showLoading && (
                    <div className='bg-white p-10'>
                        <h1 className='text-center mb-4 text-xl'>Conductores con equipamiento</h1>
                        <Formik
                            initialValues={{
                                fecha: '',
                                conductor: '',
                                equipamiento: '',
                                pagado: '',
                                instalacion: '',
                                monto: 0,
                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.fecha) {
                                    errors.fecha = 'Campo requerido';
                                }
                                if (!values.conductor) {
                                    errors.conductor = 'Selecciona un conductor';
                                }
                                if (!values.equipamiento) {
                                    errors.equipamiento = 'Campo requerido';
                                }
                                if (!values.pagado) {
                                    errors.pagado = 'Campo requerido';
                                }
                                if (!values.instalacion) {
                                    errors.instalacion = 'Campo requerido';
                                }
                                if (isNaN(values.monto) || values.monto <= 0) {
                                    errors.monto = 'Ingrese un monto válido';
                                }
                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                try {
                                    setShowLoading(true);
                                    if (!values.conductor) {
                                        console.error('Por favor, selecciona un conductor.');
                                        return;
                                    }
                                    const response = await axios.post(
                                        process.env.REACT_APP_API_URI_POST_EQUIPMENT,
                                        {
                                            date: values.fecha,
                                            idDriver: values.conductor,
                                            equipment: values.equipamiento,
                                            paid: values.pagado,
                                            place_install: values.instalacion,
                                            amount: values.monto,
                                        },
                                        {
                                            headers: {
                                                'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
                                            },
                                        }
                                    );

                                    if (response.status === 200) {
                                        setSnackbarOpen(true);
                                        resetForm();
                                    } else {
                                        console.error('Error en la solicitud POST:', response);
                                    }
                                } catch (error) {
                                    console.error('Error al realizar la solicitud POST:', error);
                                } finally {
                                    setShowLoading(false);
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit} className='max-w-md mx-auto'>

                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Fecha</label>
                                        <DatePicker
                                            sx={{
                                                border: '1.8px solid #0ea5e9',
                                                width: '100%',
                                                '& input': { fontSize: '1.05rem', padding: '10px' },
                                            }}
                                            value={values.fecha}
                                            onChange={(date) => {
                                                handleChange({ target: { name: 'fecha', value: date.toISOString().split('T')[0] } });
                                            }}
                                            renderInput={(startProps, endProps) => (
                                                <>
                                                    <input
                                                        {...startProps}
                                                        name="fecha"
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.fecha && touched.fecha && <div className='text-red-500 font-bold'>{errors.fecha}</div>}
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Conductor</label>
                                        <select
                                            className='border-solid border-2 border-sky-500 w-full p-2 font-normal'
                                            name="conductor"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.conductor}
                                        >
                                            <option value="" label="Seleccionar Conductor" />
                                            {conductoresOrdenados.map((conductor) => (
                                                <option key={conductor.driver_id} value={conductor.driver_id} label={`${conductor.name}${conductor.last_name ? ` ${conductor.last_name}` : ''}`} />
                                            ))}
                                        </select>
                                        {errors.conductor && touched.conductor && (
                                            <div className='text-red-500 font-bold'>{errors.conductor}</div>
                                        )}
                                    </div>

                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Tipo de Equipamiento</label>
                                        <select
                                            className='border-solid border-2 border-sky-500 w-full p-2 font-normal'
                                            name="equipamiento"
                                            onChange={(e) => {
                                                handleChange(e);
                                                const selectedEquipamiento = e.target.value;
                                                const monto = EQUIPAMIENTO_MONTOS[selectedEquipamiento] || 0;
                                                handleChange({ target: { name: 'monto', value: monto } });
                                            }}
                                            onBlur={handleBlur}
                                            value={values.equipamiento}
                                        >
                                            <option value="" label="Seleccionar Tipo de Equipamiento" />
                                            {EQUIPAMIENTO_OPTIONS.map((option) => (
                                                <option key={option.value} value={option.value} label={option.label} />
                                            ))}
                                        </select>
                                        {errors.equipamiento && touched.equipamiento && (
                                            <div className='text-red-500 font-bold'>{errors.equipamiento}</div>
                                        )}
                                    </div>

                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Pagado</label>
                                        <select
                                            className='border-solid border-2 border-sky-500 w-full p-2 font-normal'
                                            name="pagado"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.pagado}
                                        >
                                            <option value="" label="¿Ya esta pagado?" />
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                        </select>
                                        {errors.pagado && touched.pagado && <div className='text-red-500 font-bold'>{errors.pagado}</div>}
                                    </div>


                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Lugar de instalación</label>
                                        <input
                                            className='border-solid border-2 border-sky-500 w-full p-2 font-normal'
                                            type="text"
                                            name="instalacion"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.instalacion}
                                        />
                                        {errors.instalacion && touched.instalacion && (
                                            <div className='text-red-500 font-bold'>{errors.instalacion}</div>
                                        )}
                                    </div>

                                    <div className='mb-4'>
                                        <label className='block text-left font-bold'>Monto</label>
                                        <input
                                            className='border-solid border-2 border-sky-500 w-full p-2 bg-gray-400 font-normal'
                                            type="number"
                                            name="monto"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.monto}
                                            disabled
                                        />
                                        {errors.monto && touched.monto && (
                                            <div className='text-red-500 font-bold'>{errors.monto}</div>
                                        )}
                                    </div>

                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className='bg-BlueD text-white p-2 hover:bg-blue-900 w-full mb-2'
                                    >
                                        Enviar
                                    </button>
                                </form>
                            )}
                        </Formik>
                        <SnackBar open={snackbarOpen} handleClose={handleCloseSnackbar} />
                    </div>
                )}
            </div>
        </div>
    );
}
