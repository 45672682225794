import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Conductores = () => {
    const [conductores, setConductores] = useState([]);

    useEffect(() => {
        const fetchConductoresData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_DRIVERS,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );

                const conductoresData = response.data.drivers;
                setConductores(conductoresData);
            } catch (error) {
                console.error("Error fetching driver data:", error);
            }
        };

        fetchConductoresData();
    }, []);

    const hombresCount = conductores.filter(conductor => conductor.genre === 'Hombre').length;
    const mujeresCount = conductores.filter(conductor => conductor.genre === 'Mujer').length;
    const activosCount = conductores.filter(conductor => conductor.status === 'Activo').length;
    const pendientesCount = conductores.filter(conductor => conductor.status === 'Pendiente').length;
    const generoNuloCount = conductores.filter(conductor => conductor.genre === null).length;

    const chartData = [
        { x: "Hombres", Hombres: hombresCount },
        { x: "Mujeres", Mujeres: mujeresCount },
        { x: "Activos", Activos: activosCount },
        { x: "Pendientes", Pendientes: pendientesCount },
    ];

    return (
        <div className="flex items-center justify-center h-full">
            <div>
                <h2 className='xs:text-xl font-bold text-center'>Conductores</h2>
                <ResponsiveContainer height={300}>
                    <BarChart data={chartData}>
                        <XAxis dataKey="x" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Hombres" fill="#3aa5e6" name="Hombres" />
                        <Bar dataKey="Mujeres" fill="#51171D" name="Mujeres" />
                        <Bar dataKey="Activos" fill="#088032" name="Activos" />
                        <Bar dataKey="Pendientes" fill="#EC6112" name="Pendientes" />
                    </BarChart>
                </ResponsiveContainer>
                <div className='font-bold mt-4'>
                    <p className='md:text-xs ml-4 lg:text-lg'>Conductores totales: {conductores.length}</p>
                    <p className='md:text-xs ml-4 lg:text-lg'>Sin definir (Género): {generoNuloCount}</p>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Conductores);