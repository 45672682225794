const { split } = require("@apollo/client");
const { getMainDefinition } = require("@apollo/client/utilities");
const { wsLink } = require("./wsLink");
const { httpLink } = require("./httpLink");

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
