import { createContext, useContext, useEffect, useState } from "react";
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
const AuthContext = createContext();
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error("There is no Auth provider");
    return context;
};
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const login = (email, password) => signInWithEmailAndPassword(auth, email, password);
    const logout = () => signOut(auth);
    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            // console.log({ currentUser });
            setUser(currentUser);
            setLoading(false);
        });
    }, []);
    return (
        <AuthContext.Provider
            value={{
                login,
                user,
                logout,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}