import React, { useEffect, useState } from 'react';
import { mapStyle } from '../Sidebar/Mapa/Estilo';
import { format } from 'date-fns';
import DestinoIcon from '../../assets/img/Destino.ico';
import OrigenIcon from '../../assets/img/Origen.png';
import Car from '../../assets/img/nikucar.png';
import Loader from './Loader';
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
	getDatabase,
	ref,
	child,
	onValue,
	remove,
	set,
} from 'firebase/database';
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	Polyline,
} from '@react-google-maps/api';
import { db } from '../../firebase/firebase';
import '../../styles/panico.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const arrayL = ['geometry', 'drawing', 'places'];

const Panico = ({ id }) => {
	const [tripsData, setTripsData] = useState([]);
	const [tripsLocationsData, setTripsLocationsData] = useState([]);
	const [data, setData] = useState({});
	const [directions, setDirections] = useState([]);
	const [Loading, setLoading] = useState(false);
	const idAlert = id.length > 0 ? id[0].trip_id : null;
	const idPanic = id.length > 0 ? id[0].id : null;
	const dataPanic = id.length > 0 ? id[0] : null;

	useEffect(() => {
		if (id.length > 0) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	const alertOff = () => toast.success('Alerta Apagada Exitosamente');

	const fetchData = async () => {
		try {
			const response = await fetch(
				'https://superb-mackerel-18.hasura.app/v1/graphql',
				{
					method: 'POST',
					headers: {
						'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						query: `
                            query GetTripData($trip_id: String!) {
                                trips(where: { trip_id: { _eq: $trip_id } }) {
                                    time_request
                                    time_to_origin
                                    monitoring
                                    panic_button
                                    cronjob_id
                                    driver_id
                                    environment
                                    payment_method
                                    rate_id
                                    status
                                    substatus
                                    trip_id
                                    type
                                    user_id
                                    vehicle_id
                                    zona_id
                                    subtotal_sin_impuestos
                                    tarifa_total
                                    distance
                                    estimated_time
                                    pago_total
                                    user_trip_count
                                    viaje_minutos
                                    time_accepted
                                    note_collection
                                    driver_data {
                                        name
                                    }
                                    user_data {
                                        name
                                    }
                                }
                                trips_locations(where: { trip_id: { _eq: $trip_id } }) {
                                    timestamp
                                    description
                                    type
                                    latitude
                                    longitude
                                }
                            }
                        `,
						variables: {
							trip_id: idAlert,
						},
					}),
				}
			);
			const data = await response.json();
			const { trips, trips_locations } = data.data;
			setTripsData(trips[0]);
			setTripsLocationsData(trips_locations);
			setupRealtimeListener(trips[0].driver_id);
			return getPolylinePath(trips_locations);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const setupRealtimeListener = id => {
		try {
			const dbRef = ref(getDatabase());
			const monitoringTrip = child(
				dbRef,
				`/projects/proj_iDtR68Uec2W1ohmGzgtMRo/geoFireGroups/Conductores/${id}`
			);

			onValue(monitoringTrip, snapshot => {
				if (snapshot.exists()) {
					const data = snapshot.val();
					const dataArray = Object.entries(data).map(
						([key, value], index) => ({
							serial: index,
							id: key,
							...value,
						})
					);
					setData(dataArray[2]);
				} else {
					console.log('No hay datos en la colección "en geofire"');
				}
			});
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		}
	};

	const defaultZoom = 15;

	const getMapCenter = () => {
		if (data[0] && data[1]) {
			return { lat: parseFloat(data[0]), lng: parseFloat(data[1]) };
		} else if (tripsLocationsData.length > 0) {
			return {
				lat: tripsLocationsData[0].latitude,
				lng: tripsLocationsData[0].longitude,
			};
		}
		return null;
	};

	const getMarkers = () => {
		if (tripsLocationsData.length > 0) {
			return tripsLocationsData.map((location, index) => (
				<Marker
					key={index}
					position={{
						lat: location?.latitude,
						lng: location?.longitude,
					}}
					/* label={location.type === 'origin' ? 'Origen' : 'Destino'} */
					icon={{
						url: index === 0 ? OrigenIcon : DestinoIcon,
						scaledSize: new window.google.maps.Size(80, 80),
					}}
				/>
			));
		}
		return null;
	};

	const getPolylinePath = async location => {
		try {
			if (location?.length < 2) {
				console.error(
					'No hay suficientes ubicaciones para trazar la ruta.'
				);
				return null;
			}

			const origin = {
				lat: location[0]?.latitude,
				lng: location[0]?.longitude,
			};
			const destination = {
				lat: location[1]?.latitude,
				lng: location[1]?.longitude,
			};

			const directionsService =
				new window.google.maps.DirectionsService();

			const request = {
				origin,
				destination,
				travelMode: 'DRIVING',
			};

			await new Promise((resolve, reject) => {
				directionsService.route(request, (result, status) => {
					if (status === 'OK') {
						return setDirections(result);
					} else {
						reject(`Error fetching route: ${status}`);
					}
				});
			});
		} catch (error) {
			console.error('Error al obtener la ruta:', error);
			return null;
		}
	};

	const deleteAlert = async () => {
		confirmAlert({
			title: 'Apagar Alerta',
			message: '¿Deseas apagar esta alerta?',
			buttons: [
				{
					label: 'Sí, apagar alerta',
					onClick: async () => {
						setLoading(true);
						try {
							await updateMonitoring();
							const dbRef = ref(getDatabase());
							const alertaRef = child(
								dbRef,
								`/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/boton_panico/${idPanic}`
							);

							await remove(alertaRef);

							setLoading(false);
							alertOff();
						} catch (error) {
							console.error(
								'Error al eliminar la alerta:',
								error
							);
							setLoading(false);
						}
					},
				},
				{
					label: 'No',
					onClick: () => {
						return;
					},
				},
			],
		});
	};

	const updateMonitoring = async () => {
		try {
			const response = await fetch(
				'https://superb-mackerel-18.hasura.app/v1/graphql',
				{
					method: 'POST',
					headers: {
						'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						query: `
                    mutation UpdateTrips(
                        $trip_id: String,
                        $monitoring: Boolean,
                        $panic_button: Boolean)
                        {
                        update_trips
                            (where: {trip_id: {_eq: $trip_id}},
                            _set: {monitoring: $monitoring, panic_button: $panic_button})
                            {
                            returning {
                                monitoring
                                panic_button
                                }
                            }
                        }
                    `,
						variables: {
							trip_id: idAlert,
							panic_button: false,
							monitoring: false,
						},
					}),
				}
			);

			await response.json();
			await historyPanic();
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const historyPanic = async () => {
		try {
			await set(
				ref(
					db,
					`/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/Historial_Boton_Panico/${idPanic}`
				),
				{
					driver_id: dataPanic.driver_id,
					trip_id: idAlert,
					user_id: dataPanic.user_id,
					zona_id: dataPanic.zona_id,
					zona_nombre: dataPanic.zona_nombre,
				}
			);

			return;
		} catch (error) {
			console.log(error);
		}
	};

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS,
		libraries: arrayL,
	});

	const timeRequest = tripsData?.time_request
		? format(new Date(tripsData.time_request), 'HH:mm:ss')
		: null;

	const timeAcepted = tripsData?.time_accepted
		? format(new Date(tripsData.time_accepted), 'HH:mm:ss')
		: null;

	return (
		<>
			{id.length > 0 ? (
				<>
					{Loading && <Loader />}
					<div className='container-section-panico'>
						<div className='div-1-container-panico'>
							{isLoaded ? (
								<GoogleMap
									mapContainerStyle={{
										height: '100vh',
										width: '100%',
									}}
									options={{
										styles: mapStyle.default,
									}}
									zoom={defaultZoom}
									center={getMapCenter()}
								>
									{' '}
									{getMarkers()}
									<Marker
										key={data.id}
										position={{
											lat: parseFloat(data['0']),
											lng: parseFloat(data['1']),
										}}
										icon={{
											url: Car,
											scaledSize:
												new window.google.maps.Size(
													30,
													30
												),
										}}
									/>
									{Array.isArray(directions?.routes) &&
										directions.routes.length > 0 &&
										directions.routes[0]?.overview_path
											?.length > 0 && (
											<Polyline
												path={
													directions.routes[0]
														.overview_path
												}
												options={{
													strokeColor: '#FF0000',
												}}
											/>
										)}
								</GoogleMap>
							) : (
								<div>Loading...</div>
							)}
						</div>
						<div className='div-2-container-panico'>
							<div>
								<h1>Detalles del Viaje</h1>
							</div>
							<div>
								<p>
									<b>
										ID Viaje:{' '}
										<span> {tripsData?.trip_id} </span>
									</b>
								</p>
								<p>
									<b>
										Origen:{' '}
										<span>
											{tripsLocationsData[0]?.description}
										</span>
									</b>{' '}
								</p>
								<p>
									<b>
										Destino:{' '}
										<span>
											{tripsLocationsData[1]?.description}
										</span>
									</b>{' '}
								</p>
								<p>
									<b>
										ID Conductor:{' '}
										<span>{tripsData?.driver_id}</span>
									</b>{' '}
								</p>
								<p>
									<b>
										Conductor:{' '}
										<span>
											{tripsData.driver_data?.name}
										</span>
									</b>{' '}
								</p>
								<p>
									<b>
										ID Usuario:{' '}
										<span>{tripsData?.user_id}</span>
									</b>{' '}
								</p>
								<p>
									<b>
										Usuario:{' '}
										<span>{tripsData.user_data?.name}</span>
									</b>{' '}
								</p>
								<p>
									<b>
										Nota:{' '}
										<span>
											{' '}
											{tripsData?.note_collection
												? tripsData?.note_collection
												: 'No Hay Comentarios'}{' '}
										</span>{' '}
									</b>{' '}
								</p>
								<p>
									<b>
										Fecha:{' '}
										<span>
											{new Date(
												tripsData?.time_accepted
											).toLocaleDateString()}
										</span>
									</b>
								</p>
								<div className='div-container-blocks'>
									<p>
										<b>
											Status:{' '}
											<span> {tripsData?.status} </span>{' '}
										</b>{' '}
									</p>
									<p>
										<b>
											Sub-Status:{' '}
											<span>
												{' '}
												{tripsData?.substatus}{' '}
											</span>{' '}
										</b>{' '}
									</p>
								</div>
								<div className='div-container-blocks'>
									<p>
										<b>
											Metodo de Pago:{' '}
											<span>
												{tripsData?.payment_method}
											</span>{' '}
										</b>{' '}
									</p>
									<p>
										<b>
											Costo:{' '}
											<span>
												{' '}
												$ {tripsData?.tarifa_total}{' '}
											</span>
										</b>{' '}
									</p>
								</div>
								<div className='div-container-blocks'>
									<p>
										<b>
											Distancia:{' '}
											<span> {tripsData?.distance}</span>
										</b>{' '}
									</p>
									<p>
										<b>
											Duracion:{' '}
											<span>
												{' '}
												{
													tripsData?.estimated_time
												} min{' '}
											</span>{' '}
										</b>{' '}
									</p>
								</div>
								<div className='div-container-blocks'>
									<p>
										<b>
											Hora Solicitado:{' '}
											<span>
												{timeRequest
													? timeRequest
													: 'N/A'}
											</span>
										</b>{' '}
									</p>
									<p>
										<b>
											Hora Aceptado:{' '}
											<span>
												{timeAcepted
													? timeAcepted
													: 'N/A'}
											</span>
										</b>{' '}
									</p>
								</div>
								<div className='div-container-blocks'>
									<p>
										<b>
											Monitoriando:{' '}
											<span>
												{tripsData.monitoring
													? 'True'
													: 'False'}
											</span>
										</b>{' '}
									</p>
									<p>
										<b>
											Boton Panico:{' '}
											<span>
												{tripsData.panic_button
													? 'True'
													: 'False'}
											</span>
										</b>{' '}
									</p>
								</div>
								<div className='div-container-blocks-button'>
									<button
										id='Hablar-cliente'
										onClick={() => {
											alert('Proximamente');
										}}
									>
										Hablar con Cliente{' '}
										<span>
											<SupportAgentIcon />
										</span>
									</button>
									<button
										id='Apagar-alerta'
										onClick={() => {
											deleteAlert();
										}}
									>
										Apagar Alerta
										<span>
											<PowerSettingsNewIcon />
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className='div-container-panico-not-found'>
					<iframe
						title='lotties'
						src='https://lottie.host/embed/ea87991f-b8ef-4ed6-8c30-146bfd922436/ULIv6oe75V.json'
					></iframe>
					<p>No Hay Alertas Activas!</p>
				</div>
			)}
			<Toaster
				position='bottom-center'
				reverseOrder={false}
				toastOptions={{
					duration: 3000,
					style: {
						background: '#363636',
						color: '#fff',
					},
				}}
			/>
		</>
	);
};

export default Panico;
