import React from 'react';
import Modal from 'react-modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TablaHistorial from './TablaHistorial';

Modal.setAppElement('#root');

export default function ModalHistorial({ isOpen, onRequestClose, rowData }) {

    return (
        <div className='flex items-center justify-center h-full'>
            <Modal
                className={`bg-gray-100 flex flex-col rounded-xl border-4 w-modal h-modal border-orange-500 h-modal-content`}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Ejemplo de Modal"
                style={{
                    overlay: {
                        backgroundColor: '#00000099',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: '17vw',

                    },
                    content: {
                        overflowY: "auto",
                    },
                }}
            >
                <div className="flex justify-end">
                    <button className="p-1" onClick={onRequestClose}>
                        <HighlightOffIcon className="text-orange-500 hover:bg-orange-600 hover:text-white rounded-full text-4xl mr-1" style={{fontSize: '2rem'}}/>
                    </button>
                </div>
                <div className="text-center ">
                    <p className='font-bold text-2xl mb-2'>Historial de viajes</p>
                    <div className='m-4'>
                        <TablaHistorial rowData={rowData} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}