import React, { useEffect, useState } from 'react';
import DocumentForm from './DocumentForm';
import '../../styles/listarVehiculos.css';

const ListarVehiculos = () => {
	const [vehicles, setVehicules] = useState([]);
	const [vehiculeData, setVehiculeData] = useState({});
	const [modal, setModal] = useState(false);

	useEffect(() => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
        query GetVehicles {
          vehicles {
            vehiculo_id
            name
            description
		    image
		    type
		    passanger_quantity
          }
        }
      `,
			}),
		})
			.then(res => res.json())
			.then(res => setVehicules(res.data.vehicles));
	}, []);

	return (
		<>
			{modal ? (
				<DocumentForm vehiculeData={vehiculeData} setModal={setModal} />
			) : (
				<>
					<div className='container-listar-vehiculos'>
						<div>
							<h1>Selecciona un Vehiculo</h1>
						</div>

						{vehicles.map((vehiculo, index) => (
							<div
								key={index}
								className='div-card-listar-vehiculos'
								onClick={() => {
									setVehiculeData(vehiculo);
									setModal(true);
								}}
							>
								<div className='div-card-listar-vehiculos-div-img'>
									<img src={vehiculo?.image} alt='' />
								</div>
								<div className='div-card-listar-vehiculos-div-name'>
									<p>
										Nombre: <span> {vehiculo?.name}</span>
									</p>
									<p>
										Descripcion:
										<span>{vehiculo?.description} </span>
									</p>
								</div>
								<div className='div-card-listar-vehiculos-div-type'>
									<div>
										<p>
											Tipo: <span> {vehiculo?.type}</span>
										</p>
									</div>
									<div>
										<p>
											Pasajeros:{' '}
											<span>
												{' '}
												{vehiculo?.passanger_quantity}
											</span>{' '}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</>
	);
};

export default ListarVehiculos;
