const initialState = {
	currentPage: 'Conductores',
	poligonoId: '',
	nombreZona: '',
	panicoZona: '',
};

const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_CURRENT_PAGE':
			return {
				...state,
				currentPage: action.payload,
			};

		case 'POLIGONO_ID':
			return {
				...state,
				poligonoId: action.payload,
			};

		case 'POLIGONO_ZONA':
			return {
				...state,
				nombreZona: action.payload,
			};

		case 'PANICO_ZONA':
			return {
				...state,
				panicoZona: action.payload,
			};
		default:
			return state;
	}
};

export default rootReducer;
