import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import TablaTiempoReal from './TablaTiempoReal';

export default function DriverTable(props) {
    const [expanded, setExpanded] = useState(true);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const numberOfDrivers = props.drivers.length;

    const handleButtonClick = (location) => {
        if (props.onLocationSelect) {
            const lat = location.driverData.l[0];
            const lng = location.driverData.l[1];
            const coordinates = {
                lat: lat,
                lng: lng
            };
            props.onLocationSelect(coordinates);
        }
    };


    return (
        <div className="fixed right-0 top-0 bg-blueT 2xs:mt-20 w-96 semi:block 2xs:hidden z-10 p-4 mr-16 shadow-md text-white bg-opacity-60">
            <div className="flex justify-center items-center">
                <button
                    onClick={toggleExpansion}
                    className="text-white flex items-center justify-center"
                >
                    {expanded ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                    )}
                </button>
            </div>
            {expanded && (
                <div>
                    <h2 className="text-lg text-center mb-1">
                        Conductores conectados: {numberOfDrivers}
                    </h2>
                    <TablaTiempoReal
                        drivers={props.drivers}
                        onButtonClick={handleButtonClick}
                    />
                </div>
            )}
        </div>
    );
}