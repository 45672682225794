import { useState, useEffect } from "react";
import axios from "axios";

const sortDriversByStartDate = (drivers) => {
    return drivers.sort((a, b) => {
        const dateA = a.date_registration ? new Date(a.date_registration) : null;
        const dateB = b.date_registration ? new Date(b.date_registration) : null;

        if (dateA && dateB) {
            return dateB - dateA;
        } else if (dateA) {
            return -1;
        } else if (dateB) {
            return 1;
        } else {
            return 0;
        }
    });
};

const GetAllDrivers = () => {
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        const fetchConductoresData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_DRIVERS, {
                    headers: {
                        "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                    },
                });
                const sortedDrivers = sortDriversByStartDate(response.data.drivers);
                setDrivers(sortedDrivers);
            } catch (error) {
                console.error("Error fetching driver data:", error);
            }
        };

        fetchConductoresData();

    }, []);

    return drivers;
};

export default GetAllDrivers;