import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export async function uploadDocument(file, url, setSnackbarOpen, setSnackbarError) {
    const fechaActual = new Date();
    const storageInstance = getStorage();
    const httpsReference = ref(storageInstance, url);
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
        console.error("Tipo de archivo no permitido. Solo se permiten imágenes (jpg, jpeg, png) y PDF.");
        setSnackbarError(true);
        return;
    }

    const namefile = httpsReference.name === undefined ? fechaActual : httpsReference.name;
    const storageRef = ref(storageInstance, 'gs://nikucar-apk.appspot.com/media/proj_iDtR68Uec2W1ohmGzgtMRo/app_aYniA6tJPMc2DH943XAihq/dataApplications/' + namefile);
    setSnackbarOpen(true);

    try {
        const snapshot = await uploadBytes(storageRef, file);
        const newURL = await getDownloadURL(storageRef);
    } catch (error) {
        console.error("Error al subir el archivo:", error);
        setSnackbarOpen(false);
    }
}

export default function CustomSnackbar({ setSnackbarOpen, snackbarOpen, setSnackbarError, snackbarError }) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        setSnackbarError(false);
    };

    return (
        <>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%', height: '6vh', fontSize: '1.25rem' }}>
                    Documento actualizado
                </Alert>
            </Snackbar>

            <Snackbar open={snackbarError} autoHideDuration={3000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="error" sx={{ width: '100%', height: '6vh', fontSize: '1.25rem' }}>
        Error al subir el archivo. Por favor, asegúrate de que el tipo de archivo sea válido.
    </Alert>
</Snackbar>

        </>
    );
}
