import React, { useState } from 'react';
import { getDatabase, ref as DatabaseRef, set } from 'firebase/database';
import { app } from '../../firebase/firebase';
import Switch from 'react-switch';
import Loader from './Loader';
import '../../styles/configuracionesForm.css';

const FormConfiguraciones = ({ data, setModal, editar, setEditar }) => {
	const [isLoadding, setIsLoadding] = useState(false);

	const [
		buildNumberMinimoClienteAndroid,
		setBuildNumberMinimoClienteAndroid,
	] = useState(data[0].buildNumberMinimoClienteAndroid);
	const [buildNumberMinimoClienteIos, setBuildNumberMinimoClienteIos] =
		useState(data[0].buildNumberMinimoClienteIos);
	const [
		buildNumberMinimoConductorAndroid,
		setBuildNumberMinimoConductorAndroid,
	] = useState(data[0].buildNumberMinimoConductorAndroid);
	const [buildNumberMinimoConductorIos, setBuildNumberMinimoConductorIos] =
		useState(data[0].buildNumberMinimoConductorIos);
	const [distance_getDrivers, setDistance_getDrivers] = useState(
		data[0].distance_getDrivers
	);
	const [environment, setEnvironment] = useState(data[0].environment);
	const [forzarUpdateClienteAndroid, setForzarUpdateClienteAndroid] =
		useState(data[0].forzarUpdateClienteAndroid);
	const [forzarUpdateClienteIos, setForzarUpdateClienteIos] = useState(
		data[0].forzarUpdateClienteIos
	);
	const [forzarUpdateConductorAndroid, setForzarUpdateConductorAndroid] =
		useState(data[0].forzarUpdateConductorAndroid);
	const [forzarUpdateConductorIos, setForzarUpdateConductorIos] = useState(
		data[0].forzarUpdateConductorIos
	);
	const [fueraServicioCliente, setFueraServicioCliente] = useState(
		data[0].fueraServicioCliente
	);
	const [fueraServicioConductor, setFueraServicioConductor] = useState(
		data[0].fueraServicioConductor
	);
	const [function_environment, setFunction_environment] = useState(
		data[0].function_environment
	);
	const [multaCancelacion, setMultaCancelacion] = useState(
		data[0].multaCancelacion
	);

	const [nikuAPI, setNikuAPI] = useState(data[0].nikuAPI ? true : false);

	const [notificaSMS, setNotificaSMS] = useState(data[0].notificaSMS);

	const [
		oportunidad_segundosAceptarOportunidad,
		setOportunidad_segundosAceptarOportunidad,
	] = useState(data[0].oportunidad_segundosAceptarOportunidad);
	const [prefijoCodigoPais, setPrefijoCodigoPais] = useState(
		data[0].prefijoCodigoPais
	);
	const [prefijoDireccion, setPrefijoDireccion] = useState(
		data[0].prefijoDireccion
	);
	const prefijoLocation = data[0].prefijoLocation;

	const [prefijoMoneda, setPrefijoMoneda] = useState(data[0].prefijoMoneda);
	const [prefijoTelefono, setPrefijoTelefono] = useState(
		data[0].prefijoTelefono
	);
	const [timeCancelarViaje, setTimeCancelarViaje] = useState(
		data[0].timeCancelarViaje
	);
	const [timeVerificationCode, setTimeVerificationCode] = useState(
		data[0].timeVerificationCode
	);
	const [tracking_distance_filter, setTracking_distance_filter] = useState(
		data[0].tracking_distance_filter
	);
	const [tracking_webhook, setTracking_webhook] = useState(
		data[0].tracking_webhook
	);
	const [verificacionTelefonoCliente, setVerificacionTelefonoCliente] =
		useState(data[0].verificacionTelefonoCliente);
	const [verificacionTelefonoConductor, setVerificacionTelefonoConductor] =
		useState(data[0].verificacionTelefonoConductor);
	const [webhook_oneOff, setWebhook_oneOff] = useState(
		data[0].webhook_oneOff
	);
	const [webhook_one_cancelar_viaje, setWebhook_one_cancelar_viaje] =
		useState(data[0].webhook_one_cancelar_viaje);

	const [maximoNegativoWallet, setMaximoNegativoWallet] = useState(
		data[0].maximoNegativoWallet
	);
	const [maximoPositivoWallet, setMaximoPositivoWallet] = useState(
		data[0].maximoPositivoWallet
	);
	const [minimoRetiroWallet, setMinimoRetiroWallet] = useState(
		data[0].minimoRetiroWallet
	);
	const [montoMinimoRecargaWallet, setMontoMinimoRecargaWallet] = useState(
		data[0].montoMinimoRecargaWallet
	);

	const [permiteNegativosWallet, setPermiteNegativosWallet] = useState(
		data[0].permiteNegativosWallet
	);
	const [saldoRegaloClienteWallet, setSaldoRegaloClienteWallet] = useState(
		data[0].saldoRegaloClienteWallet
	);
	const [saldoRegaloConductorWallet, setSaldoRegaloConductorWallet] =
		useState(data[0].saldoRegaloConductorWallet);

	const guardarConfiguracion = e => {
		e.preventDefault();
		setIsLoadding(true);

		setTimeout(async () => {
			const db = getDatabase(app);

			try {
				await set(
					DatabaseRef(
						db,
						`/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/APP_Configuracion/default`
					),
					{
						buildNumberMinimoClienteAndroid,
						buildNumberMinimoClienteIos,
						buildNumberMinimoConductorAndroid,
						distance_getDrivers,
						environment,
						forzarUpdateClienteAndroid,
						forzarUpdateClienteIos,
						forzarUpdateConductorAndroid,
						forzarUpdateConductorIos,
						fueraServicioCliente,
						fueraServicioConductor,
						function_environment,
						maximoNegativoWallet,
						maximoPositivoWallet,
						minimoRetiroWallet,
						montoMinimoRecargaWallet,
						multaCancelacion,
						notificaSMS,
						oportunidad_segundosAceptarOportunidad,
						permiteNegativosWallet,
						prefijoCodigoPais,
						prefijoDireccion,
						prefijoLocation,
						prefijoMoneda,
						prefijoTelefono,
						saldoRegaloClienteWallet,
						saldoRegaloConductorWallet,
						timeCancelarViaje,
						timeVerificationCode,
						tracking_distance_filter,
						tracking_webhook,
						verificacionTelefonoCliente,
						verificacionTelefonoConductor,
						webhook_oneOff,
						webhook_one_cancelar_viaje,
					}
				);

				setIsLoadding(false);
				setModal(false);
				setEditar(!editar);
			} catch (error) {
				setIsLoadding(false);
				alert(error);
			}
		}, 3000);
	};

	return (
		<>
			{isLoadding && <Loader />}
			<section className='section-configuracion-editar'>
				<h1>Editar Configuracion</h1>
				<form
					onSubmit={guardarConfiguracion}
					className='form-main-configuracion-editar'
				>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 1'>
							Build Number Minimo Cliente Android
						</label>
						<input
							id='item 1'
							type='text'
							value={buildNumberMinimoClienteAndroid}
							onChange={e => {
								setBuildNumberMinimoClienteAndroid(
									e.target.value
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 2'>
							Build Number Minimo Cliente IOS{' '}
						</label>
						<input
							id='item 2'
							type='text'
							value={buildNumberMinimoClienteIos}
							onChange={e => {
								setBuildNumberMinimoClienteIos(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 3'>
							Build Number Minimo Conductor Android{' '}
						</label>
						<input
							id='item 3'
							type='text'
							value={buildNumberMinimoConductorAndroid}
							onChange={e => {
								setBuildNumberMinimoConductorAndroid(
									e.target.value
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 4'>
							Build Number Minimo Conductor IOS{' '}
						</label>
						<input
							id='item 4'
							type='text'
							value={buildNumberMinimoConductorIos}
							onChange={e => {
								setBuildNumberMinimoConductorIos(
									e.target.value
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 5'> Distance Get Drivers </label>
						<input
							id='item 5'
							type='text'
							value={distance_getDrivers}
							onChange={e => {
								setDistance_getDrivers(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 6'> Environment </label>
						<input
							id='item 6'
							type='text'
							value={environment}
							onChange={e => {
								setEnvironment(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 7'> Function Environment </label>
						<input
							id='item 7'
							type='text'
							value={function_environment}
							onChange={e => {
								setFunction_environment(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 8'> Multa Cancelacion </label>
						<input
							id='item 8'
							type='text'
							value={multaCancelacion}
							onChange={e => {
								setMultaCancelacion(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 9'> Aceptar Oportunidad</label>
						<input
							id='item 9'
							type='text'
							value={oportunidad_segundosAceptarOportunidad}
							onChange={e => {
								setOportunidad_segundosAceptarOportunidad(
									e.target.value
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 10'> Prefijo Codigo Pais</label>
						<input
							id='item 10'
							type='text'
							value={prefijoCodigoPais}
							onChange={e => {
								setPrefijoCodigoPais(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 11'> Prefijo Moneda </label>
						<input
							id='item 11'
							type='text'
							value={prefijoMoneda}
							onChange={e => {
								setPrefijoMoneda(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 12'> Prefijo Telefono </label>
						<input
							id='item 12'
							type='text'
							value={prefijoTelefono}
							onChange={e => {
								setPrefijoTelefono(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 13'> Time Cancelar Viaje </label>
						<input
							id='item 13'
							type='text'
							value={timeCancelarViaje}
							onChange={e => {
								setTimeCancelarViaje(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 14'>Time Verification Code </label>
						<input
							id='item 14'
							type='text'
							value={timeVerificationCode}
							onChange={e => {
								setTimeVerificationCode(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='item 15'>
							Tracking Distance Filter
						</label>
						<input
							id='item 15'
							type='text'
							value={tracking_distance_filter}
							onChange={e => {
								setTracking_distance_filter(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='wallet'>Maximo Negativo Wallet</label>
						<input
							id='wallet'
							type='text'
							value={maximoNegativoWallet}
							onChange={e => {
								setMaximoNegativoWallet(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='maximoPositivoWallet'>
							Maximo Positivo Wallet
						</label>
						<input
							id='maximoPositivoWallet'
							type='text'
							value={maximoPositivoWallet}
							onChange={e => {
								setMaximoPositivoWallet(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='minimoRetiroWallet'>
							Minimo Retiro Wallet
						</label>
						<input
							id='minimoRetiroWallet'
							type='text'
							value={minimoRetiroWallet}
							onChange={e => {
								setMinimoRetiroWallet(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='montoMinimoRecargaWallet'>
							Monto Minimo Recarga Wallet
						</label>
						<input
							id='montoMinimoRecargaWallet'
							type='text'
							value={montoMinimoRecargaWallet}
							onChange={e => {
								setMontoMinimoRecargaWallet(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='saldoRegaloClienteWallet'>
							Saldo Regalo Cliente Wallet
						</label>
						<input
							id='saldoRegaloClienteWallet'
							type='text'
							value={saldoRegaloClienteWallet}
							onChange={e => {
								setSaldoRegaloClienteWallet(e.target.value);
							}}
						/>
					</div>

					<div className='div-configuracion-editar'>
						<label htmlFor='saldoRegaloConductorWallet'>
							Saldo Regalo Conductor Wallet
						</label>
						<input
							id='saldoRegaloConductorWallet'
							type='text'
							value={saldoRegaloConductorWallet}
							onChange={e => {
								setSaldoRegaloConductorWallet(e.target.value);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label htmlFor='permiteNegativosWallet'>
							Permite Negativos Wallet
						</label>
						<Switch
							className='div-configuracion-editar-switch'
							checked={permiteNegativosWallet}
							onChange={() => {
								setPermiteNegativosWallet(
									!permiteNegativosWallet
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Forzar Update Cliente Android </label>
						<Switch
							className='div-configuracion-editar-switch'
							checked={forzarUpdateClienteAndroid}
							onChange={() => {
								setForzarUpdateClienteAndroid(
									!forzarUpdateClienteAndroid
								);
							}}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Forzar Update Cliente IOS </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setForzarUpdateClienteIos(
									!forzarUpdateClienteIos
								);
							}}
							checked={forzarUpdateClienteIos}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label>Forzar Update Conductor Android </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setForzarUpdateConductorAndroid(
									!forzarUpdateConductorAndroid
								);
							}}
							checked={forzarUpdateConductorAndroid}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Forzar Update Conductor IOS </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setForzarUpdateConductorIos(
									!forzarUpdateConductorIos
								);
							}}
							checked={forzarUpdateConductorIos}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Fuera Servicio Cliente </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setFueraServicioCliente(!fueraServicioCliente);
							}}
							checked={fueraServicioCliente}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Fuera Servicio Conductor </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setFueraServicioConductor(
									!fueraServicioConductor
								);
							}}
							checked={fueraServicioConductor}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Niku API </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setNikuAPI(!nikuAPI);
							}}
							checked={nikuAPI}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Notifica SMS</label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setNotificaSMS(!notificaSMS);
							}}
							checked={notificaSMS}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label> Verificacion Telefono Cliente </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setVerificacionTelefonoCliente(
									!verificacionTelefonoCliente
								);
							}}
							checked={verificacionTelefonoCliente}
						/>
					</div>
					<div className='div-configuracion-editar'>
						<label>Verificacion Telefono Conductor </label>
						<Switch
							className='div-configuracion-editar-switch'
							onChange={() => {
								setVerificacionTelefonoConductor(
									!verificacionTelefonoConductor
								);
							}}
							checked={verificacionTelefonoConductor}
						/>
					</div>
					<div className='form-configuraciones-editar-inputs'>
						<p> Prefijo Direccion </p>
						<input
							type='text'
							value={prefijoDireccion}
							onChange={e => {
								setPrefijoDireccion(e.target.value);
							}}
						/>
						<label> Prefijo Direccion </label>
					</div>
					<div className='form-configuraciones-editar-inputs'>
						<p> Prefijo Location </p>
						<input
							readOnly
							type='text'
							value={`Latitude: ${prefijoLocation.latitude}, Longitude: ${prefijoLocation.longitude}`}
						/>
						<label> Prefijo Location </label>
					</div>
					<div className='form-configuraciones-editar-inputs'>
						<p> Tracking Webhook </p>
						<input
							readOnly
							type='text'
							value={tracking_webhook}
							onChange={e => {
								setTracking_webhook(e.target.value);
							}}
						/>
						<label> Tracking Webhook </label>
					</div>
					<div className='form-configuraciones-editar-inputs'>
						<p> Webhook One Off </p>
						<input
							readOnly
							type='text'
							value={webhook_oneOff}
							onChange={e => {
								setWebhook_oneOff(e.target.value);
							}}
						/>
						<label> Webhook One Off </label>
					</div>
					<div className='form-configuraciones-editar-inputs'>
						<p>Webhook One Cancelar Viaje </p>
						<input
							readOnly
							type='text'
							value={webhook_one_cancelar_viaje}
							onChange={e => {
								setWebhook_one_cancelar_viaje(e.target.value);
							}}
						/>
						<label> Webhook One Cancelar Viaje </label>
					</div>
					<div className='div-buttom-guardar-configuraciones'>
						<button
							type='submit'
							className='buttom-guardar-configuraciones'
						>
							Guardar
						</button>
					</div>
				</form>
				<button
					className='buttom-cancelar-configuraciones'
					onClick={() => {
						setModal(false);
					}}
				>
					Cancelar
				</button>
			</section>
		</>
	);
};

export default FormConfiguraciones;
