import React from 'react';
import NikuCar from '../../assets/img/niku_car_blanco.png';
import { useAuth } from '../../context/AuthContext';
import useUserRole from '../../getRole';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MapIcon from '@mui/icons-material/Map';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import PinDropIcon from '@mui/icons-material/PinDrop';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MovingIcon from '@mui/icons-material/Moving';
import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '../icons/ErrorIcon';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const Accordion = styled(props => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
	backgroundColor: '#002e5a',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={
			<ArrowForwardIosSharpIcon
				sx={{ fontSize: '1.2rem', color: 'white' }}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(0, 0, 0, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		color: 'white',
		'&:hover': {
			color: 'black',
			backgroundColor: 'white',
		},
	},
}));

const LargerTypography = styled(Typography)({
	fontSize: '1.35rem',
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(0),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Sidebar = ({ setCurrentPage, currentPage }) => {
	const [expanded, setExpanded] = React.useState(false);
	const { user, logout } = useAuth();
	const { userRole } = useUserRole(user);

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleLogout = async () => {
		try {
			await logout();
		} catch (error) {
			console.error('Error al cerrar sesión:', error);
		}
	};

	const isButtonActive = buttonName => {
		return currentPage === buttonName ? 'border-b-2 border-white' : '';
	};

	return (
		<div className='bg-BlueD text-white semi:w-sb h-full fixed flex flex-col justify-start items-center overflow-y-auto overflow-x-hidden'>
			<div className=' xl:block 2xs:hidden flex flex-col justify-end items-center'>
				<header className='mt-2'>
					<div className=' text-center text-4xl big:p-4 '>
						<img
							src={NikuCar}
							alt='NikuCar'
							className='xl:w-32 xl:h-24 mx-auto big:mt-7 mt-3'
						/>
						<h1 className='big:mt-10 semi:mr-4 big:mr-0 semi:mt-5'>
							Monitoreo NikuCar
						</h1>
					</div>
				</header>
				<div className='grid grid-flow-row-dense grid-rows-6 semi:mt-0 big:mt-10 text-xl semi:ml-8 big:ml-0 lg:ml-0'>
					{(userRole === 'Admin' ||
						userRole === 'Master' ||
						userRole === 'Control') && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Home'
							)}`}
							onClick={() => setCurrentPage('Home')}
						>
							<HomeIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Home
						</button>
					)}
					{(userRole === 'Admin' ||
						userRole === 'Master' ||
						userRole === 'Control') && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Conductores'
							)}`}
							onClick={() => setCurrentPage('Conductores')}
						>
							<DirectionsCarIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Conductores
						</button>
					)}
					{(userRole === 'Admin' ||
						userRole === 'Master' ||
						userRole === 'Control') && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'ViajesEnCurso'
							)}`}
							onClick={() => setCurrentPage('ViajesEnCurso')}
						>
							<MovingIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Viajes En Curso
						</button>
					)}
					{(userRole === 'Admin' ||
						userRole === 'Master' ||
						userRole === 'Control') && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'ViajesFinalizados'
							)}`}
							onClick={() => setCurrentPage('ViajesFinalizados')}
						>
							<CheckIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Viajes Finalizados
						</button>
					)}
					{userRole === 'Admin' && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Pasajeros'
							)}`}
							onClick={() => setCurrentPage('Pasajeros')}
						>
							<EmojiPeopleIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Pasajeros
						</button>
					)}
					{userRole === 'Admin' && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'ListarVehiculos'
							)}`}
							onClick={() => setCurrentPage('ListarVehiculos')}
						>
							<ArticleIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Documentos
						</button>
					)}
					{userRole === 'Admin' && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Configuraciones'
							)}`}
							onClick={() => setCurrentPage('Configuraciones')}
						>
							<SettingsSuggestIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Configuraciones
						</button>
					)}
					{userRole === 'Admin' && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Panico'
							)}`}
							onClick={() => setCurrentPage('Panico')}
						>
							<TaxiAlertIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Panico
						</button>
					)}
					{(userRole === 'Admin' || userRole === 'Master') && (
						<button
							className={`text-white ml-4 rounded-md hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
								'Mapa'
							)}`}
							onClick={() => setCurrentPage('Mapa')}
						>
							<MapIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
							Mapa
						</button>
					)}
					{(userRole === 'Admin' ||
						userRole === 'Master' ||
						userRole === 'Control') && (
						<Accordion
							expanded={expanded === 'tarifas'}
							onChange={handleChange('tarifas')}
						>
							<AccordionSummary
							/* aria-controls='panel1d-content'
								id='panel1d-header' */
							>
								<LargerTypography>Tarifas</LargerTypography>
							</AccordionSummary>
							<AccordionDetails>
								<button
									className={`text-white ml-14 rounded-md pt-2 text-xl w-60 pl-2 hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
										'Zonas'
									)}`}
									onClick={() => setCurrentPage('Zonas')}
								>
									<PinDropIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
									Zonas
								</button>

								<button
									className={`text-white ml-14 rounded-md pt-2 text-xl w-60 pl-2 hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
										'Poligonos'
									)}`}
									onClick={() => setCurrentPage('Poligonos')}
								>
									<ViewInArIcon className='inline-block ml-1 mb-2 w-6 h-6 mr-2' />
									Poligonos
								</button>
							</AccordionDetails>
						</Accordion>
					)}

					<Accordion
						expanded={expanded === 'panel1'}
						onChange={handleChange('panel1')}
					>
						<AccordionSummary
							aria-controls='panel1d-content'
							id='panel1d-header'
						>
							<LargerTypography>Equipamiento</LargerTypography>
						</AccordionSummary>
						<AccordionDetails>
							<button
								className={`text-white ml-14 rounded-md pt-2 text-xl w-60 pl-2 hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
									'Form'
								)}`}
								onClick={() => setCurrentPage('Form')}
							>
								Formulario
							</button>
							<button
								className={`text-white ml-14 rounded-md pt-2 text-xl w-60 pl-2 hover:bg-white py-2 hover:text-black text-left focus:outline-none ${isButtonActive(
									'ConductoresEquipamiento'
								)}`}
								onClick={() =>
									setCurrentPage('ConductoresEquipamiento')
								}
							>
								Conductores
							</button>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className='flex flex-col items-center justify-center big:mt-5 bottom-0'>
					<AccountCircleIcon style={{ fontSize: '3rem' }} />
					<button
						className='bg-white rounded-full text-lg p-2 aspect-w-4 aspect-h-1 text-BlueD big:mt-4 semi:mt-1 hover:text-black hover:scale-105 transition-all hover:font-bold'
						onClick={handleLogout}
					>
						Cerrar sesión
					</button>

					<button className='big:mt-7 semi:mt-3 text-xs mb-2 big:hover:scale-110 semi:hover:scale-105 hover:text-orange-500'>
						NIKU TECNOLOGÍA, SA DE CV v2.1.1
					</button>
					<button
						onClick={() =>
							window.open(
								'https://nikutecnologiasadecv.slack.com/archives/C066ML0LBUY',
								'_blank'
							)
						}
						className='bg-red-500 rounded-full text-lg p-2 aspect-w-4 aspect-h-1 big:mt-7 semi:mt-3  mb-2 big:hover:scale-110 semi:hover:scale-60 hover:text-white flex items-center justify-center align-center gap-2'
					>
						<ErrorIcon />
						Reportar ERROR
					</button>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
