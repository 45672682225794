import React, { useEffect, useState } from 'react';
import store from '../../redux/store';
import TarifaForm from './TarifaForm';
import { FaPlusCircle } from 'react-icons/fa';
import { IoArrowBackSharp } from 'react-icons/io5';
import TarifaDinamica from './TarifaDinamica';
import Loader from './Loader';
import getVehicles from './vehicles';
import '../../styles/tarifa.css';

const Tarifas = ({ setCurrentPage }) => {
	const [tarifas, setTarifas] = useState([]);
	const [modal, setModal] = useState(false);
	const [whatModal, setWhatModal] = useState('');
	const [titulo, setTitulo] = useState('');
	const [data, setData] = useState({});
	const [update, setUpdate] = useState(false);
	const [editar, setEditar] = useState(false);
	const [nombreZona, setNombreZona] = useState('');
	const [idZona, setIdZona] = useState('');
	const [tarifaDinamica, setTarifaDinamica] = useState(false);
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState([]);

	const poligonoID = store.getState();

	useEffect(() => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `query getRates {
					rates(order_by: {poligono_id: asc}, where: {poligono_id: {_eq: "${poligonoID.poligonoId}"}, environment: {_eq: "2"}}) {
						additional_charge
						base_rate
						comision
						cuota_solicitud
						description
						environment
						image
						impuestos_isr
						impuestos_iva
						index
						max_distance
						minimum_rate
						name
						poligono_id
						plataforma_iva
						rate_id
						rate_per_kilometer
						rate_per_minute
						status
                    	uso_plataforma
                    	vehiculo_id
                    polygon_data {
						nombre
                    }
				}
			}`,
			}),
		})
			.then(res => res.json())
			.then(res => {
				setTarifas(res.data.rates);
				setIdZona(poligonoID.poligonoId);
				setNombreZona(poligonoID.nombreZona);
				setImage(getVehicles);
			});
	}, [poligonoID.poligonoId, poligonoID.nombreZona, update]);

	const eliminarTarifa = id => {
		setLoading(true);

		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: `
        mutation MyMutation($id: String!) {
          delete_rates_by_pk(rate_id: $id) {
            rate_id
          }
        }`,
				variables: {
					id,
				},
			}),
		})
			.then(res => res.json())
			.then(() => {
				setUpdate(!update);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	return (
		<>
			{loading && <Loader />}
			{tarifaDinamica ? (
				<TarifaDinamica
					setTarifaDinamica={setTarifaDinamica}
					nombreZona={nombreZona}
					poligonoID={poligonoID}
				/>
			) : modal ? (
				<>
					{whatModal === 'tarifa' && (
						<TarifaForm
							setModal={setModal}
							titulo={titulo}
							data={data}
							update={update}
							setUpdate={setUpdate}
							editar={editar}
							nombreZona={nombreZona}
							idZona={idZona}
						/>
					)}
				</>
			) : (
				<>
					<section className='section-tarifas'>
						<div className='div-tarifas-titulo'>
							<div>
								<button
									onClick={() => {
										setCurrentPage('Zonas');
									}}
								>
									<IoArrowBackSharp />
								</button>
							</div>
							<h1>Lista de tarifas por zona</h1>
							<div>
								<button
									onClick={e => {
										e.stopPropagation();
										setTarifaDinamica(true);
										setNombreZona(
											tarifas[0].polygon_data?.nombre
										);
									}}
								>
									{' '}
									Tarifa Dinamica
								</button>
							</div>
						</div>
						{tarifas.map(tarifa => (
							<div
								key={tarifa.rate_id}
								className='div-tarifas-card'
								onClick={() => {
									setModal(true);
									setTitulo('Editar');
									setData(tarifa);
									setEditar(true);
									setNombreZona(tarifa.polygon_data?.nombre);
									setWhatModal('tarifa');
								}}
							>
								<div className='div-tarifas-card-imagen'>
									{image
										.filter(
											img =>
												img.vehiculo_id ===
												tarifa.vehiculo_id
										)
										.map(img => (
											<img
												key={img.vehiculo_id}
												src={img.image}
												alt='imagen'
											/>
										))}
								</div>
								<div className='div-tarifas-card-description'>
									<p>Tarifa en produccion</p>
									<span> {tarifa.name}</span>
									<div className='div-tarifas-card-bases'>
										<div>
											<h2>Base</h2>
											<p>{tarifa?.base_rate}</p>
										</div>
										<div>
											<h2>KM</h2>
											<p>{tarifa?.rate_per_kilometer}</p>
										</div>
										<div>
											<h2>Minima</h2>
											<p>{tarifa?.minimum_rate}</p>
										</div>
									</div>
								</div>
								<div className='div-tarifas-card-buttom'>
									<button
										onClick={e => {
											e.stopPropagation();
											const confirmarEliminacion =
												window.confirm(
													'¿Seguro que deseas eliminar esta tarifa?'
												);
											if (confirmarEliminacion) {
												eliminarTarifa(tarifa.rate_id);
											}
										}}
									>
										Eliminar
									</button>
								</div>
							</div>
						))}

						<div className='div-tarifas-modal'>
							<button
								onClick={() => {
									setModal(true);
									setTitulo('Crear');
									setEditar(false);
									setWhatModal('tarifa');
								}}
							>
								<FaPlusCircle id='modal-icon-form' />
							</button>
						</div>
					</section>
				</>
			)}
		</>
	);
};

export default Tarifas;
