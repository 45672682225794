import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';
import Home from './Sidebar/Home';
import Zonas from './Sidebar/Zonas';
import Tarifas from './Sidebar/Tarifas';
import Conductores from './Sidebar/Conductores';
import ViajesEnCurso from './Sidebar/ViajesEnCurso';
import ViajesFinalizados from './Sidebar/ViajesFinalizados';
import Pasajeros from './Sidebar/Pasajeros';
import Mapa from './Sidebar/Mapa';
import NavBar from '../components/NavBar/NavBar';
import useCurrentTrips from '../hooks/useCurrentTrips';
import AlertModal from './Sidebar/CurrentTrips/AlertModal';
import ConductoresEquipamiento from './Sidebar/Equipamiento/ConductoresEquipamiento/ConductoresEquipamiento';
import Form from './Sidebar/Equipamiento/Form/Form';
import Poligonos from '../components/Sidebar/Poligonos';
import Panico from './Sidebar/Panico';
import AlertaPanico from './Sidebar/AlertaPanico';
import Configuraciones from '../components/Sidebar/Configuraciones';
import ListarVehiculos from './Sidebar/ListarVehiculos';
import { getDatabase, ref, child, onValue } from 'firebase/database';
import { useAuth } from '../context/AuthContext';
import useUserRole from '../getRole';

const componentMap = {
	Home,
	Zonas,
	Poligonos,
	Tarifas,
	Conductores,
	ViajesEnCurso,
	ViajesFinalizados,
	Pasajeros,
	Configuraciones,
	ListarVehiculos,
	Panico,
	Mapa,
	Form,
	ConductoresEquipamiento,
};

const Dashboard = () => {
	const [currentPage, setCurrentPage] = useState('Home');
	const { trips, /* monitoring, */ panicAlert } = useCurrentTrips();
	const [showModal, setShowModal] = useState(false);
	const [mostrarPanicAlert, setMostrarPanicAlert] = useState(false);
	const [data, setData] = useState([]);
	const { user } = useAuth();
	const { /* idZone, */ nameZone } = useUserRole(user);

	useEffect(() => {
		if (nameZone) {
			setupRealtimeListener(nameZone);
		}
	}, [nameZone]);

	const setupRealtimeListener = zona => {
		try {
			const dbRef = ref(getDatabase());
			const botonPanicoRef = child(
				dbRef,
				'/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/boton_panico'
			);

			onValue(botonPanicoRef, snapshot => {
				if (snapshot.exists()) {
					const data = snapshot.val();
					const dataArray = Object.entries(data).map(
						([key, value], index) => ({
							serial: index,
							id: key,
							...value,
						})
					);
					const trip = dataArray.filter(
						trip => trip?.zona_nombre === zona
					);

					if (trip.length > 0) {
						setData(trip);
						setMostrarPanicAlert(true);
					} else {
						setData([]);
						setMostrarPanicAlert(false);
					}
				} else {
					setData([]);
					/* console.log('No hay datos en la colección "Boton Panico"'); */
				}
			});
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		}
	};

	/* useEffect(() => {
		if (panicAlert) {
			setShowModal(true);
		} else setShowModal(false);
	}, [monitoring, panicAlert]); */

	return (
		<>
			{mostrarPanicAlert ? (
				<AlertaPanico
					setMostrarPanicAlert={setMostrarPanicAlert}
					setCurrentPage={setCurrentPage}
				/>
			) : (
				<>
					<div className='h-0 overflow-y-hidden'>
						<NavBar setCurrentPage={setCurrentPage} />
					</div>
					<div className='flex'>
						<div className='xl:w-sb lg:w-0 md:w-0 sm:w-0 xs:w-0 2xs:w-0'>
							<Sidebar
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
						<div className='flex-1 2xs:hidden overflow-x-auto bg-bgDash md:block min-h-screen xl:mt-0 2xs:mt-30'>
							{currentPage === 'Zonas' ? (
								<Zonas setCurrentPage={setCurrentPage} />
							) : (
								<>
									{componentMap[currentPage]
										? React.createElement(
												componentMap[currentPage],
												{
													trips:
														currentPage ===
															'ViajesEnCurso' &&
														trips,

													id: data,
													setCurrentPage:
														setCurrentPage,
												}
										  )
										: null}
								</>
							)}

							<AlertModal
								open={showModal}
								onClose={() => setShowModal(false)}
								panic={panicAlert}
							/>
						</div>
						<h1 className='2xs:block md:hidden mt-32 text-2xl font-bold text-center'>
							Página no disponible para dispositivos móviles. Por
							favor, accede desde una tablet o una pantalla más
							grande para disfrutar de la experiencia completa.
						</h1>
					</div>
				</>
			)}
		</>
	);
};
export default Dashboard;
