import React from 'react';
import Modal from 'react-modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DataTable from 'react-data-table-component';

Modal.setAppElement('#root');

const customStyles = {

    headCells: {
        style: {
            backgroundColor: "rgba(0, 73, 144)",
            color: "white",
        },
    },
};

export default function ModalDriversByLocality({ isOpen, onClose, locality, drivers }) {
    const columns = [
        {
            name: 'Nombre',
            selector: row => `${row.name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: 'ID',
            selector: row => row.driver_id,
            sortable: true,
        },
        {
            name: 'Vehículo',
            selector: row => row.vehicle_id_type?.name || 'Desconocido',
            sortable: true,
        },
    ];

    return (
        <Modal
            className={`bg-gray-100  flex flex-col rounded-xl border-4 w-[30vw] h-[80vh] border-orange-500`}
            isOpen={isOpen}
            onClose={onClose}
            contentLabel="Detalles de Conductores"
            style={{
                overlay: {
                    backgroundColor: '#00000099',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '17vw',
                },
                content: {
                    overflowY: "auto",
                },
            }}
        >
            <div className="flex justify-end">
                <button className="p-1" onClick={onClose}>
                    <HighlightOffIcon className="text-orange-500 hover:bg-orange-600 hover:text-white rounded-full text-4xl mr-1" style={{ fontSize: '2rem' }} />
                </button>
            </div>
            <div className="text-center">
                <p className='font-bold text-2xl mb-2'>Conductores en {locality}</p>
                <div className='mx-4'>
                    <DataTable
                        columns={columns}
                        data={drivers}
                        customStyles={customStyles}
                        pagination
                    />
                </div>
            </div>
        </Modal>
    );
}
