import React, { useEffect, useState } from 'react';
import { setPoligonoId, setPoligonoZona } from '../../redux/actions';
import store from '../../redux/store';
import '../../styles/zonas.css';

const Zonas = ({ setCurrentPage }) => {
	const [zonas, setZonas] = useState([]);
	useEffect(() => {
		fetch('https://superb-mackerel-18.hasura.app/v1/graphql', {
			method: 'POST',
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: 'query Get_poligons_admin {   poligonos(where: {environment: {_eq: "2"}}) {     nombre     poligono_id     status     poligono   } }',
			}),
		})
			.then(res => res.json())
			.then(res => {
				setZonas(res.data.poligonos);
			});
	}, []);

	return (
		<>
			<section className='section-zonas'>
				<div>
					<h1>Selecciona una Zona para acceder a sus tarifas</h1>
				</div>
				{zonas.map(zona => (
					<div
						key={zona.poligono_id}
						className='div-zona'
						onClick={() => {
							setCurrentPage('Tarifas');
							store.dispatch(setPoligonoId(zona.poligono_id));
							store.dispatch(setPoligonoZona(zona.nombre));
						}}
					>
						<div className='div-zona-imagen'>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/apphive-inc.appspot.com/o/usersmedia%2F3MXJqLpPFsKzSbj8AujUAo?alt=media&token=fad5dc5d-799b-41e0-942c-910991669c36'
								alt='imagen'
							/>
						</div>
						<div className='div-zona-nombre'>
							<p>
								Nombre de la zona: <span> {zona.nombre} </span>
							</p>
							<p>
								Status de la zona:{' '}
								<span
									style={{
										color: zona.status ? 'green' : 'red',
									}}
								>
									{zona.status ? 'Activo' : 'Inactivo'}
								</span>
							</p>
						</div>
					</div>
				))}
			</section>
		</>
	);
};

export default Zonas;
