import React, { useState } from 'react';
import ModalHistorial from './ModalHistorial';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

export default function BotonHistorial({ rowData }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const modalMarginStyle = window.innerWidth > 1280 ?
        { marginLeft: 'calc((100% - 20.5rem) / 3)', marginTop: '13rem' }
        : { margin: '0 auto', marginTop: '7.5rem' };

    return (
        <div>
            <button className='bg-BlueD text-white p-1 rounded-r-full ml-1 hover:scale-110 hover:bg-orange-500' onClick={openModal}>
                <DirectionsCarIcon sx={{fontSize:'1.1rem', margin:'.25rem'}} />
            </button>
            <ModalHistorial 
            isOpen={isModalOpen} onRequestClose={closeModal} rowData={rowData}
                style={{
                    overlay: {
                        backgroundColor: '#00000099',
                    },
                    content: {
                        overflowY: "scroll",
                        ...modalMarginStyle,
                    },
                }}
            />
        </div>
    );
}