import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { isToday, isYesterday, startOfMonth, endOfMonth, addMonths } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Historial = () => {
    const [viajes, setViajes] = useState([]);
    const [hoy, setHoy] = useState(0);
    const [ayer, setAyer] = useState(0);
    const [mesActual, setMesActual] = useState(0);
    const [mesPasado, setMesPasado] = useState(0);

    useEffect(() => {
        const fetchViajesData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_VIAJES,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );

                const viajesData = response.data.trips;
                setViajes(viajesData);
            } catch (error) {
                console.error("Error fetching trip data:", error);
            }
        };

        fetchViajesData();
    }, []);

    useEffect(() => {
        const today = new Date();
        const startOfCurrentMonth = startOfMonth(today);
        const endOfCurrentMonth = endOfMonth(today);
        const startOfLastMonth = startOfMonth(addMonths(today, -1));
        const endOfLastMonth = endOfMonth(addMonths(today, -1));

        const viajesHoy = viajes.filter(viaje =>
            isToday(new Date(viaje.time_request))
        ).length;

        const viajesAyer = viajes.filter(viaje =>
            isYesterday(new Date(viaje.time_request))
        ).length;

        const viajesMesActual = viajes.filter(viaje =>
            new Date(viaje.time_request) >= startOfCurrentMonth && new Date(viaje.time_request) <= endOfCurrentMonth
        ).length;

        const viajesMesPasado = viajes.filter(viaje =>
            new Date(viaje.time_request) >= startOfLastMonth && new Date(viaje.time_request) <= endOfLastMonth
        ).length;

        setHoy(viajesHoy);
        setAyer(viajesAyer);
        setMesActual(viajesMesActual);
        setMesPasado(viajesMesPasado);
    }, [viajes]);

    const chartData = [
        { a: hoy, Hoy: hoy, x: "Hoy" },
        { b: ayer, Ayer: ayer, x: "Ayer" },
        { b: mesActual, Mes: mesActual, x: "Mes" },
        { b: mesPasado, Mes_Pasado: mesPasado, x: "Mes Pasado" },
    ];

    return (
        <div className="flex items-center justify-center h-full">
            <div>
                <h2 className='text-xl font-bold text-center'>Historial</h2>
                <h2 className='text-xl font-bold text-center'>(Viajes realizados)</h2>
                <BarChart width={450} height={300} data={chartData}>
                    <XAxis dataKey="x" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Hoy" fill="#3aa5e6" name="Hoy" />
                    <Bar dataKey="Ayer" fill="#51171D" name="Ayer" />
                    <Bar dataKey="Mes" fill="#088032" name="Mes" />
                    <Bar dataKey="Mes_Pasado" fill="#EC6112" name="Mes_Pasado" />
                </BarChart>
            </div>
        </div>
    );
}

export default React.memo(Historial);
