import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loading from "../assets/img/Loading.json";
import Lottie from "lottie-react";

export function ProtectedRoute({ children, allowedRoles }) {
  const { user, loading } = useAuth();
  if (loading) return <Lottie animationData={Loading} loop={true} />;
  if (!user) return <Navigate to="/Login" />;
  const userId = user.uid || "";
  if (allowedRoles && !allowedRoles.includes(userId)) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
}
