import React from "react";
import { ALL_TRIPS, ALL_USERS, DRIVERS_NOTIFIED } from "../../schemas/querys";
import { useQuery } from "@apollo/client";

export const UsersComponent = () => {
  const { loading, error, data } = useQuery(ALL_USERS);
  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return data;
};

export const useAllTripsData = () => {
  const { loading, error, data } = useQuery(ALL_TRIPS);
  if (loading) return { loading: true };
  if (error) return { error: error.message };
  return { data };
};

export const useDriversNotifiedData = () => {
  const { loading, error, data } = useQuery(DRIVERS_NOTIFIED);
  if (loading) return { loading: true };
  if (error) return { error: error.message };
  return { data };
};
