import { useState, useRef, useEffect } from 'react';
import PanicAlert from '../assets/sounds/Alert.mp3';
import MonitorAlert from '../assets/sounds/monitoreo.mp3';
import { LISTEN_TRAVELS_SUB } from '../schemas/subscriptions';
import { useSubscription } from '@apollo/client';

const useCurrentTrips = () => {
	const [trips, setTrips] = useState([]);
	const [monitoring, setMonitoring] = useState(false);
	const [panicAlert, setPanicAlert] = useState(false);
	const tripsRef = useRef(trips);
	const alertTriggeredRef = useRef({});

	useEffect(() => {
		tripsRef.current = trips;
	}, [trips]);

	const { data } = useSubscription(LISTEN_TRAVELS_SUB);
	const TRIPS = data?.trips;

	useEffect(() => {
		launchAlert(TRIPS);
	}, [TRIPS]);

	/**
	 * Processes an array of trip objects and launches different types
	 * of alerts (panic or monitoring) based on the properties of these objects.
	 * It also keeps track of which trips have had alerts triggered to prevent duplicate alerts for the same trip.
	 * @param {Array<Object>} trips
	 */
	const launchAlert = trips => {
		if (trips?.length > 0) {
			setTrips(
				trips?.map(trip => ({
					tripID: trip.trip_id,
					distance: trip.distance,
					monitoring: trip.monitoring,
					panic: trip.panic_button,
					tripStatus: trip.status,
					tripSubStatus: trip.substatus,
					user: `${trip.user_data.name} ${trip.user_data.last_name}`,
					driver: `${trip.driver_data.name} ${trip.driver_data.last_name}`,
					locations: {
						origin: trip.locations[0].description,
						destination: trip.locations[1].description,
					},
				}))
			);
			trips.forEach(trip => {
				if (trip.panic) {
					triggerSound(PanicAlert);
					setPanicAlert(trip.panic);
					return;
				}

				if (
					!alertTriggeredRef.current[trip.tripID] &&
					trip.monitoring
				) {
					triggerSound(MonitorAlert);
					setMonitoring(trip.monitoring);
					alertTriggeredRef.current[trip.tripID] = true;
					return;
				}
			});
		} else {
			setTrips([]);
			setMonitoring(false);
			setPanicAlert(false);
		}
	};

	/**
	 * Triggers sound effects, such as alerts or other auditory feedback, in a web application
	 * @param {String} audioSrc - Path to the local sound asset
	 */
	const triggerSound = audioSrc => {
		const audio = new Audio();
		audio.pause();
		audio.muted = false;
		audio.src = audioSrc;
		audio.currentTime = 0;
		audio.play();
	};

	return { trips, monitoring, panicAlert };
};

export default useCurrentTrips;
