import React, { useEffect, useState } from 'react';
import { ref, child, get } from 'firebase/database';
import { db } from '../../firebase/firebase';
import EditIcon from '@mui/icons-material/Edit';
import FormConfiguraciones from './FormConfiguraciones';
import '../../styles/configuraciones.css';

const Configuraciones = () => {
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [editar, setEditar] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const dbRef = ref(db);
				const snapshot = await get(
					child(
						dbRef,
						'/projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/APP_Configuracion/'
					)
				);

				if (snapshot.exists()) {
					const data = snapshot.val();
					const dataArray = Object.entries(data).map(
						([key, value], index) => ({
							serial: index,
							id: key,
							...value,
						})
					);
					setData(dataArray);
				} else {
					console.log('No hay datos en la colección "Configuracion"');
				}
			} catch (error) {
				console.error('Error al obtener los datos:', error);
			}
		};

		fetchData();
	}, [editar]);

	return (
		<>
			{modal ? (
				<FormConfiguraciones
					data={data}
					setModal={setModal}
					editar={editar}
					setEditar={setEditar}
				/>
			) : (
				<>
					<section className='section-configuracion-detalles'>
						<h1>Configuraciones</h1>
						{data.map(configuracion => (
							<div
								key={configuracion.id}
								className='div-main-configuracion-detalles'
							>
								<div className='form-configuracion-detalles'>
									<p>Build Number Minimo Cliente Android</p>
									<span>
										{
											configuracion?.buildNumberMinimoClienteAndroid
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Build Number Minimo Cliente IOS</p>
									<span>
										{
											configuracion?.buildNumberMinimoClienteIos
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Build Number Minimo Conductor Android</p>
									<span>
										{
											configuracion?.buildNumberMinimoConductorAndroid
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Build Number Minimo Conductor IOS</p>
									<span>
										{
											configuracion?.buildNumberMinimoConductorIos
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Distance Get Drivers</p>
									<span>
										{configuracion?.distance_getDrivers}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Environment</p>
									<span>{configuracion?.environment}</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Forzar Update Cliente Android</p>
									<span>
										{configuracion?.forzarUpdateClienteAndroid
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Forzar Update Cliente IOS</p>
									<span>
										{configuracion?.forzarUpdateClienteIos
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Forzar Update Conductor Android</p>
									<span>
										{configuracion?.forzarUpdateConductorAndroid
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Forzar Update Conductor IOS</p>
									<span>
										{configuracion?.forzarUpdateConductorIos
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Fuera Servicio Cliente</p>
									<span>
										{configuracion?.fueraServicioCliente
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Fuera Servicio Conductor</p>
									<span>
										{configuracion?.fueraServicioConductor
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Function Environment</p>
									<span>
										{configuracion?.function_environment}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Multa Cancelacion</p>
									<span>
										{configuracion?.multaCancelacion}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Niku API</p>
									<span>
										{configuracion?.nikuAPI
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Notifica SMS</p>
									<span>
										{configuracion?.notificaSMS
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Permite Negativos Wallet</p>
									<span>
										{configuracion?.permiteNegativosWallet
											? 'true'
											: 'false'}
									</span>
								</div>

								<div className='form-configuracion-detalles'>
									<p>Maximo Negativo Wallet</p>
									<span>
										{configuracion?.maximoNegativoWallet}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Maximo Positivo Wallet</p>
									<span>
										{configuracion?.maximoPositivoWallet}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Minimo Retiro Wallet</p>
									<span>
										{configuracion?.minimoRetiroWallet}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Monto Minimo Recarga Wallet</p>
									<span>
										{
											configuracion?.montoMinimoRecargaWallet
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Saldo Regalo Cliente Wallet</p>
									<span>
										{
											configuracion?.saldoRegaloClienteWallet
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Saldo Regalo Conductor Wallet</p>
									<span>
										{
											configuracion?.saldoRegaloConductorWallet
										}
									</span>
								</div>

								<div className='form-configuracion-detalles'>
									<p>Aceptar Oportunidad</p>
									<span>
										{
											configuracion?.oportunidad_segundosAceptarOportunidad
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Prefijo Codigo Pais</p>
									<span>
										{configuracion?.prefijoCodigoPais}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Prefijo Direccion</p>
									<span>
										{configuracion?.prefijoDireccion}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Prefijo Moneda</p>
									<span>{configuracion?.prefijoMoneda}</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Prefijo Telefono</p>
									<span>
										{configuracion?.prefijoTelefono}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Time Cancelar Viaje</p>
									<span>
										{configuracion?.timeCancelarViaje}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Time Verification Code </p>
									<span>
										{configuracion?.timeVerificationCode}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Tracking Distance Filter</p>
									<span>
										{
											configuracion?.tracking_distance_filter
										}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Tracking Webhook</p>
									<span>
										{configuracion?.tracking_webhook}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Verificacion Telefono Cliente</p>
									<span>
										{configuracion?.verificacionTelefonoCliente
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Verificacion Telefono Conductor</p>
									<span>
										{configuracion?.verificacionTelefonoConductor
											? 'true'
											: 'false'}
									</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Webhook One Off</p>
									<span>{configuracion?.webhook_oneOff}</span>
								</div>
								<div className='form-configuracion-detalles'>
									<p>Webhook One Cancelar Viaje </p>
									<span>
										{
											configuracion?.webhook_one_cancelar_viaje
										}
									</span>
								</div>
							</div>
						))}

						<button
							className='buttom-editar-configuraciones'
							onClick={() => {
								setModal(true);
							}}
						>
							Editar <EditIcon />
						</button>
					</section>
				</>
			)}
		</>
	);
};

export default Configuraciones;
