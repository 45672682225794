import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CursorWrapper = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: #ffffff; /* Cambiado a blanco para simular nieve */
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.15s ease;
  z-index: 9999;
  mix-blend-mode: difference; /* Agregado efecto de mezcla para un look más festivo */
  cursor: none; /* Ocultar el cursor predeterminado del sistema */
`;

const Snowflake = styled.span`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #4aa0d5; /* Color azul para el copo de nieve */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(${(props) => props.rotate}deg);
`;

const CursorEffectChristmas = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const updateCursorPosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateCursorPosition);

    return () => {
      document.removeEventListener('mousemove', updateCursorPosition);
    };
  }, []);

  return (
    <CursorWrapper style={{ left: `${position.x}px`, top: `${position.y}px` }}>
      <Snowflake rotate="45" />
      <Snowflake rotate="90" />
      <Snowflake rotate="135" />
      <Snowflake rotate="180" />
      <Snowflake rotate="225" />
      <Snowflake rotate="270" />
      <Snowflake rotate="315" />
      <Snowflake rotate="0" />
    </CursorWrapper>
  );
};

export default CursorEffectChristmas;
