import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { mapStyle } from '../../Sidebar/Mapa/Estilo';
import DestinoIcon from "../../../assets/img/Destino.ico";
import OrigenIcon from "../../../assets/img/Origen.png";
import formatDate from '../../../formatDate';
Modal.setAppElement('#root');
export default function ModalFinalizados({ isOpen, onRequestClose, finalizadoDetails }) {
  const timeRequest = finalizadoDetails?.trips_by_pk?.time_request;
  const defaultLatitude = 19.0589756;
  const defaultLongitude = -98.1811881;
  const originLatitude = finalizadoDetails?.trips_by_pk?.locations?.[0]?.latitude || defaultLatitude;
  const originLongitude = finalizadoDetails?.trips_by_pk?.locations?.[0]?.longitude || defaultLongitude;
  const destinationLatitude = finalizadoDetails?.trips_by_pk?.locations?.[1]?.latitude;
  const destinationLongitude = finalizadoDetails?.trips_by_pk?.locations?.[1]?.longitude;
  const [directions, setDirections] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS,
    libraries: ['geometry', 'drawing', 'places'],
  })
  useEffect(() => {
    if (originLatitude && originLongitude && destinationLatitude && destinationLongitude) {
      const directionsService = new window.google.maps.DirectionsService();
      const request = {
        origin: { lat: originLatitude, lng: originLongitude },
        destination: { lat: destinationLatitude, lng: destinationLongitude },
        travelMode: 'DRIVING',
      };
      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          setDirections(result);
        } else {
          console.error(`Error al obtener direcciones: ${status}`);
        }
      });
    }
  }, [originLatitude, originLongitude, destinationLatitude, destinationLongitude]);
  function formatarFecha(timestamp) {
    if (timestamp && typeof timestamp === 'number') {
      if (timestamp.toString().length === 10) {
        timestamp *= 1000;
      }
      return new Date(timestamp).toLocaleTimeString() || 'Sin hora';
    }
    return 'Sin hora';
  }
  const center = {
    lat: (originLatitude + destinationLatitude) / 2,
    lng: (originLongitude + destinationLongitude) / 2,
  };
  return (
    <Modal
      className={`bg-gray-100 flex flex-col rounded-xl border-4 w-modal h-modal border-orange-500 `}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      style={{
        overlay: {
          backgroundColor: '#00000099',
          overflow: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '17vw'
        },
        content: {
          overflowY: "auto",
          overflowX: "hidden",
        },
      }}
    >
      <div className="grid semi:grid-cols-2 h-full sm:grid-rows-2 semi:grid-rows-1">
        <div className="semi:col-span-1 sm:row-start-2 semi:row-start-1">
          <div className="h-full">
            {isLoaded &&
              <GoogleMap
                mapContainerStyle={{ height: '100%' }}
                center={center}
                zoom={13}
                options={{ styles: mapStyle.default }}
              >
                {finalizadoDetails?.trips_by_pk?.locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={{ lat: location.latitude, lng: location.longitude }}
                    icon={{
                      url: index === 0 ? OrigenIcon : DestinoIcon,
                      scaledSize: new window.google.maps.Size(80, 80),
                    }}
                  />
                ))}
                {directions && (
                  <Polyline
                    path={directions.routes[0].overview_path}
                    options={{
                      strokeColor: '#FF8000',
                      strokeOpacity: 0.8,
                      strokeWeight: 5,
                    }}
                  />
                )}
              </GoogleMap>
            }
          </div>
        </div>
        <div className="col-span-1 justify-center text-md px-2">
          <div className='grid grid-cols-8 items-start semi:justify-between sm:justify-between'>
            <h2 className="self-center col-start-2 col-end-7 font-bold semi:text-center sm:items-center ml-2">Detalles del viaje</h2>
            <button className='col-start-8 justify-self-end mt-1 mr-1' onClick={onRequestClose}>
              <HighlightOffOutlinedIcon className="text-orange-500 hover:bg-orange-600  hover:text-white rounded-full" sx={{ fontSize: '2rem' }} />
            </button>
          </div>
          <div className="grid grid-cols-4 mt-5">
            {timeRequest && (
              <p className="ml-2 col-span-1">
                <span className="font-bold">Fecha:</span> {formatDate(timeRequest)}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.trip_id && (
              <p className="ml-2 col-start-3 text-right mr-6 truncate ... col-end-5">
                <span className="font-bold">ID Viaje:</span> <span className='truncate ...'>{finalizadoDetails.trips_by_pk.trip_id}</span>
              </p>
            )}
          </div>
          {finalizadoDetails?.trips_by_pk?.driver_id ? (
            <p className="ml-2 mt-5">
              <span className="font-bold">ID conductor:</span> {finalizadoDetails.trips_by_pk.driver_id}
            </p>
          ) : (
            <p className="ml-2 mt-5">
              <span className="font-bold">ID conductor:</span> Sin asignar
            </p>
          )}
          <div>
            {finalizadoDetails?.trips_by_pk?.driver_data ? (
              <p className="ml-2 mt-5">
                <span className="font-bold">Conductor:</span> {finalizadoDetails.trips_by_pk.driver_data.name} {finalizadoDetails.trips_by_pk.driver_data.last_name}
              </p>
            ) : (
              <p className="ml-2 mt-5">
                <span className="font-bold">Conductor:</span> Sin asignar
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 mt-5">
            {finalizadoDetails?.trips_by_pk?.status && (
              <p className="ml-2 col-span-1">
                <span className="font-bold">Estatus:</span> {finalizadoDetails.trips_by_pk.status}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.substatus && (
              <p className="ml-2 col-span-1 text-right mr-6">
                <span className="font-bold">Sub-estatus:</span> {finalizadoDetails.trips_by_pk.substatus}
              </p>
            )}
          </div>
          {finalizadoDetails?.trips_by_pk?.reasons_cancellation ? (
            <p className="mt-5 ml-2 col-span-1">
              <span className="font-bold">Nota:</span> {finalizadoDetails.trips_by_pk.reasons_cancellation}
            </p>
          ) : (
            <p className="mt-5 ml-2 col-span-1">
              <span className="font-bold">Nota:</span> Sin comentarios
            </p>
          )}
          <div className="grid grid-cols-2 mt-5">
            {finalizadoDetails?.trips_by_pk?.payment_method && (
              <p className="ml-2 col-span-1">
                <span className="font-bold">Metodo de Pago:</span> {finalizadoDetails.trips_by_pk.payment_method}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.costo && (
              <p className="ml-2 col-span-1 text-right mr-6">
                <span className="font-bold">Costo:</span> <>$</>{finalizadoDetails.trips_by_pk.costo}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 mt-5">
            <p className="ml-2 col-span-1">
              <span className="font-bold">Duración:</span>{' '}
              {finalizadoDetails?.trips_by_pk?.estimated_time ?? '0'}
              <span> min</span>
            </p>
            <p className="ml-2 col-span-1 text-right mr-6">
              <span className="font-bold">Distancia:</span>{' '}
              {finalizadoDetails?.trips_by_pk?.distance ?? '0'}
            </p>
          </div>
          {finalizadoDetails?.trips_by_pk?.locations[0]?.description && (
            <p className="ml-2 mt-5">
              <span className="font-bold">Origen:</span> {finalizadoDetails.trips_by_pk.locations[0].description}
            </p>
          )}
          {finalizadoDetails?.trips_by_pk?.locations[1]?.description && (
            <p className="ml-2 mt-5">
              <span className="font-bold">Destino:</span> {finalizadoDetails.trips_by_pk.locations[1].description}
            </p>
          )}
          {finalizadoDetails?.trips_by_pk?.user_data?.name && (
            <p className="ml-2 col-span-1 mt-5">
              <span className="font-bold">Usuario:</span> {finalizadoDetails.trips_by_pk.user_data.name} {finalizadoDetails.trips_by_pk.user_data.last_name}
            </p>
          )}
          {finalizadoDetails?.trips_by_pk?.user_id && (
            <p className="ml-2 mt-5 col-span-1">
              <span className="font-bold">ID Usuario:</span> {finalizadoDetails.trips_by_pk.user_id}
            </p>
          )}
          <div>
            {finalizadoDetails?.trips_by_pk?.time_request && (
              <p className="ml-2 mt-5 col-span-1">
                <span className="font-bold">Hora solicitado:</span>{' '}
                {formatarFecha(finalizadoDetails.trips_by_pk.time_request) || 'Sin hora'}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.time_accepted && (
              <p className="ml-2 mt-5 col-span-1">
                <span className="font-bold">Hora aceptado:</span>{' '}
                {formatarFecha(finalizadoDetails.trips_by_pk.time_accepted) || 'Sin hora'}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.time_completed && (
              <p className="ml-2 mt-5 col-span-1">
                <span className="font-bold">Hora de conclusión del viaje:</span>{' '}
                {formatarFecha(finalizadoDetails.trips_by_pk.time_completed) || 'Sin hora'}
              </p>
            )}
            {finalizadoDetails?.trips_by_pk?.time_canceled && (
              <p className="ml-2 mt-5 col-span-1">
                <span className="font-bold">Hora de cancelación:</span>{' '}
                {formatarFecha(finalizadoDetails.trips_by_pk.time_canceled) || 'Sin hora'}
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}