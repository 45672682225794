import { WebSocketLink } from "@apollo/client/link/ws";


export const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_WS,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
      },
    },
  },
});
