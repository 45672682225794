import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query AllUsers {
    user {
      email
      identification
      last_log_date
      last_name
      last_trip_date
      name
      pass
      phone
      photo
      rating
      register_date
      register_type
      status
      trip_count
      user_id
      user_trip_count {
        count
        rating
        user_id
      }
    }
  }
`;

export const TRIPS_BY_ID_USER = gql`
  query TripsByIdUser($_eq: String!) {
    trips(order_by: { time_request: asc }, where: { user_id: { _eq: $_eq } }) {
      # distance
      driver_id
      # drivers_canceled {
      #   id
      #   driver_id
      #   callback
      #   trip_id
      # }
      # drivers_notified {
      #   id
      #   driver_id
      #   trip_id
      # }
      environment
      # estimated_time
      # impuestos_isr
      # locations {
      #   description
      #   latitude
      #   longitude
      # }
      pago_total
      # panic_button
      # payment_method
      # rate_id
      # reasons_cancellation
      status
      # tarifa_total
      # tarifa_total_descuento
      # time_accepted
      # time_canceled
      # time_completed
      # time_course
      # time_origin
      # time_request
      # time_to_origin
      trip_id
      user_id
      vehicle_id
      # viaje_minutos
      # who_cancel
    }
  }
`;

export const ALL_TRIPS = gql`
  query AllTrips {
    trips(order_by: { time_request: asc }) {
      distance
      driver_id
      drivers_canceled {
        id
        driver_id
        callback
        trip_id
      }
      drivers_notified {
        id
        driver_id
        trip_id
      }
      environment
      estimated_time
      impuestos_isr
      locations {
        description
        latitude
        longitude
      }
      pago_total
      panic_button
      payment_method
      rate_id
      reasons_cancellation
      status
      tarifa_total
      tarifa_total_descuento
      time_accepted
      time_canceled
      time_completed
      time_course
      time_origin
      time_request
      time_to_origin
      trip_id
      type
      user_id
      vehicle_id
      viaje_minutos
      who_cancel
    }
  }
`;

export const DRIVERS_NOTIFIED = gql`
  query DriversNotified {
    trips_drivers_notified {
      trip_id
      id
      driver_id
      callback
    }
  }
`;

export const DRIVERS_ON_LINE = gql`
  query DriversOnLine @cached {
    drivers(
      where: {
        environment: { _eq: "2" }
        is_connected: { timestamp: { _is_null: false } }
      }
    ) {
      driver_id
      address_state
      address_locality
      last_name
      name
      status
      vehicle_id_type {
        name
      }
      inline: is_connected {
        timestamp
      }
    }
  }
`;
