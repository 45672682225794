import React, { useState, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import Tooltip from '@mui/material/Tooltip';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { uploadDocument } from './CustomSnackbar';
import CustomSnackbar from './CustomSnackbar';

export default function TablaDocumentos({ vehicleDetails, vehicleId }) {
	const [documentsToShow, setDocumentsToShow] = useState([]);
	const [sortedDocuments, setSortedDocuments] = useState([]);
	const [approvalState, setApprovalState] = useState([]);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
		useState(false);
	const [isDocVerification, setIsDocVerification] = useState(
		vehicleDetails ? vehicleDetails.docVerification : false
	);
	const [isSnackbarOpen, setSnackbarOpen] = useState(false);
	const [isSnackbarError, setSnackbarError] = useState(false);

	useEffect(() => {
		if (
			vehicleDetails &&
			vehicleDetails.docs &&
			Array.isArray(vehicleDetails.docs)
		) {
			const sortedDocs = [...vehicleDetails.docs].sort((a, b) => {
				if (
					a.data &&
					b.data &&
					typeof a.data.index === 'number' &&
					typeof b.data.index === 'number'
				) {
					return a.data.index - b.data.index;
				}
				return 0;
			});
			setSortedDocuments(sortedDocs);

			const initialApproval = sortedDocs.map(
				doc => doc.validation === 'check'
			);
			setApprovalState(initialApproval);

			const filteredDocs = sortedDocs.filter(
				doc => doc.data && doc.data.name
			);
			setDocumentsToShow(filteredDocs);
		}
	}, [vehicleDetails]);

	const toggleValidation = () => {
		setConfirmationModalIsOpen(true);
	};
	const confirmValidation = async newValidation => {
		setConfirmationModalIsOpen(false);
		try {
			if (vehicleDetails && vehicleDetails.id) {
				const response = await axios.post(
					`${process.env.REACT_APP_API_MUTATION_DOCVERIFICATION}${vehicleDetails.id}/${newValidation}`,
					null,
					{
						headers: {
							'x-hasura-admin-secret':
								process.env.REACT_APP_API_KEY,
						},
					}
				);
				if (response.status === 200) {
					vehicleDetails.docVerification = newValidation === 'true';
				} else {
					console.error(
						'Error al cambiar el estado de validación en Hasura:',
						response
					);
				}
			} else {
				console.error(
					'El objeto vehicleDetails o su propiedad id es nulo.'
				);
			}
		} catch (error) {
			console.error(
				'Error al cambiar el estado de validación en Hasura:',
				error
			);
		}
		setIsDocVerification(newValidation === 'true');
	};
	const isButtonDisabled =
		vehicleDetails &&
		vehicleDetails.docs &&
		(!vehicleDetails.docs.every(doc => doc.validation === 'check') ||
			documentsToShow.length < 8);

	const toggleApproval = async index => {
		const doc = sortedDocuments[index];

		try {
			const newApprovalValue =
				doc.validation === 'check' ? 'false' : 'check';
			const response = await axios.post(
				`${process.env.REACT_APP_GRAPHQL_ENDPOINT}${newApprovalValue}/${vehicleDetails.id}/${doc.docid}`,
				null,
				{
					headers: {
						'x-hasura-admin-secret': process.env.REACT_APP_API_KEY,
					},
				}
			);

			if (response.status === 200) {
				doc.validation = newApprovalValue;

				setSortedDocuments(prevState => {
					const newState = [...prevState];
					newState[index] = doc;
					return newState;
				});

				setApprovalState(prevState => {
					const newState = [...prevState];
					newState[index] = newApprovalValue === 'check';
					return newState;
				});
			} else {
				console.error(
					'Error al cambiar el estado en Hasura:',
					response
				);
			}
		} catch (error) {
			console.error('Error al cambiar el estado en Hasura:', error);
		}
	};

	const openDocumentLink = src => {
		window.open(src, '_blank', 'width=800,height=600');
	};

	let knownDocumentNames = [
		'Comprobante de domicilio',
		'Antecedentes no penales (no mayor a un año)',
		'INE Trasera',
		'Licencia de conducir',
		'Imagen del automóvil',
		'Tarjeta de circulación',
		'Póliza de seguro',
		'INE Frontal',
	];

	if (vehicleId === '094363hl-s11ed-8a29-75bea275esad2w') {
		knownDocumentNames = [
			...knownDocumentNames,
			'Licencia de manejo de Servicio público',
			'Identificación de taxista: Tarjetón ',
			'CURP',
		];
	}

	return (
		<div className='mb-5 mr-7 ml-2'>
			<h2 className='text-center text-xl font-bold rounded-2xl text-white mb-2 bg-orange-400 '>
				Documentos
			</h2>
			{documentsToShow.length === 0 ? (
				<p>No hay documentos del conductor</p>
			) : (
				<div>
					{documentsToShow.map((document, index) => (
						<div
							className='grid grid-cols-8 text-xl mb-1'
							key={index}
						>
							<div className='col-span-5 text-lg row-auto bg-orange-500 rounded-l-2xl text-center text-white px-2'>
								<h1 className='mt-1 mb-1 truncate ...'>
									{document.data
										? document.data.name || 'N/A'
										: 'N/A'}
								</h1>
							</div>
							<label
								className='col-span-1 bg-orange-500'
								htmlFor={`fileInput-${index}`}
							>
								<Tooltip
									title='Cambiar documento'
									placement='left'
									arrow
								>
									<DriveFolderUploadIcon
										sx={{
											fontSize: '2rem',
											color: 'white',
											cursor: 'pointer',
										}}
									/>
								</Tooltip>
							</label>
							<input
								type='file'
								name=''
								id={`fileInput-${index}`}
								onChange={e =>
									uploadDocument(
										e.target.files[0],
										document.src,
										setSnackbarOpen,
										setSnackbarError
									)
								}
								style={{ display: 'none' }}
							/>
							<div className='col-span-1 bg-green-500'>
								<button
									className={`bg-${
										approvalState[index] ? 'green' : 'red'
									}-600 text-white w-full h-full flex items-center justify-center`}
									onClick={() => toggleApproval(index)}
								>
									{approvalState[index] ? (
										<Tooltip
											title="Cambiar a 'invalido'"
											placement='top'
											arrow
										>
											<CheckIcon
												className='text-white'
												style={{ fontSize: '2rem' }}
											/>
										</Tooltip>
									) : (
										<Tooltip
											title="Cambiar a 'aprobado' "
											placement='top'
											arrow
										>
											<CloseIcon
												className='text-white bg-red-600'
												style={{ fontSize: '2rem' }}
											/>
										</Tooltip>
									)}
								</button>
							</div>
							<div className='col-span-1 bg-orange-500 rounded-r-2xl flex items-center ml-1 justify-center px-4'>
								<button
									className='text-gray-50'
									onClick={() =>
										openDocumentLink(document?.src)
									}
								>
									<Tooltip
										title='Ver documentos'
										placement='right'
										arrow
									>
										<VisibilityIcon
											style={{ fontSize: '2rem' }}
										/>
									</Tooltip>
								</button>
							</div>
						</div>
					))}
					{knownDocumentNames.map(
						(documentName, index) =>
							!documentsToShow.some(
								doc => doc.data.name === documentName
							) && (
								<div
									className='grid grid-cols-8 text-xl mb-1'
									key={index}
								>
									<div className='col-span-5 text-lg row-auto bg-orange-500 rounded-l-2xl text-center text-white px-2'>
										<h1 className='mt-1 mb-1 truncate ...'>
											{documentName}
										</h1>
									</div>
									<div className='col-span-3 text-lg row-auto bg-orange-500 rounded-r-2xl text-center text-white px-2'>
										<p className='mt-1 mb-1 text-sm'>
											No se ha subido el documento
										</p>
									</div>
								</div>
							)
					)}
				</div>
			)}
			<button
				className={`p-2 text-white ml-6 mt-2 rounded-lg ${
					isDocVerification
						? 'bg-green-500'
						: isButtonDisabled
						? 'bg-gray-500 cursor-not-allowed'
						: 'bg-orange-500'
				}`}
				onClick={() => toggleValidation('true')}
				disabled={isButtonDisabled || isDocVerification}
			>
				Validar documentos
			</button>
			<ConfirmationModal
				isOpen={confirmationModalIsOpen}
				onRequestClose={() => setConfirmationModalIsOpen(false)}
				confirmValidation={confirmValidation}
			/>
			<CustomSnackbar
				setSnackbarOpen={setSnackbarOpen}
				snackbarOpen={isSnackbarOpen}
				setSnackbarError={setSnackbarError}
				snackbarError={isSnackbarError}
			/>
		</div>
	);
}
