import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { db } from './firebase/firebase';

const useUserRole = user => {
	const [userRole, setUserRole] = useState(null);
	const [idZone, setIdZone] = useState('');
	const [nameZone, setNameZone] = useState('');

	useEffect(() => {
		if (user) {
			const userId = user.uid;

			const roleRef = ref(
				db,
				/* `projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/DASHBOARD_User/${userId}/role` */
				`projects/proj_iDtR68Uec2W1ohmGzgtMRo/data/DASHBOARD_User/${userId}`
			);

			get(roleRef)
				.then(snapshot => {
					if (snapshot.exists()) {
						const data = snapshot.val();

						setUserRole(data.role);
						setIdZone(data.zona_id || '');
						setNameZone(data.zona_nombre || '');
					}
				})
				.catch(error => {
					console.error(
						'Error al obtener el rol del usuario:',
						error
					);
				});
		}
	}, [user]);

	return { userRole, idZone, nameZone };
};

export default useUserRole;
