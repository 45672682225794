import React, { useState } from 'react';
import axios from 'axios';
import ModalFinalizados from './ModalFinalizados';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function DetallesFinalizados({ rowData }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [finalizadoDetails, setFinalizadoDetails] = useState(null);

    const openModal = async () => {
        setModalIsOpen(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_VIAJES}/${rowData?.trip_id || rowData?.tripID}`,
                {
                    headers: {
                        "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                    },
                }
            );
            setFinalizadoDetails(response.data);
        } catch (error) {
            console.error("Error fetching passenger details:", error);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFinalizadoDetails(null);
    };

    return (
        <div className='text-center items-center justify-center'>
            <button
                className='bg-blueT text-white px-2 py-1 rounded-l-2xl font-bold hover:bg-blue-950 text-current'
                onClick={openModal}>
                <MoreHorizIcon className='text-white' />
            </button>
            <ModalFinalizados
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                finalizadoDetails={finalizadoDetails}
            />
        </div>
    );
}