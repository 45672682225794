import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Alert } from './Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import fondoDriver from '.././assets/img/CONDUCTOR.jpg';
import logo from '.././assets/img/NikuLogo.png';
import title from '.././assets/img/niku_car_blanco.png';

export function Login() {
	const [user, setUser] = useState({
		email: '',
		password: '',
	});
	const { login } = useAuth();
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async e => {
		e.preventDefault();
		setError('');
		try {
			await login(user.email, user.password);
			navigate('/');
		} catch (error) {
			setError(error.message);
		}
	};

	const handleChange = ({ target: { value, name } }) =>
		setUser({ ...user, [name]: value });

	return (
		<div className='bg-gradient-to-tl from-deg3 via-deg2 to-blue-700 m-auto h-screen flex items-center justify-center font-montserrat'>
			<div className=' top-16 left-96  absolute z-10'>
				{/* <h1 className="text-5xl w-1/6 text-white">DASHBOARD</h1> */}
				<img
					src={title}
					alt='Fondo del conductor'
					className='w-1/12 ml-8'
				/>
			</div>
			<div className='w-1/2 flex items-center justify-center flex-col'>
				{error && <Alert message={error} />}
				<form
					onSubmit={handleSubmit}
					className='rounded-2xl w-4/5 md:w-1/2 lg:w-2/5'
				>
					<div className='text-center mb-3'>
						<label className='font-bold text-3xl text-white '>
							<FontAwesomeIcon
								icon={faUser}
								className='mr-2 text-orange-500'
							/>
							LOGIN
						</label>
					</div>
					<div className='mb-4'>
						<label
							htmlFor='email'
							className='block text-white text-md font-bold mb-2'
						>
							EMAIL
						</label>
						<input
							type='email'
							name='email'
							id='email'
							onChange={handleChange}
							className='shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'
							placeholder='youremail@company.tld'
						/>
					</div>
					<div className='mb-4'>
						<label
							htmlFor='password'
							className='block text-white text-md font-bold mb-2 capitalize'
						>
							PASSWORD
						</label>
						<input
							type='password'
							name='password'
							id='password'
							onChange={handleChange}
							className='shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'
							placeholder='*************'
						/>
					</div>

					<div className='flex items-center justify-center'>
						<button
							className='bg-orange-500 mt-4 hover:bg-orange-800 w-96 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
							type='submit'
						>
							Iniciar sesión
						</button>
					</div>
				</form>
			</div>
			<div className='w-1/2 bg-blue-900 h-screen relative'>
				<img
					src={fondoDriver}
					alt='Fondo del conductor'
					className='w-full h-full object-cover'
				/>
				<img
					src={logo}
					alt='Niku Logo'
					className='absolute top-4 transform -translate-x-1/2 left-1/2 w-1/2 mt-10'
				/>
			</div>
		</div>
	);
}
