import React from 'react';

const SearchBar = ({ setSearchTerm }) => {
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="my-4 flex justify-center">
            <input
                type="text"
                onChange={handleSearchChange}
                className="px-4 py-2 rounded-2xl w-64 text-white bg-blueT font-bold border border-white"
            />
        </div>
    );
};

export default SearchBar;
