import React from 'react'
import Modal from 'react-modal'
import InfoIcon from '@mui/icons-material/Info';
function AlertModal({ open, onClose, panic }) {
  return (
    <Modal
      className={`bg-gray-100 flex flex-col rounded-xl border-4 w-[70vh] h-[35vh] border-orange-500 h-modal-content items-center `}
      isOpen={open}
      onRequestClose={() => onClose(!open)}
      style={{
        overlay: {
          backgroundColor: '#00000099',
          overflow: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '17vw',
        },
        content: {
          overflowY: "auto",
          overflowX: "hidden",
        },
      }}
    >
      <br />
      <br />
      <InfoIcon
        sx={{fontSize:'6rem', color:'#facc15'}}
      />
      <br />
      <br />
      <h1 className="text-5xl text-black self-center w-full text-center">
        {`¡Alerta de ${panic ? 'Pánico!' : 'Monitoreo!'}`}
      </h1>
    </Modal>
  )
}

export default AlertModal
