import XLSX from "xlsx-js-style";

export const exportToExcel = (filteredDrivers, columnConfig) => {
  const wb = XLSX.utils.book_new();
  const dataToExport = filteredDrivers.map((driver) => {
    const rowData = {};
    columnConfig.forEach((column) => {
      const keys = column.key.split(".");
      let value = driver;
      for (const key of keys) {
        if (value && key in value) {
          value = value[key];
        } else {
          value = "N/A";
          break;
        }
      }
      if (column.key === "name") {
        const lastName = driver.last_name || "N/A";
        value = `${value} ${lastName}`;
      }
      if (column.key === "date_registration") {
        if (typeof value === "number") {
          const date = new Date(value);
          rowData[column.label] = date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
        } else {
          rowData[column.label] = value;
        }
      } else {
        rowData[column.label] = value;
      }
      if (column.key === "vehicle_id_type") {
        rowData["Vehiculo"] = value ? value.name : "No asignado";
      } else if (column.key === "address_state") {
        rowData[column.label] = value || "N/A";
      } else if (column.key === "address_locality") {
        rowData[column.label] = value || "N/A";
      } else if (column.key === "phone") {
        rowData[column.label] = value || "N/A";
      }
    });
    return rowData;
  });

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  ws["!charset"] = "UTF-8";
  XLSX.utils.book_append_sheet(wb, ws, "conductores");

  const columnWidths = [];
  columnConfig.forEach((column, columnIndex) => {
    const minWidth = 20;
    let maxWidth = minWidth;

    dataToExport.forEach((rowData) => {
      const cellValue = rowData[column.label] || "";
      const cellWidth = cellValue.toString().length + 7;
      if (cellWidth > maxWidth) {
        maxWidth = cellWidth;
      }
    });

    if (maxWidth < minWidth) {
      maxWidth = minWidth;
    }

    columnWidths[columnIndex] = { width: maxWidth };
  });

  ws["!cols"] = columnWidths;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const fileName = `Conductores_${formattedDate}.xlsx`;

  XLSX.writeFile(wb, fileName);
};
