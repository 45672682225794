import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function ConfirmationModal({ isOpen, onRequestClose, confirmValidation }) {
    return (
        <Modal
            className={`bg-gray-100 flex flex-col rounded-xl border-4 w-96 h-52 border-orange-500 h-modal-content`}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirmación de Validación"
            style={{
                overlay: {
                    backgroundColor: '#00000099',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '17vw',

                },
                content: {
                    overflowY: "auto",
                },
            }}
        >
            <div className="bg-slate-200 flex justify-center h-screen items-center">
                <div className="text-center">
                    <h2 className='mb-6 text-lg'>¿Validar documentos?</h2>
                    <div className="mt-4 space-x-4">
                        <button className="bg-green-500 text-white px-4 py-1 rounded-lg hover:bg-green-800" onClick={() => {
                            confirmValidation("true");
                            onRequestClose();
                        }}>
                            Confirmar
                        </button>
                        <button className="bg-red-500 text-white px-4 py-1 rounded-lg hover:bg-red-800" onClick={onRequestClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
