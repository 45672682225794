import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import SearchBar from "./SearchBar";
import CursorEffectChristmas from "../../CursorEffectChristmas/CursorEffectChristmas";

export default function TablaTiempoReal(props) {
  const [searchTerm, setSearchTerm] = useState("");

  let driversList = props.drivers;

  const handleButtonClick = (row) => {
    if (props.onButtonClick) {
      props.onButtonClick(row);
    }
  };
  useEffect(() => {
    document.title = `S. Monitoreo - Conductores tiempo real`;
  }, []);
  const filteredData = driversList
    .filter(
      (item) =>
        item?.driverData?.data?.nombre !== undefined &&
        item?.driverData?.data?.vehiculo !== undefined
    ) // Filtrar elementos con nombre y matrícula
    .filter((item) => {
      const conductorMatch = item.driverData.data.nombre
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const idMatch = item.id.toLowerCase().includes(searchTerm.toLowerCase());
      const matriculaMatch = item.driverData.data.vehiculo
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return conductorMatch || idMatch || matriculaMatch;
    });

  const columns = [
    {
      name: "Conductor",
      cell: (row) => (
        <button onClick={() => handleButtonClick(row)}>
          {row.driverData.data.nombre.toUpperCase()}
        </button>
      ),
      sortable: false,
      width: "120px",
    },
    {
      name: "ID",
      cell: (row) => <div>{row.id.substring(0, 8)}</div>,
      sortable: false,
      width: "110px",
    },
    {
      name: "Matricula",
      cell: (row) => (
        <button onClick={() => handleButtonClick(row)}>
          {row.driverData.data.vehiculo.split(":")[1]}
        </button>
      ),
      sortable: false,
      width: "120px",
    },
  ];

  const customStyles = {
    cells: {
      style: {
        backgroundColor: "rgba(0, 73, 144)",
        color: "white",
      },
    },
    headCells: {
      style: {
        backgroundColor: "rgba(0, 73, 144)",
        color: "white",
      },
    },
  };

  const tableContainerStyle = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  return (
    <div className="opacity-50" style={tableContainerStyle}>
{/*       <CursorEffectChristmas/>
 */}
      <SearchBar setSearchTerm={setSearchTerm} />
      {filteredData.length === 0 ? (
        <div className="text-center my-4">
          <p>No se encontraron resultados.</p>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={false}
          customStyles={customStyles}
        />
      )}
    </div>
  );
}
